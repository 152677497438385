import Swal from "sweetalert2";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../../components/Navbar';

const AddFinancialFollowUp = () => {
    const [AddictFamily, setAddictFamily] = useState("")
    const [AddictDonor, setAddictDonor] = useState("")
    const [AddictForm, setAddictForm] = useState("")
    const [financialFollowUps, setFinancialFollowUps] = useState([]);
    const [formData, setFormData] = useState({
        AddictForm: '',
        AddictFamily: '',
        AddictFamilyAmount: '',
        AddictDonor: '',
        AddictDonorAmount: '',
        CathedralAmount: '',
        CathedralResponsible:'',
        CathedralResponsiblePhone:'',
        FamilyBoxAmount: '',
        GodAmount: '',
        GodResponsible:'',
        GodResponsiblePhone:'',
        OtherAmount: '',
        FinancialFollowUpDate: '',
        FinancialFollowUpStatus: 'no',
        totalAmount: '',
    });

    const [targetedAmount, setTargetedAmount] = useState(0);  
    
    const fetchAddictFamily = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}follow-up`);
            setFinancialFollowUps(response.data);
            const response2 = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict-family`);
            const allFamilies = response2.data;
            const existingFamilies = response.data.map(financialFollowUp => financialFollowUp.AddictFamily?._id);
            const newAddictFamilies = allFamilies.filter(addictFamily => !existingFamilies.includes(addictFamily._id));
            setAddictFamily(newAddictFamilies);
        } catch (error) {
            console.error('Error fetching family expenses:', error);
        }
    };

    const fetchAddictForm = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}follow-up`);
            setFinancialFollowUps(response.data);
            const response2 = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict-form`);
            const allAddictForms = response2.data;
            const existingAddicts = response.data.map(financialFollowUp => financialFollowUp.AddictForm?.addict?.addictName);
            const newAddictForms = allAddictForms.filter(addictForm => !existingAddicts.includes(addictForm.addict?.addictName));
            setAddictForm(newAddictForms);
        } catch (error) {
            console.error('Error fetching Form expenses:', error);
        }
    };

    const fetchAddictDonor = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict-donor`);
            setAddictDonor(response.data);
        } catch (error) {
            console.error('Error fetching family expenses:', error);
        }
    };

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleChange = (e) => {
        const updatedFormData = { ...formData, [e.target.name]: e.target.value };
        setFormData(updatedFormData);
        
        if (e.target.name === 'AddictForm' && AddictForm.length > 0) {
            const selectedForm = AddictForm.find(form => form._id === e.target.value);
            if (selectedForm) {
                setTargetedAmount(selectedForm.allExpense);
            }
        }
    
        const total = [
            parseFloat(updatedFormData.AddictFamilyAmount || 0),
            parseFloat(updatedFormData.AddictDonorAmount || 0),
            parseFloat(updatedFormData.CathedralAmount || 0),
            parseFloat(updatedFormData.FamilyBoxAmount || 0),
            parseFloat(updatedFormData.GodAmount || 0),
            parseFloat(updatedFormData.OtherAmount || 0),
        ].reduce((sum, value) => sum + value, 0);
    
        setFormData((prevData) => ({
            ...prevData,
            totalAmount: total
        }));
    };
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(targetedAmount > formData.totalAmount){
            return showErrorAlert("المبلغ المراد تجميعه اقل من الفعلي")
        }
        try {
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}follow-up`, formData);
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم اضافة متابعة مالية جديدة بنجاح`,
            });
            showSuccessAlert('تمت الاضافة بنجاح');
            setFormData({
                AddictForm: '',
                AddictFamily: '',
                AddictFamilyAmount: '',
                AddictDonor: '',
                AddictDonorAmount: '',
                CathedralAmount: '',
                FamilyBoxAmount: '',
                GodAmount: '',
                FinancialFollowUpDate: '',
                FinancialFollowUpStatus: 'no',
                totalAmount:'',
            });
            setTargetedAmount(0);
        } catch (error) {
            if (error.response && error.response.data.error) {
                showErrorAlert(error.response.data.error);
            } else {
                console.log(error)
                showErrorAlert('حدث خطأ اثناء الاضافة');
            }
        }
    };

    useEffect(() => {
        fetchAddictFamily();
        fetchAddictDonor();
        fetchAddictForm();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">اضف متابعة مالية جديدة</h1>
            <div className="max-w-4xl mx-auto p-8 bg-white rounded-xl shadow-lg">
                <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-8">
                    <div>
                        <label htmlFor="AddictForm" className="block text-lg font-medium mb-2">أستمارة الحالة</label>
                        <select
                            id="AddictForm"
                            name="AddictForm"
                            value={formData.AddictForm}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">اختر أستمارة الحالة</option>
                            {AddictForm && AddictForm.map((form) => (
                                <option key={form._id} value={form._id}>
                                    {form.addict?.addictName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="AddictFamily" className="block text-lg font-medium mb-2">أسرة الحالة</label>
                        <select
                            id="AddictFamily"
                            name="AddictFamily"
                            value={formData.AddictFamily}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">اختر أسرة الحالة</option>
                            {AddictFamily && AddictFamily.map((family) => (
                                <option key={family._id} value={family._id}>
                                    {family.AddictFamilyResponsible}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="AddictFamilyAmount" className="block text-lg font-medium mb-2">مبلغ الأسرة </label>
                        <input
                            type="number"
                            id="AddictFamilyAmount"
                            name="AddictFamilyAmount"
                            value={formData.AddictFamilyAmount}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="AddictDonor" className="block text-lg font-medium mb-2">متبرع الحالة</label>
                        <select
                            id="AddictDonor"
                            name="AddictDonor"
                            value={formData.AddictDonor}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">اختر أسرة الحالة</option>
                            {AddictDonor && AddictDonor.map((donor) => (
                                <option key={donor._id} value={donor._id}>
                                    {donor.AddictDonor} 
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="AddictDonorAmount" className="block text-lg font-medium mb-2">مبلغ المتبرع</label>
                        <input
                            type="number"
                            id="AddictDonorAmount"
                            name="AddictDonorAmount"
                            value={formData.AddictDonorAmount}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="CathedralAmount" className="block text-lg font-medium mb-2">مساهمة الكاتدرائية</label>
                        <input
                            type="number"
                            id="CathedralAmount"
                            name="CathedralAmount"
                            value={formData.CathedralAmount}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="CathedralResponsible" className="block text-lg font-medium mb-2">المسئول من الكاتدرائية</label>
                        <input
                            type="text"
                            id="CathedralResponsible"
                            name="CathedralResponsible"
                            value={formData.CathedralResponsible}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="CathedralResponsiblePhone" className="block text-lg font-medium mb-2">رقم المسئول من الكاتدرائية</label>
                        <input
                            type="text"
                            id="CathedralResponsiblePhone"
                            name="CathedralResponsiblePhone"
                            value={formData.CathedralResponsiblePhone}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="FamilyBoxAmount" className="block text-lg font-medium mb-2">مساهمة صندوق الخدمة </label>
                        <input
                            type="number"
                            id="FamilyBoxAmount"
                            name="FamilyBoxAmount"
                            value={formData.FamilyBoxAmount}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="GodAmount" className="block text-lg font-medium mb-2">مساهمة اخوة رب</label>
                        <input
                            type="number"
                            id="GodAmount"
                            name="GodAmount"
                            value={formData.GodAmount}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="GodResponsible" className="block text-lg font-medium mb-2">المسئول من اخوة رب</label>
                        <input
                            type="text"
                            id="GodResponsible"
                            name="GodResponsible"
                            value={formData.GodResponsible}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="GodResponsiblePhone" className="block text-lg font-medium mb-2">رقم المسئول من اخوة رب</label>
                        <input
                            type="text"
                            id="GodResponsiblePhone"
                            name="GodResponsiblePhone"
                            value={formData.GodResponsiblePhone}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="OtherAmount" className="block text-lg font-medium mb-2">مبالغ اخرى</label>
                        <input
                            type="number"
                            id="OtherAmount"
                            name="OtherAmount"
                            value={formData.OtherAmount}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="FinancialFollowUpDate" className="block text-lg font-medium mb-2">تاريخ المتابعة المالية</label>
                        <input
                            type="number"
                            id="FinancialFollowUpDate"
                            name="FinancialFollowUpDate"
                            value={formData.FinancialFollowUpDate}
                            onChange={handleChange}
                            placeholder="ادخل يوم الشهر من 1 ل 28"
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="col-span-1">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white text-lg font-semibold py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                        >
                            أضافة متابعة مالية
                        </button>
                    </div>
                </form>
                <div className="mt-3 text-center">
                    <h3 className="text-xl font-medium">المبلغ المراد تجميعه: {targetedAmount} </h3>
                    <h3 className="text-xl font-medium">المبلغ المجمع : {formData.totalAmount} </h3>
                </div>
            </div>
        </div>
    );
};

export default AddFinancialFollowUp;
