import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../imgs/logo.png";

const AddAdvance = () => {
    const [names, setNames] = useState('');
    const [id, setid] = useState('');
    const [advance, setAdvance] = useState('');
    const [advanceMonthly, setAdvanceMonthly] = useState('');
    const [advanceDate, setAdvanceDate] = useState('');
    const [advanceReason, setAdvanceReason] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const getData = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}salary`)
            .then(res => setNames(res.data.data))
            .catch(err => console.error(err));
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') navigate('/login');
        } catch {
            navigate('/login');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}advance/add/${id}`, {
                name_id: id,
                advanceExpense: advance,
                advanceDate,
                advanceMonthly,
                advanceRest: advance,
                advanceReason
            });
            setSuccess("تمت اضافة السلفة بنجاح");
            setid('');
            setAdvance('');
            setAdvanceDate('');
            setAdvanceMonthly('');
            setAdvanceReason('');
            setError(null);
        } catch (err) {
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        verifyAdmin();
        getData();
    }, []);

    return (
        <div>
            <div className="px-4">
                <Navbar />
            </div>
            <div className="form w-[75%] m-auto">
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">الأسم المختار</label>
                    <select
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        onChange={(e) => setid(e.target.value)}
                        value={id}
                    >
                        <option selected>من فضلك اختار الاسم</option>
                        {names && names.map((item) => (
                            <option key={item._id} value={item._id}>{item.name}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">تاريخ طلب السلفة</label>
                    <input
                        type="date"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={advanceDate}
                        onChange={(e) => setAdvanceDate(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">قيمة السلفة</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={advance}
                        onChange={(e) => setAdvance(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">قسط السلفة</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={advanceMonthly}
                        onChange={(e) => setAdvanceMonthly(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">تفاصيل السلفة</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={advanceReason}
                        onChange={(e) => setAdvanceReason(e.target.value)}
                    />
                </div>
                {error && (
                    <div className="mt-4 text-red-500 text-center">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="mt-4 text-green-500 text-center">
                        {success}
                    </div>
                )}
                <div className="submit mt-6 text-center">
                    <button
                        type="submit"
                        className="bg-[#000000] text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        onClick={handleSubmit}
                    >
                        أضافة سلفة
                    </button>
                </div>
                <div className="submit mt-6 text-center print:hidden">
                    <button
                        type="submit"
                        className="bg-lime-500 text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        disabled={loading}
                        onClick={() => window.print()}
                    >
                        طباعة
                    </button>
                </div>
                {names && (
                    <div className="print:block hidden mt-10">
                        <div className="my-4">
                            <img src={logo} alt="logo" className="w-32 h-32" />
                        </div>
                        <h2 className="text-3xl text-center font-bold mb-4">طلب سلفة</h2>
                        <p className="text-lg mt-2 mb-2">
                            الي ابينا القمص مدبر الكنيسة \........................ <br /> تحية طبية و بعد <br /> الرجاء من قدسكم الموافقة علي طلب السلفة بقيمة {advance} جنيها
                        </p>
                        <h2 className="text-2xl font-bold mb-4 mt-6">تفاصيل السلفة</h2>
                        <p className="text-xl mb-4">الأسم طالب السلفة: {names.find(item => item._id === id)?.name}</p>
                        <p className="text-xl mb-4">تاريخ طلب السلفة: {advanceDate}</p>
                        <p className="text-xl mb-4">قيمة السلفة: {advance}</p>
                        <p className="text-xl mb-4">قسط السلفة: {advanceMonthly}</p>
                        <p className="text-xl mb-4">سبب السلفة: {advanceReason}</p>
                        <p className="text-xl mb-4 mt-4">الأعتماد</p>
                        <div className="flex justify-between">
                            <div>
                                <p className="text-xl text-left">توقيع الحسابات</p>
                            </div>
                            <div>
                                <p className="text-xl text-right">توقيع مدبر الكنيسة</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddAdvance;
