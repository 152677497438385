import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar";
import Logo from "../../../imgs/logo.png";

const AddPermission = () => {
    const [workers, setWorkers] = useState([]);
    const [workerID, setWorkerID] = useState("");
    const [worker, setWorker] = useState("");
    const [workerDepartment, setWorkerDepartment] = useState("");
    const [workerJob, setWorkerJob] = useState("");
    const [permissionCause, setPermissionCause] = useState("");
    const [stratHour, setStratHour] = useState("");
    const [endHour, setEndHour] = useState("");
    const [dayDate, setDayDate] = useState("");
    const [details, setDetails] = useState("");

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        }).then(() => {
            window.location.reload();
        });
    };

    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const fetchWorkers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/workers`);
            setWorkers(response.data.workers);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchWorkers();
    }, []);

    const navigate = useNavigate();
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== "خزنة") {
                navigate("/login");
            }
        } catch (err) {
            console.error(err);
            navigate("/login");
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    const handleWorkerChange = (e) => {
        const selectedWorker = workers.find(w => w.name === e.target.value);
        setWorker(e.target.value);
        setWorkerID(selectedWorker ? selectedWorker._id : "");
        setWorkerDepartment(selectedWorker ? selectedWorker.departement : "");
        setWorkerJob(selectedWorker ? selectedWorker.job : "");
    };

    const handleSubmitPermission = async () => {
        if (!worker || !workerDepartment || !workerJob || !permissionCause || !stratHour || !endHour || !dayDate || !details) {
            showRequiredAlert("جميع الحقول مطلوبة");
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}permission/`, {
                Name: workerID,
                Departement: workerDepartment,
                Job: workerJob,
                PermissionCause: permissionCause,
                StratHour: stratHour,
                EndHour: endHour,
                DayDate: dayDate,
                Details: details,
            });
            showSuccessAlert("تم إضافة الإذن بنجاح");
            setWorker("");
            setWorkerDepartment("");
            setWorkerJob("");
            setPermissionCause("");
            setStratHour("");
            setEndHour("");
            setDayDate("");
            setDetails("");
        } catch (error) {
            console.log(error);
            showErrorAlert(error.response?.data?.message || "حدث خطأ أثناء إضافة الإذن");
        }
    };

    const formatTimeTo12Hour = (time) => {
        const [hours, minutes] = time.split(':');
        const period = +hours >= 12 ? 'م' : 'ص';
        const formattedHours = (+hours % 12 || 12).toString().padStart(2, '0');
        return `${formattedHours}:${minutes} ${period}`;
    };

    return (
        <div className="w-[75%] mx-auto print:w-[95%]  px-4 py-8">
            <div className="print:hidden">
                <Navbar />
                <h1 className="text-3xl font-bold mb-8 text-center print:hidden">طلب إذن</h1>
                <h1 className="text-3xl font-bold mb-8 text-center underline underline-offset-[10px] hidden print:block">طلب إذن</h1>
                <div className="print:hidden">
                    <div className="grid grid-cols-1 gap-4 mb-6">
                        <select
                            value={worker}
                            onChange={handleWorkerChange}
                            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                        >
                            <option value="">أختر العامل</option>
                            {workers.map((worker, index) => (
                                <option key={index} value={worker.name}>{worker.name}</option>
                            ))}
                        </select>

                        <input
                            type="text"
                            value={workerDepartment}
                            readOnly
                            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                            placeholder="الإدارة"
                        />

                        <input
                            type="text"
                            value={workerJob}
                            readOnly
                            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                            placeholder="الوظيفة"
                        />

                        <input
                            type="text"
                            value={permissionCause}
                            onChange={(e) => setPermissionCause(e.target.value)}
                            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                            placeholder="سبب الإذن"
                        />

                        <label className="text-md">تاريخ الإذن</label>
                        <input
                            type="date"
                            value={dayDate}
                            onChange={(e) => setDayDate(e.target.value)}
                            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                        />

                        <label className="text-md">ساعة بداية الإذن</label>
                        <input
                            type="time"
                            value={stratHour}
                            onChange={(e) => setStratHour(e.target.value)}
                            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                        />

                        <label className="text-md">ساعة نهاية الإذن</label>
                        <input
                            type="time"
                            value={endHour}
                            onChange={(e) => setEndHour(e.target.value)}
                            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                        />

                        <input
                            type="text"
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                            className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                            placeholder="التفاصيل"
                        />
                    </div>
                    <div className="text-center">
                        <button
                            onClick={handleSubmitPermission}
                            className="mb-6 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg shadow-lg hover:bg-indigo-700"
                        >
                            إضافة الإذن
                        </button>
                        <br />
                        <button
                            onClick={() => { window.print(); }}
                            className="mb-6 px-6 py-2 bg-green-600 text-white font-semibold rounded-lg shadow-lg hover:bg-green-700"
                        >
                            طباعة طلب الإذن
                        </button>
                    </div>
                </div>
            </div>
            <div className="hidden print:block print:border-4 p-10">
                <div className="my-4 text-start">
                    <img src={Logo} alt="اللوجو" className="w-32 h-32" />
                </div>
                <div className="text-center mb-4">
                    <p className="text-3xl underline underline-offset-[10px]">
                        طلب إذن
                    </p>
                </div>
                <div className="text-center mb-4">
                    <p className="text-2xl underline underline-offset-[10px]">
                        إلى قدس ابونا مدير الكنيسة : <span className="font-medium">ابونا مكاريوس جمال</span>
                    </p>
                </div>
                <div className="mb-4 mt-4 space-y-4">
                    <p className="text-lg mb-2">
                        العامل: <span className="font-medium">{worker}</span>
                    </p>
                    <p className="text-lg mb-2">
                        الإدارة: <span className="font-medium">{workerDepartment}</span>
                    </p>
                    <p className="text-lg mb-2">
                        الوظيفة: <span className="font-medium">{workerJob}</span>
                    </p>
                    <p className="text-lg mb-2">
                        سبب الإذن: <span className="font-medium">{permissionCause}</span>
                    </p>
                    <p className="text-lg mb-2">
                        تاريخ الإذن: <span className="font-medium">{dayDate}</span>
                    </p>
                    <div className="flex justify-between">
                        <p className="text-lg mb-2">
                            من: <span className="font-medium">{formatTimeTo12Hour(stratHour)}</span>
                        </p>
                        <p className="text-lg mb-2">
                            إلى: <span className="font-medium">{formatTimeTo12Hour(endHour)}</span>
                        </p>
                    </div>
                    <p className="text-lg mb-2">
                        تفاصيل: <span className="font-medium">{details}</span>
                    </p>
                </div>
                <div className="text-center mt-8">
                    <div className="flex justify-between">
                        <p className="text-lg mb-4">
                            توقيع صاحب الإذن: 
                        </p>
                        <p className="text-lg mb-4">
                            توقيع الإدارة: 
                        </p>
                        <p className="text-lg mb-4">
                            توقيع مدبر الكنيسة: 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPermission;