import { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import Logo from "../../imgs/logo.png"
const AddWorker = () => {
    const [name, setName] = useState('');
    const [departement, setDepartement] = useState('');
    const [job, setJob] = useState('');
    const [address, setAddress] = useState('');
    const [startDate, setStartDate] = useState('');
    const [ID, setID] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [age, setAge] = useState('');
    const [qualifier, setQualifier] = useState('');
    const [assurance, setAssurance] = useState('');
    const [assuranceNumber, setAssuranceNumber] = useState('');
    const [soldier, setSoldier] = useState('');
    const [phone, setPhone] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [notes, setNotes] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate('')
    const [loading, setLoading] = useState('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, [])
    useEffect(() => {
        if (birthDate) {
            const now = new Date();
            const birthYear = new Date(birthDate).getFullYear();
            setAge(now.getFullYear() - birthYear);
        }
    }, [birthDate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}add/worker`, {
                name,
                departement,
                job,
                address,
                startDate,
                ID,
                birthDate,
                age,
                qualifier,
                assurance,
                assuranceNumber,
                soldier,
                phone,
                whatsapp,
                notes
            });
            setSuccess('تم إضافة الموظف بنجاح');
            setError(null);
            resetForm();
            setLoading(true)
        } catch (err) {
            console.log(err)
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
            setLoading(true)
        } finally {
            setLoading(false)
        }
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}add/search-worker`, {
                name
            });
            setName(response.data.workers[0].name)
            setDepartement(response.data.workers[0].departement)
            setJob(response.data.workers[0].job);
            setAddress(response.data.workers[0].address);
            setID(response.data.workers[0].ID);
            setBirthDate(response.data.workers[0].birthDate);
            setStartDate(response.data.workers[0].startDate);
            setAge(response.data.workers[0].age);
            setQualifier(response.data.workers[0].qualifier);
            setAssurance(response.data.workers[0].assurance);
            setAssuranceNumber(response.data.workers[0].assuranceNumber);
            setSoldier(response.data.workers[0].soldier);
            setPhone(response.data.workers[0].phone);
            setWhatsapp(response.data.workers[0].whatsapp);
            setNotes(response.data.workers[0].notes);
            setError(null);
            setLoading(true)
        } catch (err) {
            console.log(err)
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
            setLoading(true)
        } finally {
            setLoading(false)
        }
    };

    const handlePrint = () => {
        window.print()
    }
    const resetForm = () => {
        setName('');
        setJob('');
        setDepartement('');
        setAddress('');
        setID('');
        setBirthDate('');
        setStartDate('');
        setAge('');
        setQualifier('');
        setAssurance('');
        setAssuranceNumber('');
        setSoldier('');
        setPhone('');
        setWhatsapp('');
        setNotes('');
    };

    return (
        <div className="add-worker">
            <div className="w-[90%] m-auto print:hidden">
                <Navbar />
                <div className="add-heading mt-5">
                    <h1 className="text-center text-3xl font-bold">صفحة اضافة الموظفين الجدد في الكنيسة</h1>
                </div>
                <div className="form w-[80%] m-auto">
                    <form className="flex flex-wrap justify-center gap-3" onSubmit={handleSubmit}>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">الأسم</label>
                            <input
                                type="text"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">الوظيفة</label>
                            <input
                                type="text"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={job}
                                onChange={(e) => setJob(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">الادارة</label>
                            <input
                                type="text"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={departement}
                                onChange={(e) => setDepartement(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">العنوان</label>
                            <input
                                type="text"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">تاريخ التعيين</label>
                            <input
                                type="date"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">الرقم القومي</label>
                            <input
                                type="number"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={ID}
                                onChange={(e) => setID(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">تاريخ الميلاد</label>
                            <input
                                type="date"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={birthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">السن</label>
                            <input
                                type="number"
                                disabled
                                value={age}
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">المؤهل</label>
                            <input
                                type="text"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={qualifier}
                                onChange={(e) => setQualifier(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">تأمين اجتماعي</label>
                            <input
                                type="text"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={assurance}
                                onChange={(e) => setAssurance(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">الرقم التأميني</label>
                            <input
                                type="number"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={assuranceNumber}
                                onChange={(e) => setAssuranceNumber(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">التجنيد</label>
                            <input
                                type="text"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={soldier}
                                onChange={(e) => setSoldier(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">رقم الموبايل</label>
                            <input
                                type="text"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px]">
                            <label className="text-xl">رقم الواتس</label>
                            <input
                                type="text"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={whatsapp}
                                onChange={(e) => setWhatsapp(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-4 w-[340px] md:w-[80%]">
                            <label className="text-xl">ملاحظات</label>
                            <input
                                type="text"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                            />
                        </div>
                        {error && (
                            <div className="w-full mt-4 text-center text-red-500">
                                <p>{error}</p>
                            </div>
                        )}
                        {success && (
                            <div className="w-full mt-4 text-center text-green-500">
                                <p>{success}</p>
                            </div>
                        )}
                        <div className="submit mt-6 text-center w-full">
                            <button type="submit"
                                disabled={loading}
                                className="bg-[#000000] text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">
                                أضافة موظف
                            </button>
                        </div>
                        {
                            name ?
                                <div className="submit mt-6 text-center w-full">
                                    <button 
                                        onClick={handleSearch}
                                        disabled={loading}
                                        className="bg-lime-500 text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-lime-600 duration-200">
                                        بحث
                                    </button>
                                </div> : ''
                        }
                    </form>
                    <div className="mt-6 text-center w-full">
                        <button
                            onClick={handlePrint}
                            className="bg-lime-500 text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-lime-400 duration-200">
                            طباعة
                        </button>
                    </div>
                </div>
            </div>
            <div className="w-[90%] m-auto hidden print:block border border-gray-200 p-5">
                <div className="my-4">
                    <img src={Logo} alt="لوجو" className="w-52 h-52" />
                </div>
                <div className="add-heading mt-5 print:mt-0">
                    <h1 className="text-center text-3xl font-bold">بيانات اضافة الموظفين لكنيسة ماري جرجس المذبح</h1>
                </div>
                <div className="add-heading mt-5 print:mt-0">
                    <h1 className="text-center text-2xl font-bold">بيانات الموظف</h1>
                </div>
                <div className="form w-[80%] m-auto print:w-full print:grid print:grid-cols-2 print:gap-4 print:text-xl print:p-5">
                    <div className="form-group print:mb-2">
                        <p>الأسم: {name}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>الوظيفة: {job}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>الادارة: {departement}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>العنوان: {address}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>تاريخ التعيين: {new Date(startDate).toLocaleDateString("en-uk")}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>الرقم القومي: {ID}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>تاريخ الميلاد: {new Date(birthDate).toLocaleDateString("en-uk")}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>السن: {age}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>المؤهل: {qualifier}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>تأمين اجتماعي: {assurance}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>الرقم التأميني: {assuranceNumber}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>التجنيد: {soldier}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>رقم الموبايل: {phone}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>رقم الواتس: {whatsapp}</p>
                    </div>
                    <div className="form-group print:mb-2">
                        <p>ملاحظات: {notes}</p>
                    </div>
                </div>
                <div className="form-group flex justify-between px-4 print:mb-2">
                    <p>توقيع الموظف الجديد</p>
                    <p>توقيع مسئول الحسابات</p>
                </div>
                <div className="form-group flex justify-between px-4 print:mb-2">
                    <p>................................</p>
                    <p>................................</p>
                </div>
            </div>
        </div>
    );
};

export default AddWorker;
