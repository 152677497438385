const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handleInputChange = (e) => {
        const pageNumber = Number(e.target.value);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            onPageChange(pageNumber);
        }
    };

    return (
        <div className="flex justify-center mt-4 items-center">
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="py-2 px-4 mx-1 border rounded bg-gray-200"
            >
                السابق
            </button>
            <input
                type="number"
                value={currentPage}
                onChange={handleInputChange}
                className="py-2 px-4 mx-1 border rounded text-center"
                min="1"
                max={totalPages}
            />
            <span className="mx-2">من {totalPages}</span>
            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="py-2 px-4 mx-1 border rounded bg-gray-200"
            >
                التالي
            </button>
        </div>
    );
};

export default Pagination;