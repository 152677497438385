import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar";

const AddReceipt = () => {
    const [formData, setFormData] = useState({
        Addict: "",
        Responsible: "",
        ResponsiblePhone: "",
        Amount: 0,
        ReceiptFile: null,
        details: "",
    });

    const [addicts, setAddicts] = useState([]);
    const [loading, setLoading] = useState('');

    useEffect(() => {
        const fetchAddicts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict`);
                setAddicts(response.data);
            } catch (error) {
                console.error("Error fetching addicts", error);
            }
        };

        fetchAddicts();
    }, []);

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        }).then(() => {
            window.location.reload();
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, ReceiptFile: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append("Addict", formData.Addict);
        data.append("Responsible", formData.Responsible);
        data.append("ResponsiblePhone", formData.ResponsiblePhone);
        data.append("Amount", formData.Amount);
        data.append("ReceiptFile", formData.ReceiptFile);
        data.append("details", formData.details);
        setLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}receipt`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم اضافة ايصال جديد بنجاح`,
            });
            showSuccessAlert("تمت الإضافة بنجاح");
            setFormData({
                Addict: "",
                Responsible: "",
                ResponsiblePhone: "",
                Amount: 0,
                ReceiptFile: null,
                details: "",
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response && error.response.data.error) {
                showErrorAlert(error.response.data.error);
            } else {
                showErrorAlert('حدث خطأ أثناء الإضافة');
            }
        }
    };

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">إضافة إيصال جديد</h1>
            <div className="max-w-4xl mx-auto p-8 bg-white rounded-xl shadow-lg">
                <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <label htmlFor="Addict" className="block text-lg font-medium mb-2">الحالة</label>
                        <select
                            id="Addict"
                            name="Addict"
                            value={formData.Addict}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        >
                            <option value="">اختر الحالة</option>
                            {addicts.map((addict) => (
                                <option key={addict._id} value={addict._id}>
                                    {addict.addictName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="Responsible" className="block text-lg font-medium mb-2">المسؤول</label>
                        <input
                            type="text"
                            id="Responsible"
                            name="Responsible"
                            value={formData.Responsible}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="ResponsiblePhone" className="block text-lg font-medium mb-2">رقم تليفون المسئول</label>
                        <input
                            type="text"
                            id="ResponsiblePhone"
                            name="ResponsiblePhone"
                            value={formData.ResponsiblePhone}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="Amount" className="block text-lg font-medium mb-2">المبلغ</label>
                        <input
                            type="Number"
                            id="Amount"
                            name="Amount"
                            value={formData.Amount}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div className="col-span-1 md:col-span-2">
                        <label htmlFor="ReceiptFile" className="block text-lg font-medium mb-2">ملف الإيصال</label>
                        <input
                            type="file"
                            id="ReceiptFile"
                            name="ReceiptFile"
                            onChange={handleFileChange}
                            accept=".pdf"
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div className="col-span-1 md:col-span-2">
                        <label htmlFor="details" className="block text-lg font-medium mb-2">التفاصيل</label>
                        <textarea
                            id="details"
                            name="details"
                            value={formData.details}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div className="col-span-1 md:col-span-2">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white text-lg font-semibold py-3 rounded-lg"
                            disabled={loading}
                        >
                            إضافة الإيصال
                        </button>
                        <p className="text-center text-lg mt-3">{loading && "جاري رفع الملفات ......"}</p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddReceipt;