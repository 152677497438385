import Swal from "sweetalert2";
import React, { useState } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar";

const AddAddict = () => {
    const [formData, setFormData] = useState({
        addictName: "",
        addictID: "",
        addictAddress: "",
        addictPhone1: "",
        addictPhone2: "",
        addictPhone3: "",
        addictEntryDate: "",
        addictExitDate: "",
        addictCode: "",
        addictResponsibleServer: "",
        addictBirthDate: "",
        addictAddictions: [""],
        addictExceptions: [""],
    });
    const [age,setAge] = useState('');
    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "addictBirthDate") {
            const birthDate = new Date(value);
            const age = Math.floor((new Date() - birthDate) / (1000 * 60 * 60 * 24 * 365.25));
            setAge(age);
            setFormData({ ...formData, addictBirthDate: value, age });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (new Date(formData.addictExitDate) < new Date(formData.addictEntryDate)) {
            showErrorAlert("تاريخ الخروج يجب أن يكون بعد تاريخ الدخول");
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}addict`, formData);
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم اضافة حالة بنجاح`,
            });
            showSuccessAlert("تمت الاضافة بنجاح");
            setAge('');
            setFormData({
                addictName: "",
                addictID: "",
                addictAddress: "",
                addictPhone1: "",
                addictPhone2: "",
                addictPhone3: "",
                addictEntryDate: "",
                addictExitDate: "",
                addictCode: "",
                addictResponsibleServer: "",
                addictBirthDate: "",
                addictAddictions: [""],
                addictExceptions: [""],
            });
        } catch (error) {
            console.error(error);
            showErrorAlert("حدث خطأ اثناء الاضافة");
        }
    };

    const handleAddToList = (listName) => {
        setFormData({ ...formData, [listName]: [...formData[listName], ""] });
    };

    const handleListChange = (listName, index, value) => {
        const updatedList = [...formData[listName]];
        updatedList[index] = value;
        setFormData({ ...formData, [listName]: updatedList });
    };

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">اضف حالة جديدة</h1>
            <div className="max-w-4xl mx-auto p-8 bg-white rounded-xl shadow-lg">
                <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <label htmlFor="addictName" className="block text-lg font-medium mb-2">الأسم</label>
                        <input
                            type="text"
                            id="addictName"
                            name="addictName"
                            value={formData.addictName}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictID" className="block text-lg font-medium mb-2">الرقم القومي للحالة</label>
                        <input
                            type="text"
                            id="addictID"
                            name="addictID"
                            value={formData.addictID}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictAddress" className="block text-lg font-medium mb-2">العنوان</label>
                        <input
                            type="text"
                            id="addictAddress"
                            name="addictAddress"
                            value={formData.addictAddress}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictPhone1" className="block text-lg font-medium mb-2">رقم التليفون الاول</label>
                        <input
                            type="text"
                            id="addictPhone1"
                            name="addictPhone1"
                            value={formData.addictPhone1}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictPhone2" className="block text-lg font-medium mb-2">رقم التليفون التاني</label>
                        <input
                            type="text"
                            id="addictPhone2"
                            name="addictPhone2"
                            value={formData.addictPhone2}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictPhone3" className="block text-lg font-medium mb-2">رقم التليفون التالت</label>
                        <input
                            type="text"
                            id="addictPhone3"
                            name="addictPhone3"
                            value={formData.addictPhone3}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictResponsibleServer" className="block text-lg font-medium mb-2">اسم الخادم المسئول</label>
                        <input
                            type="text"
                            id="addictResponsibleServer"
                            name="addictResponsibleServer"
                            value={formData.addictResponsibleServer}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictBirthDate" className="block text-lg font-medium mb-2">تاريخ الميلاد</label>
                        <input
                            type="date"
                            id="addictBirthDate"
                            name="addictBirthDate"
                            value={formData.addictBirthDate}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="age" className="block text-lg font-medium mb-2">العمر</label>
                        <input
                            type="number"
                            id="age"
                            name="age"
                            readOnly
                            value={age}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictEntryDate" className="block text-lg font-medium mb-2">تاريخ الدخول</label>
                        <input
                            type="date"
                            id="addictEntryDate"
                            name="addictEntryDate"
                            value={formData.addictEntryDate}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictExitDate" className="block text-lg font-medium mb-2">تاريخ الخروج</label>
                        <input
                            type="date"
                            id="addictExitDate"
                            name="addictExitDate"
                            value={formData.addictExitDate}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictAddictions" className="block text-lg font-medium mb-2">مواد التعاطي</label>
                        {formData.addictAddictions.map((addiction, index) => (
                            <div key={index} className="mb-4">
                                <input
                                    type="text"
                                    value={addiction}
                                    onChange={(e) => handleListChange("addictAddictions", index, e.target.value)}
                                    className="w-full border rounded-lg px-4 py-3"
                                />
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={() => handleAddToList("addictAddictions")}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg"
                        >
                            إضافة مادة تعاطي
                        </button>
                    </div>
                    <div>
                        <label htmlFor="addictExceptions" className="block text-lg font-medium mb-2">الاستثناءات</label>
                        {formData.addictExceptions.map((exception, index) => (
                            <div key={index} className="mb-4">
                                <input
                                    type="text"
                                    value={exception}
                                    onChange={(e) => handleListChange("addictExceptions", index, e.target.value)}
                                    className="w-full border rounded-lg px-4 py-3"
                                />
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={() => handleAddToList("addictExceptions")}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg"
                        >
                            إضافة استثناء
                        </button>
                    </div>
                    <div className="col-span-1 md:col-span-2">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white text-lg font-semibold py-3 rounded-lg"
                        >
                            أضافة حالة
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddAddict;
