import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Logo from "../../../imgs/logo.png";

const MissionHistory = () => {
    const [missions, setMissions] = useState([]);
    const [filteredMissions, setFilteredMissions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [nameFilter, setNameFilter] = useState("");
    const [departmentFilter, setDepartmentFilter] = useState("");
    const [jobFilter, setJobFilter] = useState("");
    const [worker, setWorker] = useState("");
    const [workerDepartment, setWorkerDepartment] = useState("");
    const [workerJob, setWorkerJob] = useState("");
    const [missionPlace, setMissionPlace] = useState("");
    const [stratHour, setStratHour] = useState("");
    const [endHour, setEndHour] = useState("");
    const [dayDate, setDayDate] = useState("");
    const [details, setDetails] = useState("");

    const fetchMissions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}mission/`);
            setMissions(response.data);
            setFilteredMissions(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const navigate = useNavigate();
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== "خزنة") {
                navigate("/login");
            }
        } catch (err) {
            console.error(err);
            navigate("/login");
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    useEffect(() => {
        fetchMissions();
    }, []);

    const handleSearch = () => {
        let filtered = missions;

        if (nameFilter) {
            filtered = filtered.filter(mission =>
                mission.Name?.name?.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }

        if (departmentFilter) {
            filtered = filtered.filter(mission =>
                mission.Departement.toLowerCase().includes(departmentFilter.toLowerCase())
            );
        }

        if (jobFilter) {
            filtered = filtered.filter(mission =>
                mission.Job.toLowerCase().includes(jobFilter.toLowerCase())
            );
        }

        setFilteredMissions(filtered);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginatedData = filteredMissions.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const formatTimeTo12Hour = (time) => {
        const [hours, minutes] = time.split(':');
        const period = +hours >= 12 ? 'م' : 'ص';
        const formattedHours = (+hours % 12 || 12).toString().padStart(2, '0');
        return `${formattedHours}:${minutes} ${period}`;
    };

    const totalPages = Math.ceil(filteredMissions.length / itemsPerPage);

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}mission/${id}`);
                    fetchMissions();
                    Swal.fire('تم الحذف!', 'تم حذف المأمورية.', 'success');
                } catch (error) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء حذف المأمورية.', 'error');
                }
            }
        });
    };

    const hadlePrint = async (item) => {
        await setWorker(item.Name.name)
        await setWorkerDepartment(item.Departement)
        await setWorkerJob(item.Job)
        await setMissionPlace(item.MissionPlace)
        await setStratHour(new Date(item.StratHour).toLocaleTimeString("ar-EG", { hour: '2-digit', minute: '2-digit', hour12: true }))
        await setEndHour(new Date(item.EndHour).toLocaleTimeString("ar-EG", { hour: '2-digit', minute: '2-digit', hour12: true }))
        await setDayDate(new Date(item.DayDate).toLocaleDateString("en-uk"))
        await setDetails(item.Details)
        window.print();
    }
    return (
        <div className="container mx-auto px-4 py-8">
            <div className="print:hidden">
                <h1 className="text-3xl font-bold mb-8 text-center">سجل المأموريات</h1>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
                    <input
                        type="text"
                        placeholder="البحث بالاسم"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                    />
                    <input
                        type="text"
                        placeholder="الإدارة"
                        value={departmentFilter}
                        onChange={(e) => setDepartmentFilter(e.target.value)}
                        className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                    />
                    <input
                        type="text"
                        placeholder="الوظيفة"
                        value={jobFilter}
                        onChange={(e) => setJobFilter(e.target.value)}
                        className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                    />
                </div>

                <button
                    onClick={handleSearch}
                    className="mb-6 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg shadow-lg hover:bg-indigo-700"
                >
                    بحث
                </button>

                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white shadow-lg rounded-lg">
                        <thead className="bg-indigo-700 text-white">
                            <tr>
                                <th className="py-4 px-6 text-right">الاسم</th>
                                <th className="py-4 px-6 text-right">الادارة</th>
                                <th className="py-4 px-6 text-right">الوظيفة</th>
                                <th className="py-4 px-6 text-right">مكان المأمورية</th>
                                <th className="py-4 px-6 text-right">من ساعة</th>
                                <th className="py-4 px-6 text-right">إلى ساعة</th>
                                <th className="py-4 px-6 text-right">تاريخ المأمورية</th>
                                <th className="py-4 px-6 text-right">سبب المأمورية</th>
                                <th className="py-4 px-6 text-center">إجراءات</th>
                                <th className="py-4 px-6 text-center">طباعة</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.length > 0 ? (
                                paginatedData.map((mission, index) => (
                                    <tr key={index} className="border-b even:bg-gray-100">
                                        <td className="py-4 px-6 text-right">{mission.Name?.name || "N/A"}</td>
                                        <td className="py-4 px-6 text-right">{mission.Departement || "N/A"}</td>
                                        <td className="py-4 px-6 text-right">{mission.Job || "N/A"}</td>
                                        <td className="py-4 px-6 text-right">{mission.MissionPlace}</td>
                                        <td className="py-4 px-6 text-right">{new Date(mission.StratHour).toLocaleTimeString("ar-EG", { hour: '2-digit', minute: '2-digit', hour12: true })}</td>
                                        <td className="py-4 px-6 text-right">{new Date(mission.EndHour).toLocaleTimeString("ar-EG", { hour: '2-digit', minute: '2-digit', hour12: true })}</td>
                                        <td className="py-4 px-6 text-right">{new Date(mission.DayDate).toLocaleDateString("en-uk")}</td>
                                        <td className="py-4 px-6 text-right">{mission.Details}</td>
                                        <td className="py-4 px-6 text-center">
                                            <button
                                                onClick={() => handleDelete(mission._id)}
                                                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                                            >
                                                حذف
                                            </button>
                                        </td>
                                        <td className="py-4 px-6 text-center">
                                            <button
                                                onClick={() => hadlePrint(mission)}
                                                className="px-4 py-2 bg-lime-600 text-white rounded-lg hover:bg-lime-700"
                                            >
                                                طباعة
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="9" className="py-4 text-center">لم يتم العثور على سجلات</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="mt-6 flex justify-center items-center space-x-4">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`px-4 py-2 mx-4 rounded-lg ${currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-indigo-600 text-white hover:bg-indigo-500"}`}
                    >
                        السابق
                    </button>
                    <div className="flex items-center space-x-2">
                        <input
                            type="number"
                            min="1"
                            max={totalPages}
                            value={currentPage}
                            onChange={(e) => handlePageChange(Number(e.target.value))}
                            className="w-14 text-center border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                        <span className="text-sm font-semibold text-gray-700">/ {totalPages}</span>
                    </div>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`px-4 py-2 rounded-lg ${currentPage === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-indigo-600 text-white hover:bg-indigo-500"}`}
                    >
                        التالي
                    </button>
                </div>

            </div>

            <div className="hidden print:block print:border-4 p-10">
                <div className="my-4 text-start">
                    <img src={Logo} alt="اللوجو" className="w-32 h-32" />
                </div>
                <div className="text-center mb-4">
                    <p className="text-3xl underline underline-offset-[10px]">
                        طلب مأمورية
                    </p>
                </div>
                <div className="text-center mb-4">
                    <p className="text-2xl underline underline-offset-[10px]">
                        إلى قدس ابونا مدير الكنيسة : <span className="font-medium">ابونا مكاريوس جمال</span>
                    </p>
                </div>
                <div className="mb-4 mt-4 space-y-4">
                    <p className="text-lg mb-2">
                        العامل: <span className="font-medium">{worker}</span>
                    </p>
                    <p className="text-lg mb-2">
                        الإدارة: <span className="font-medium">{workerDepartment}</span>
                    </p>
                    <p className="text-lg mb-2">
                        الوظيفة: <span className="font-medium">{workerJob}</span>
                    </p>
                    <p className="text-lg mb-2">
                        مكان المأمورية: <span className="font-medium">{missionPlace}</span>
                    </p>
                    <p className="text-lg mb-2">
                        تاريخ المأمورية: <span className="font-medium">{dayDate}</span>
                    </p>
                    <div className="flex justify-between">
                        <p className="text-lg mb-2">
                            من: <span className="font-medium">{(stratHour)}</span>
                        </p>
                        <p className="text-lg mb-2">
                            إلى: <span className="font-medium">{(endHour)}</span>
                        </p>
                    </div>
                    <p className="text-lg mb-2">
                        سبب المأمورية: <span className="font-medium">{details}</span>
                    </p>
                </div>
                <div className="text-center mt-8">
                    <div className="flex justify-between">
                        <p className="text-lg mb-4">
                            توقيع صاحب المأمورية:
                        </p>
                        <p className="text-lg mb-4">
                            توقيع الإدارة:
                        </p>
                        <p className="text-lg mb-4">
                            توقيع مدبر الكنيسة:
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MissionHistory;