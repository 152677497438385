import Swal from "sweetalert2";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../../imgs/logo.png'
const PenaletyHistory = () => {
    const [penalties, setPenalties] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [name,setName] = useState('');
    const [penaletyDate, setPenaletyDate] = useState('');
    const [penaletyExpense, setPenaletyExpense] = useState('');
    const [reason, setReason] = useState('');
    const itemsPerPage = 10;
    const navigate = useNavigate('');

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };
    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleDelete = async (id, name) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: `هل تريد حذف ${name}؟`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${process.env.REACT_APP_HOST_SERVER}penalety/delete/${id}`, {
                        data: { name }
                    });
                    showSuccessAlert('تم الحذف بنجاح');
                    fetchPenalties();
                } catch (err) {
                    showErrorAlert('حدث خطأ برجاء مراجعة البيانات');
                    console.log('deletion error', err);
                }
            }
        });
    };



    const fetchPenalties = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}penalety`);
            setPenalties(response.data);
        } catch (error) {
            console.error('Error fetching penalties:', error);
        }
    };
    useEffect(() => {
        verifyAdmin();
        fetchPenalties();
    }, []);


    const filteredPenalties = penalties.filter(penalty =>
        penalty.name_id?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastPenalty = currentPage * itemsPerPage;
    const indexOfFirstPenalty = indexOfLastPenalty - itemsPerPage;
    const currentPenalties = filteredPenalties.slice(indexOfFirstPenalty, indexOfLastPenalty);
    const totalPages = Math.ceil(filteredPenalties.length / itemsPerPage);

    const handlePrint = async(item) => {
        await setName(item.name_id.name)
        await setPenaletyDate(new Date(item.PenaletyDate).toLocaleDateString('en-uk'))
        await setPenaletyExpense(item.PenaletyExpense)
        await setReason(item.reason)
        window.print();
    };

    return (
        <div className="p-6 bg-white shadow rounded-md w-[90%] mx-auto mt-6 relative">
            <div className="print:hidden">
                <h2 className="text-2xl font-bold mb-4 text-blue-600">جدول الجزائات</h2>

                <input
                    type="text"
                    placeholder="بحث بالاسم"
                    className="mb-4 p-2 border border-blue-300 rounded w-full"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                <table className="w-full border border-gray-300 text-center">
                    <thead>
                        <tr className="bg-blue-100">
                            <th className="border px-4 py-2">الاسم</th>
                            <th className="border px-4 py-2">قيمة الجزاء</th>
                            <th className="border px-4 py-2">تاريخ الجزاء</th>
                            <th className="border px-4 py-2">سبب الجزاء</th>
                            <th className="border px-4 py-2">اجرائات</th>
                            <th className="border px-4 py-2">طباعة</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPenalties.map(penalty => (
                            <tr key={penalty._id}>
                                <td className="border px-4 py-2">{penalty.name_id?.name}</td>
                                <td className="border px-4 py-2">{penalty.PenaletyExpense.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                <td className="border px-4 py-2">{new Date(penalty.PenaletyDate).toLocaleDateString('EN')}</td>
                                <td className="border px-4 py-2">{penalty.reason ? penalty.reason : "لا يوجد"}</td>
                                <td className="border px-4 py-2">
                                    <button
                                        onClick={() => {
                                            handleDelete(penalty._id, penalty.name_id.name)
                                        }}
                                        className='px-6 py-2 text-white bg-red-500 rounded-2xl hover:bg-red-600 duration-100'>
                                        حذف
                                    </button>
                                </td>
                                <td className="border px-4 py-2">
                                    <button
                                        onClick={() => {
                                            handlePrint(penalty)
                                        }}
                                        className='px-6 py-2 text-white bg-lime-500 rounded-2xl hover:bg-lime-600 duration-100'>
                                        طباعة
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="flex justify-between mt-4">
                    <button
                        className="bg-blue-600 text-white px-4 py-2 rounded disabled:opacity-50"
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        السابق
                    </button>
                    <span>
                        صفحة {currentPage} من {totalPages}
                    </span>
                    <button
                        className="bg-blue-600 text-white px-4 py-2 rounded disabled:opacity-50"
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        التالي
                    </button>
                </div>
            </div>
            <div className="print:block hidden mt-10 border-2 p-14">
                <div className="my-4">
                    <img src={logo} alt="logo" className="w-32 h-32" />
                </div>
                <h2 className="text-3xl text-center font-bold mb-6">جزاء</h2>
                <p className="text-lg mb-6 leading-loose">
                    الي ابينا القمص مدبر الكنيسة \........................<br />
                    تحية طيبة و بعد <br />
                    الرجاء من قدسكم اعتماد هذا الجزاء بقيمة {penaletyExpense} جنيها
                </p>
                <h2 className="text-2xl font-bold mb-4">تفاصيل الجزاء</h2>
                <p className="text-xl mb-4">أسم الحاصل علي الجزاء: {name}</p>
                <p className="text-xl mb-4">تاريخ طلب الجزاء: {penaletyDate}</p>
                <p className="text-xl mb-4">قيمة الجزاء: {penaletyExpense}</p>
                <p className="text-xl mb-4">سبب الجزاء: {reason}</p>
                <div className="flex justify-between items-center border-t pt-4">
                    <p className="text-xl">توقيع الحسابات</p>
                    <p className="text-xl">توقيع مدبر الكنيسة</p>
                </div>
            </div>
        </div>
    );
};

export default PenaletyHistory;
