import { Link, useNavigate } from 'react-router-dom';
import logo from '../imgs/logo.png';

const Navbar = () => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
        sessionStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <div className="Navbar">
            <div className='logo w-fit m-auto md:m-0 relative'>
                <button 
                    onClick={handleLogoClick} 
                    className='focus:outline-none relative group'
                >
                    <img src={logo} alt="logo" className='w-[200px] h-[150px]' />
                    <span className="absolute bottom-[-30px] left-1/2 transform -translate-x-1/2 bg-gray-700 text-white text-sm py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                        تسجيل الخروج
                    </span>
                </button>
            </div>
        </div>
    );
}

export default Navbar;
