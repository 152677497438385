import Swal from "sweetalert2";
import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../../components/Navbar';

const AddSanatorium = () => {
    const [formData, setFormData] = useState({
        sanatoriumName: '',
        sanatoriumAddress: '',
        sanatoriumAdmin: '',
        sanatoriumAdminPhone: '',
        sanatoriumFinanceAdmin: '',
        sanatoriumFinanceAdminPhone: '',
        sanatoriumExceptions: [''],
        delviryExpensee:'',
        delviryExpenseeresponsible:'',
        delviryExpenseeresponsiblePhone:'',
        residenceExpense:'',
        personalExpense:'',
        medicalExpense:'',
        cigiratesExpense:'',
        medicalTestsExpenes:'',
        xraysExpense:'',
        kantennExpense:'',
        otherExpenses:'',
        therapySessionExpense:'',
        DetoksExpense:'',
        extramedicineExpense:'',
        extramedicineTestsandxraysExpense:'',
        goBackExpense:'',
        changePlaceExpense:'',
    });

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleExceptionChange = (index, value) => {
        const updatedExceptions = [...formData.sanatoriumExceptions];
        updatedExceptions[index] = value;
        setFormData({ ...formData, sanatoriumExceptions: updatedExceptions });
    };

    const handleAddException = () => {
        setFormData({ ...formData, sanatoriumExceptions: [...formData.sanatoriumExceptions, ''] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}sanatorium`, formData);
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم إضافة مصحة بنجاح`,
            });
            showSuccessAlert('تمت الاضافة بنجاح');
            setFormData({
                sanatoriumName: '',
                sanatoriumAddress: '',
                sanatoriumAdmin: '',
                sanatoriumAdminPhone: '',
                sanatoriumFinanceAdmin: '',
                sanatoriumFinanceAdminPhone: '',
                sanatoriumExceptions: [''],
                delviryExpensee:'',
                delviryExpenseeresponsible:'',
                delviryExpenseeresponsiblePhone:'',
                residenceExpense:'',
                personalExpense:'',
                medicalExpense:'',
                cigiratesExpense:'',
                medicalTestsExpenes:'',
                xraysExpense:'',
                kantennExpense:'',
                otherExpenses:'',
                therapySessionExpense:'',
                DetoksExpense:'',
                extramedicineExpense:'',
                extramedicineTestsandxraysExpense:'',
                goBackExpense:'',
                changePlaceExpense:'',
            });
        } catch (error) {
            console.error(error);
            showErrorAlert('حدث خطأ اثناء الاضافة');
        }
    };

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">اضف مصحة جديدة</h1>
            <div className="max-w-6xl mx-auto p-8 bg-white rounded-xl shadow-lg">
                <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div>
                        <label htmlFor="sanatoriumName" className="block text-lg font-medium mb-2">اسم المصحة</label>
                        <input
                            type="text"
                            id="sanatoriumName"
                            name="sanatoriumName"
                            value={formData.sanatoriumName}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumAddress" className="block text-lg font-medium mb-2">العنوان</label>
                        <input
                            type="text"
                            id="sanatoriumAddress"
                            name="sanatoriumAddress"
                            value={formData.sanatoriumAddress}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumAdmin" className="block text-lg font-medium mb-2">إسم مدير المصحة</label>
                        <input
                            type="text"
                            id="sanatoriumAdmin"
                            name="sanatoriumAdmin"
                            value={formData.sanatoriumAdmin}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumAdminPhone" className="block text-lg font-medium mb-2">رقم تليفون المدير</label>
                        <input
                            type="text"
                            id="sanatoriumAdminPhone"
                            name="sanatoriumAdminPhone"
                            value={formData.sanatoriumAdminPhone}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumFinanceAdmin" className="block text-lg font-medium mb-2">إسم مدير الشؤون المالية</label>
                        <input
                            type="text"
                            id="sanatoriumFinanceAdmin"
                            name="sanatoriumFinanceAdmin"
                            value={formData.sanatoriumFinanceAdmin}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumFinanceAdminPhone" className="block text-lg font-medium mb-2">رقم تليفون المدير المالي</label>
                        <input
                            type="text"
                            id="sanatoriumFinanceAdminPhone"
                            name="sanatoriumFinanceAdminPhone"
                            value={formData.sanatoriumFinanceAdminPhone}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpensee" className="block text-lg font-medium mb-2">مصاريف الشحن</label>
                        <input
                            type="number"
                            id="delviryExpensee"
                            name="delviryExpensee"
                            value={formData.delviryExpensee}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpenseeresponsible" className="block text-lg font-medium mb-2">المسئول عن الشحن</label>
                        <input
                            type="text"
                            id="delviryExpenseeresponsible"
                            name="delviryExpenseeresponsible"
                            value={formData.delviryExpenseeresponsible}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpenseeresponsiblePhone" className="block text-lg font-medium mb-2">رقم تليفون المسئول عن الشحن</label>
                        <input
                            type="text"
                            id="delviryExpenseeresponsiblePhone"
                            name="delviryExpenseeresponsiblePhone"
                            value={formData.delviryExpenseeresponsiblePhone}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="residenceExpense" className="block text-lg font-medium mb-2">مصاريف الاقامة</label>
                        <input
                            type="number"
                            id="residenceExpense"
                            name="residenceExpense"
                            value={formData.residenceExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="personalExpense" className="block text-lg font-medium mb-2">مصاريف شخصية</label>
                        <input
                            type="number"
                            id="personalExpense"
                            name="personalExpense"
                            value={formData.personalExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="medicalExpense" className="block text-lg font-medium mb-2">مصاريف الدواء</label>
                        <input
                            type="number"
                            id="medicalExpense"
                            name="medicalExpense"
                            value={formData.medicalExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="cigiratesExpense" className="block text-lg font-medium mb-2">مصاريف السجائر</label>
                        <input
                            type="number"
                            id="cigiratesExpense"
                            name="cigiratesExpense"
                            value={formData.cigiratesExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="medicalTestsExpenes" className="block text-lg font-medium mb-2">مصاريف التحليل</label>
                        <input
                            type="number"
                            id="medicalTestsExpenes"
                            name="medicalTestsExpenes"
                            value={formData.medicalTestsExpenes}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="kantennExpense" className="block text-lg font-medium mb-2">مصاريف الكانتين</label>
                        <input
                            type="number"
                            id="kantennExpense"
                            name="kantennExpense"
                            value={formData.kantennExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="xraysExpense" className="block text-lg font-medium mb-2">مصاريف الاشعة</label>
                        <input
                            type="number"
                            id="xraysExpense"
                            name="xraysExpense"
                            value={formData.xraysExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="otherExpenses" className="block text-lg font-medium mb-2">مصاريف اخرى</label>
                        <input
                            type="number"
                            id="otherExpenses"
                            name="otherExpenses"
                            value={formData.otherExpenses}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="therapySessionExpense" className="block text-lg font-medium mb-2">مصاريف الجلسات العلاجية داخل المصحة</label>
                        <input
                            type="number"
                            id="therapySessionExpense"
                            name="therapySessionExpense"
                            value={formData.therapySessionExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="DetoksExpense" className="block text-lg font-medium mb-2">مصاريف الديتوكس</label>
                        <input
                            type="number"
                            id="DetoksExpense"
                            name="DetoksExpense"
                            value={formData.DetoksExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="extramedicineExpense" className="block text-lg font-medium mb-2">مصاريف ادوية خارجية</label>
                        <input
                            type="number"
                            id="extramedicineExpense"
                            name="extramedicineExpense"
                            value={formData.extramedicineExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="extramedicineTestsandxraysExpense" className="block text-lg font-medium mb-2">مصاريف الاشعات والتحاليل الاضافية</label>
                        <input
                            type="number"
                            id="extramedicineTestsandxraysExpense"
                            name="extramedicineTestsandxraysExpense"
                            value={formData.extramedicineTestsandxraysExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="goBackExpense" className="block text-lg font-medium mb-2">مصاريف الذهاب و العودة</label>
                        <input
                            type="number"
                            id="goBackExpense"
                            name="goBackExpense"
                            value={formData.goBackExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="changePlaceExpense" className="block text-lg font-medium mb-2">مصاريف تغير المكان</label>
                        <input
                            type="number"
                            id="changePlaceExpense"
                            name="changePlaceExpense"
                            value={formData.changePlaceExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumExceptions" className="block text-lg font-medium mb-2">الاستثناءات</label>
                        {formData.sanatoriumExceptions.map((exception, index) => (
                            <div key={index} className="mb-4">
                                <input
                                    type="text"
                                    name={`exception${index}`}
                                    value={exception}
                                    onChange={(e) => handleExceptionChange(index, e.target.value)}
                                    className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={handleAddException}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none"
                        >
                            إضافة استثناء
                        </button>
                    </div>
                    <div className="col-span-1 md:col-span-2 lg:col-span-3">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white text-lg font-semibold py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                        >
                            أضافة مصحة
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddSanatorium;
