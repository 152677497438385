import Swal from "sweetalert2";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar';

const Balance = () => {
    const [data, setData] = useState({
        AddictFamilyAmount: 0,
        AddictDonorAmount: 0,
        FamilyBoxAmount: 0,
        GodAmount: 0,
        CathedralAmount: 0,
        OtherAmount: 0,
        AddictFamilyAmountCollected: 0,
        AddictDonorAmountCollected: 0,
        FamilyBoxAmountCollected: 0,
        GodAmountCollected: 0,
        CathedralAmountCollected: 0,
        OtherAmountCollected: 0,
        totalAmountCollected: 0,
    });
    const [otherData, setOtherData] = useState('');
    const { id } = useParams();

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}follow-up/${id}`);
            const {
                AddictFamilyAmount,
                AddictDonorAmount,
                CathedralAmount,
                FamilyBoxAmount,
                GodAmount,
                OtherAmount,
                AddictFamilyAmountCollected,
                AddictDonorAmountCollected,
                FamilyBoxAmountCollected,
                GodAmountCollected,
                CathedralAmountCollected,
                OtherAmountCollected,
                totalAmountCollected,
                addictName
            } = response.data;
            setOtherData(response.data);
            setData({
                AddictFamilyAmount,
                AddictDonorAmount,
                CathedralAmount,
                FamilyBoxAmount,
                GodAmount,
                OtherAmount,
                AddictFamilyAmountCollected,
                AddictDonorAmountCollected,
                FamilyBoxAmountCollected,
                GodAmountCollected,
                CathedralAmountCollected,
                OtherAmountCollected,
                totalAmountCollected,
                addictName
            });
        } catch (error) {
            console.error('Error fetching data', error);
        }
    };

    const handleChange = (e, field) => {
        const updatedValue = parseFloat(e.target.value) || 0;

        const newData = {
            ...data,
            [field]: updatedValue
        };

        const totalCollected =
            newData.AddictFamilyAmountCollected +
            newData.AddictDonorAmountCollected +
            newData.FamilyBoxAmountCollected +
            newData.GodAmountCollected +
            newData.OtherAmountCollected +
            newData.CathedralAmountCollected;

        setData({
            ...newData,
            totalAmountCollected: totalCollected
        });
    };

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        }).then(() => {
            window.location.reload();
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}follow-up/${id}`, data);
            showSuccessAlert('تم حفظ البيانات بنجاح');
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم تحديث متابعة مالية بنجاح`,
            });

            const fields = [
                { field: 'AddictFamilyAmountCollected', name: 'مبلغ الأسرة' },
                { field: 'AddictDonorAmountCollected', name: 'مبلغ المتبرع' },
                { field: 'FamilyBoxAmountCollected', name: 'مبلغ صندوق الخدمة' },
                { field: 'GodAmountCollected', name: 'مبلغ اخوة رب' },
                { field: 'CathedralAmountCollected', name: 'مبلغ الكاتدرائية' },
                { field: 'OtherAmountCollected', name: 'مبالغ أخرى' }
            ];

            for (const { field, name } of fields) {
                const updatedValue = parseFloat(data[field]) || 0;
                const oldValue = parseFloat(otherData[field]) || 0;

                if (updatedValue > oldValue) {
                    const difference = updatedValue - oldValue;

                    const incomeData = {
                        income: difference,
                        incomeType: 'ايراد',
                        details: `الفرق في ${name} - الحالة: ${otherData.AddictForm.addict.addictName || 'غير متوفر'}`
                    };

                    const incomeResponse = await axios.post(`${process.env.REACT_APP_HOST_SERVER}income`, incomeData);
                    const incomeId = incomeResponse.data._id;
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}family-box`, {
                        incomeAmount: incomeId,
                        amountType: 'ايراد',
                        details: incomeData.details,
                    });
                }
            }
        } catch (error) {
            console.error('Error saving data', error);
            showErrorAlert('فشل في حفظ البيانات');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getInputClass = (field) => {
        if (data[field] > data[`${field}Collected`]) return "p-2 border border-red-500 rounded-md bg-red-50";
        if (data[field] < data[`${field}Collected`]) return "p-2 border border-green-500 rounded-md bg-green-50";
        return "p-2 border border-gray-300 rounded-md";
    };

    return (
        <div>
            <Navbar />
            <div className="container mx-auto p-6">
                <h1 className="text-3xl font-bold text-center mb-6">الميزانية</h1>
                <div className="flex justify-between items-center mb-6">
                    <div className="text-lg font-semibold">المبلغ المطلوب: {data.AddictFamilyAmount + data.AddictDonorAmount + data.FamilyBoxAmount + data.GodAmount + data.CathedralAmount}</div>
                    <div className="text-lg font-semibold">المجموع الذي تم جمعه: {data.totalAmountCollected}</div>
                </div>
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                    <div className="flex flex-col">
                        <label className="text-lg mb-2">الأسرة</label>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-500">المبلغ المطلوب: {data.AddictFamilyAmount}</span>
                            <input
                                type="number"
                                value={data.AddictFamilyAmountCollected}
                                onChange={(e) => handleChange(e, 'AddictFamilyAmountCollected')}
                                className={getInputClass('AddictFamilyAmount')}
                            />
                        </div>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-500">
                                المسئول عن الاسرة: {otherData.AddictFamily?.AddictFamilyResponsible || 'غير متوفر'}
                            </span>
                            <span className="text-sm text-gray-500">
                                رقم تليفون المسئول عن الاسرة: {otherData.AddictFamily?.AddictFamilyResponsiblePhone || 'غير متوفر'}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <label className="text-lg mb-2">المتبرع</label>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-500">المبلغ المطلوب: {data.AddictDonorAmount}</span>
                            <input
                                type="number"
                                value={data.AddictDonorAmountCollected}
                                onChange={(e) => handleChange(e, 'AddictDonorAmountCollected')}
                                className={getInputClass('AddictDonorAmount')}
                            />
                        </div>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-500">
                                المتبرع: {otherData.AddictDonor?.AddictDonor || 'غير متوفر'}
                            </span>
                            <span className="text-sm text-gray-500">
                                رقم تليفون المتبرع: {otherData.AddictDonor?.AddictDonorPhone || 'غير متوفر'}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <label className="text-lg mb-2">صندوق الخدمة</label>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-500">المبلغ المطلوب: {data.FamilyBoxAmount}</span>
                            <input
                                type="number"
                                value={data.FamilyBoxAmountCollected}
                                onChange={(e) => handleChange(e, 'FamilyBoxAmountCollected')}
                                className={getInputClass('FamilyBoxAmount')}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <label className="text-lg mb-2"> المبلغ الذي ساهمت به اخوة رب</label>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-500">المبلغ المطلوب: {data.GodAmount}</span>
                            <input
                                type="number"
                                value={data.GodAmountCollected}
                                onChange={(e) => handleChange(e, 'GodAmountCollected')}
                                className={getInputClass('GodAmount')}
                            />
                        </div>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-500">
                                مسئول اخوة رب: {otherData.GodResponsible || 'غير متوفر'}
                            </span>
                            <span className="text-sm text-gray-500">
                                رقم تليفون مسئول اخوة رب: {otherData.GodResponsiblePhone || 'غير متوفر'}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <label className="text-lg mb-2">المبلغ الذي ساهمت به الكاتدرائية</label>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-500">المبلغ المطلوب: {data.CathedralAmount}</span>
                            <input
                                type="number"
                                value={data.CathedralAmountCollected}
                                onChange={(e) => handleChange(e, 'CathedralAmountCollected')}
                                className={getInputClass('CathedralAmount')}
                            />
                        </div>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-500">
                                مسئول الكاتدرائية: {otherData.CathedralResponsible || 'غير متوفر'}
                            </span>
                            <span className="text-sm text-gray-500">
                                رقم تليفون مسئول الكاتدرائية: {otherData.CathedralResponsiblePhone || 'غير متوفر'}
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <label className="text-lg mb-2">المبالغ الاخرى</label>
                        <div className="flex justify-between items-center mb-4">
                            <span className="text-sm text-gray-500">المبلغ المطلوب: {data.OtherAmount}</span>
                            <input
                                type="number"
                                value={data.OtherAmountCollected ? data.OtherAmountCollected : 0}
                                onChange={(e) => handleChange(e, 'OtherAmountCollected')}
                                className={getInputClass('OtherAmount')}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center mt-6">
                    <button
                        onClick={handleSave}
                        className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    >
                        حفظ
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Balance;