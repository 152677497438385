import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import Pagination from '../component/Pagination';

const FamilyExpenseTable = () => {
    const [familyExpenses, setFamilyExpenses] = useState([]);
    const [familyCategories, setFamilyCategories] = useState([]);
    const [editingExpense, setEditingExpense] = useState(null);
    const [editForm, setEditForm] = useState({
        FamilyCategory: '',
        FamilyExpense: '',
        FamilyExpenseDetails: ''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const fetchFamilyExpenses = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}family-expense`);
            setFamilyExpenses(response.data);
        } catch (error) {
            console.error('Error fetching family expenses:', error);
        }
    };

    const fetchFamilyCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}family-category`);
            setFamilyCategories(response.data);
        } catch (error) {
            console.error('Error fetching family categories:', error);
        }
    };

    const deleteExpense = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف المصروف بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}family-expense/${id}`);
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                        Notifaction: `تم حذف المصروف بنجاح`,
                    });
                    Swal.fire('تم الحذف!', 'تم حذف المصروف بنجاح', 'success');
                    fetchFamilyExpenses();
                } catch (err) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء الحذف.', 'error');
                }
            }
        });
    };

    const handleEditClick = (expense) => {
        setEditingExpense(expense._id);
        setEditForm({
            FamilyCategory: expense.FamilyCategory ? expense.FamilyCategory._id : '',
            FamilyExpense: expense.FamilyExpense || '',
            FamilyExpenseDetails: expense.FamilyExpenseDetails || ''
        });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditForm({ ...editForm, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}family-expense/${editingExpense}`, editForm);
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم تحديث المصروف بنجاح`,
            });
            Swal.fire('تم التحديث!', 'تم تحديث البيانات بنجاح.', 'success');
            setEditingExpense(null);
            fetchFamilyExpenses();
        } catch (error) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء التحديث.', 'error');
        }
    };

    const downloadFamilyExpenses = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}family-expense/copies`, null, {
                responseType: 'blob', 
            });
    
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'family_expenses.csv'); 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading CSV file:", error);
            Swal.fire('خطأ!', 'حدث خطأ أثناء تنزيل الملف.', 'error');
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginatedFamilyExpenses = familyExpenses.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(familyExpenses.length / itemsPerPage);

    useEffect(() => {
        fetchFamilyExpenses();
        fetchFamilyCategories();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="container mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">مصروفات الخدمة</h1>
                <div className="flex justify-between mb-4">
                    <Link to={'/addiction/add/expense'}>
                        <button
                            onClick={() => setEditingExpense(null)}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none"
                        >
                            أضافة مصروف جديد
                        </button>
                    </Link>
                    <button
                        onClick={downloadFamilyExpenses}
                        className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-green-700 focus:outline-none"
                    >
                        تحميل نسخة
                    </button>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-3">#</th>
                                <th className="px-4 py-3">اسم الفئة</th>
                                <th className="px-4 py-3">المصاريف</th>
                                <th className="px-4 py-3">تفاصيل المصاريف</th>
                                <th className="px-4 py-3">الإجراءات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedFamilyExpenses.map((expense, index) => (
                                <tr key={expense._id} className="border-b hover:bg-gray-100">
                                    <td className="px-4 py-3 text-center">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td className="px-4 py-3 text-center">{expense.FamilyCategory ? expense.FamilyCategory.FamilyCategory : 'لا يوجد'}</td>
                                    <td className="px-4 py-3 text-center">{expense.FamilyExpense ? expense.FamilyExpense.toLocaleString("en-US") : 'لا يوجد'}</td>
                                    <td className="px-4 py-3 text-center">{expense.FamilyExpenseDetails || "لا يوجد"}</td>
                                    <td className="px-4 py-3 flex justify-center space-x-2">
                                        <button
                                            onClick={() => handleEditClick(expense)}
                                            className="bg-yellow-500 me-3 text-white py-1 px-3 rounded hover:bg-yellow-600 focus:outline-none"
                                        >
                                            تعديل
                                        </button>
                                        <button
                                            onClick={() => deleteExpense(expense._id)}
                                            className="bg-red-500 ms-3 text-white py-1 px-3 rounded hover:bg-red-600 focus:outline-none"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
                {editingExpense && (
                    <div className="mt-8 bg-white p-6 rounded-xl shadow-lg">
                        <h2 className="text-2xl font-bold mb-4">تعديل بيانات المصروف</h2>
                        <form onSubmit={handleEditSubmit}>
                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    <label className="block mb-2 font-medium">اسم الفئة</label>
                                    <select
                                        name="FamilyCategory"
                                        value={editForm.FamilyCategory}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    >
                                        <option value="">اختر الفئة</option>
                                        {familyCategories.map((category) => (
                                            <option key={category._id} value={category._id}>
                                                {category.FamilyCategory}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="block mb-2 font-medium">اسم المصروف</label>
                                    <input
                                        type="text"
                                        name="FamilyExpense"
                                        value={editForm.FamilyExpense}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2 font-medium">تفاصيل المصروف</label>
                                    <textarea
                                        name="FamilyExpenseDetails"
                                        value={editForm.FamilyExpenseDetails}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end mt-4">
                                <button
                                    type="button"
                                    onClick={() => setEditingExpense(null)}
                                    className="bg-gray-400 text-white py-2 px-4 rounded mr-2 hover:bg-gray-500"
                                >
                                    إلغاء
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                >
                                    حفظ التعديلات
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FamilyExpenseTable;