import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import Pagination from '../component/Pagination';

const IncomeTable = () => {
    const [boxIncomes, setBoxIncomes] = useState([]);
    const [editingBoxIncome, setEditingBoxIncome] = useState(null);
    const [editForm, setEditForm] = useState({
        income: '',
        incomeType: '',
        details: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const fetchBoxIncomes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}income`);
            setBoxIncomes(response.data);
        } catch (error) {
            console.error('Error fetching box incomes:', error);
        }
    };

    const deleteBoxIncome = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف هذا الايراد بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}income/${id}`);
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                        Notifaction: `تم حذف الايراد بنجاح`,
                    });
                    Swal.fire('تم الحذف!', 'تم حذف الايراد بنجاح.', 'success');
                    fetchBoxIncomes();
                } catch (err) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء الحذف.', 'error');
                }
            }
        });
    };

    const handleEditClick = (boxIncome) => {
        setEditingBoxIncome(boxIncome._id);
        setEditForm({
            income: boxIncome.income,
            incomeType: boxIncome.incomeType,
            details: boxIncome.details,
        });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditForm({ ...editForm, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}income/${editingBoxIncome}`, editForm);
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم تحديث الايراد بنجاح`,
            });
            Swal.fire('تم التحديث!', 'تم تحديث البيانات بنجاح.', 'success');
            setEditingBoxIncome(null);
            fetchBoxIncomes();
        } catch (error) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء التحديث.', 'error');
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginatedBoxIncomes = boxIncomes.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(boxIncomes.length / itemsPerPage);

    useEffect(() => {
        fetchBoxIncomes();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="container mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">جدول الايرادات</h1>
                <div className="flex justify-end mb-4">
                    <Link to={'/addiction/add/income'}>
                        <button
                            onClick={() => setEditingBoxIncome(null)}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none"
                        >
                            أضف ايراد جديد
                        </button>
                    </Link>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-3">المبلغ</th>
                                <th className="px-4 py-3">نوع الايراد</th>
                                <th className="px-4 py-3">التفاصيل</th>
                                <th className="px-4 py-3">الإجراءات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedBoxIncomes.map((boxIncome) => (
                                <tr key={boxIncome._id} className="border-b hover:bg-gray-100">
                                    <td className="px-4 py-3 text-center">{boxIncome.income}</td>
                                    <td className="px-4 py-3 text-center">{boxIncome.incomeType}</td>
                                    <td className="px-4 py-3 text-center">{boxIncome.details}</td>
                                    <td className="px-4 py-3 flex justify-center space-x-2">
                                        <button
                                            onClick={() => handleEditClick(boxIncome)}
                                            className="bg-yellow-500 me-3 text-white py-1 px-3 rounded hover:bg-yellow-600 focus:outline-none"
                                        >
                                            تعديل
                                        </button>
                                        <button
                                            onClick={() => deleteBoxIncome(boxIncome._id)}
                                            className="bg-red-500 ms-3 text-white py-1 px-3 rounded hover:bg-red-600 focus:outline-none"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {editingBoxIncome && (
                    <div className="mt-8 bg-white p-6 rounded-xl shadow-lg">
                        <h2 className="text-2xl font-bold mb-4">تعديل بيانات الايراد</h2>
                        <form onSubmit={handleEditSubmit}>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block mb-2 font-medium">الايراد</label>
                                    <input
                                        type="number"
                                        name="income"
                                        value={editForm.income}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2 font-medium">نوع الايراد</label>
                                    <input
                                        type="text"
                                        name="incomeType"
                                        value={editForm.incomeType}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div className="col-span-2">
                                    <label className="block mb-2 font-medium">التفاصيل</label>
                                    <textarea
                                        name="details"
                                        value={editForm.details}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end mt-4">
                                <button
                                    type="button"
                                    onClick={() => setEditingBoxIncome(null)}
                                    className="bg-gray-400 text-white py-2 px-4 rounded mr-2 hover:bg-gray-500"
                                >
                                    إلغاء
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                >
                                    حفظ التعديلات
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    );
};

export default IncomeTable;