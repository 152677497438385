import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import Pagination from '../component/Pagination';

const ReceiptTable = () => {
    const [receipts, setReceipts] = useState([]);
    const [editingReceipt, setEditingReceipt] = useState(null);
    const [addicts, setAddicts] = useState([]);
    const [editForm, setEditForm] = useState({
        Addict: '',
        Responsible: '',
        ResponsiblePhone: '',
        Amount: '',
        details: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const fetchAddicts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict`);
            setAddicts(response.data);
        } catch (error) {
            console.error("Error fetching addicts", error);
        }
    };

    const fetchReceipts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}receipt`);
            setReceipts(response.data.data);
        } catch (error) {
            console.error('Error fetching receipts:', error);
        }
    };

    const deleteReceipt = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف هذا الإيصال بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}receipt/${id}`);
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                        Notifaction: `تم حذف الإيصال بنجاح`,
                    });
                    Swal.fire('تم الحذف!', 'تم حذف الإيصال بنجاح.', 'success');
                    fetchReceipts();
                } catch (err) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء الحذف.', 'error');
                }
            }
        });
    };

    const handleEditClick = (receipt) => {
        setEditingReceipt(receipt._id);
        setEditForm({
            Addict: receipt.Addict._id,
            Responsible: receipt.Responsible,
            ResponsiblePhone: receipt.ResponsiblePhone,
            Amount: receipt.Amount,
            details: receipt.details,
        });
    };

    const handleEditChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "ReceiptFile") {
            setEditForm({ ...editForm, [name]: files[0] });
        } else {
            setEditForm({ ...editForm, [name]: value });
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("Addict", editForm.Addict);
        data.append("Responsible", editForm.Responsible);
        data.append("ResponsiblePhone", editForm.ResponsiblePhone);
        data.append("Amount", editForm.Amount);
        data.append("details", editForm.details);
        if (editForm.ReceiptFile) {
            data.append("ReceiptFile", editForm.ReceiptFile);
        }

        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}receipt/${editingReceipt}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم تحديث الإيصال بنجاح`,
            });
            Swal.fire('تم التحديث!', 'تم تحديث البيانات بنجاح.', 'success');
            setEditingReceipt(null);
            fetchReceipts();
        } catch (error) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء التحديث.', 'error');
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginatedReceipts = Array.isArray(receipts) ? receipts.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) : [];

    const totalPages = Math.ceil(Array.isArray(receipts) ? receipts.length / itemsPerPage : 0);

    useEffect(() => {
        fetchAddicts();
        fetchReceipts();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="container mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">جدول الإيصالات</h1>
                <div className="flex justify-end mb-4">
                    <Link to={'/addiction/add/receipt'}>
                        <button
                            onClick={() => setEditingReceipt(null)}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none"
                        >
                            أضف إيصال جديد
                        </button>
                    </Link>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-3">الحالة</th>
                                <th className="px-4 py-3">المسؤول</th>
                                <th className="px-4 py-3">رقم تليفون المسؤول</th>
                                <th className="px-4 py-3">المبلغ</th>
                                <th className="px-4 py-3">التفاصيل</th>
                                <th className="px-4 py-3">الإجراءات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedReceipts.map((receipt) => (
                                <tr key={receipt._id} className="border-b hover:bg-gray-100">
                                    <td className="px-4 py-3 text-center">{receipt.Addict.addictName || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{receipt.Responsible || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{receipt.ResponsiblePhone || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{receipt.Amount || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{receipt.details || "لا يوجد"}</td>
                                    <td className="px-4 py-3 flex justify-center space-x-2">
                                        <button
                                            onClick={() => handleEditClick(receipt)}
                                            className="bg-yellow-500 me-3 text-white py-1 px-3 rounded hover:bg-yellow-600 focus:outline-none"
                                        >
                                            تعديل
                                        </button>
                                        <button
                                            onClick={() => deleteReceipt(receipt._id)}
                                            className="bg-red-500 ms-3 text-white py-1 px-3 rounded hover:bg-red-600 focus:outline-none"
                                        >
                                            حذف
                                        </button>
                                        <a
                                            href={`${process.env.REACT_APP_HOST_SERVER}files/${receipt.ReceiptFile}`}
                                            rel="noopener noreferrer"
                                            className="bg-green-500 ms-3 text-white py-1 px-3 rounded hover:bg-green-600 focus:outline-none"
                                        >
                                            عرض PDF
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {editingReceipt && (
                    <div className="mt-8 bg-white p-6 rounded-xl shadow-lg">
                        <h2 className="text-2xl font-bold mb-4">تعديل بيانات الإيصال</h2>
                        <form onSubmit={handleEditSubmit}>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block mb-2 font-medium">الحالة</label>
                                    <select className="w-full p-2 border rounded"
                                        name="Addict"
                                        value={editForm.Addict}
                                        onChange={handleEditChange}>
                                        <option selected value="">اختر الحالة</option>
                                        {addicts.map((addict) => (
                                            <option key={addict._id} value={addict._id}>
                                                {addict.addictName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="block mb-2 font-medium">المسؤول</label>
                                    <input
                                        type="text"
                                        name="Responsible"
                                        value={editForm.Responsible}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2 font-medium">رقم تليفون المسؤول</label>
                                    <input
                                        type="text"
                                        name="ResponsiblePhone"
                                        value={editForm.ResponsiblePhone}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2 font-medium">المبلغ</label>
                                    <input
                                        type="number"
                                        name="Amount"
                                        value={editForm.Amount}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div className="col-span-2">
                                    <label className="block mb-2 font-medium">التفاصيل</label>
                                    <textarea
                                        name="details"
                                        value={editForm.details}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div className="col-span-2">
                                    <label className="block mb-2 font-medium">ملف الإيصال</label>
                                    <input
                                        type="file"
                                        name="ReceiptFile"
                                        onChange={handleEditChange}
                                        accept=".pdf"
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end mt-4">
                                <button
                                    type="button"
                                    onClick={() => setEditingReceipt(null)}
                                    className="bg-gray-400 text-white py-2 px-4 rounded mr-2 hover:bg-gray-500"
                                >
                                    إلغاء
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                >
                                    حفظ التعديلات
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    );
};

export default ReceiptTable;