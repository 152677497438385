import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddWedding = () => {
    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [church, setChurch] = useState("");
    const [minutes, setMinutes] = useState("");
    const [addMinutes, setAddMinutes] = useState(false);
    const [coupon, setCoupon] = useState("");
    const [addCoupon, setAddCoupon] = useState(false);
    const [father, setFather] = useState("");
    const [assistant, setAssistant] = useState("");
    const [fatherPlessing, setFatherPlessing] = useState("");
    const [assistantPlessing, setAssistantPlessing] = useState("");
    const [fatherPlessingMinutes, setFatherPlessingMinutes] = useState("");
    const [fatherPlessingCoupon, setFatherPlessingCoupon] = useState("");
    const [assistantPlessingMinutes, setAssistantPlessingMinutes] = useState("");
    const [assistantPlessingCoupon, setAssistantPlessingCoupon] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchWeddingConstants = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}wedding-constants/`);
            if (res.data.length > 0) {
                const constants = res.data[0];
                setMinutes(constants.minutes);
                setCoupon(constants.coupon);
                setFatherPlessingMinutes(constants.fatherPlessingMinutes);
                setAssistantPlessingMinutes(constants.assistantPlessingMinutes);
                setFatherPlessingCoupon(constants.fatherPlessingCoupon);
                setAssistantPlessingCoupon(constants.assistantPlessingCoupon);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== "خزنة") {
                navigate("/login");
            }
        } catch {
            navigate("/login");
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const payload = {
                name,
                date,
                church,
                minutes: addMinutes ? minutes : 0,
                coupon: addCoupon ? coupon : 0,
                father,
                assistant,
                fatherPlessing,
                assistantPlessing,
            };

            await axios.post(`${process.env.REACT_APP_HOST_SERVER}wedding`, payload);
            Swal.fire({
                title: "نجاح!",
                text: "تمت إضافة الفرح بنجاح",
                icon: "success",
                confirmButtonText: "حسناً",
            });
            setName("");
            setDate("");
            setChurch("");
            setAddMinutes(false);
            setAddCoupon(false);
            setFather("");
            setAssistant("");
            setFatherPlessing("");
            setAssistantPlessing("");
        } catch (err) {
            Swal.fire({
                title: "خطأ!",
                text: err.response?.data?.message || "حدث خطأ ما",
                icon: "error",
                confirmButtonText: "حاول مرة أخرى",
            });
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    const calculatePlessings = () => {
        const fatherTotal =
            (addMinutes ? parseFloat(fatherPlessingMinutes) || 0 : 0) +
            (addCoupon ? parseFloat(fatherPlessingCoupon) || 0 : 0);
        const assistantTotal =
            (addMinutes ? parseFloat(assistantPlessingMinutes) || 0 : 0) +
            (addCoupon ? parseFloat(assistantPlessingCoupon) || 0 : 0);

        setFatherPlessing(fatherTotal);
        setAssistantPlessing(assistantTotal);
    };

    useEffect(() => {
        calculatePlessings();
    }, [addMinutes, addCoupon]);

    useEffect(() => {
        verifyAdmin();
        fetchWeddingConstants();
    }, []);

    return (
        <div>
            <Navbar />
            <div className="container m-auto">
                <div className="form space-y-3">
                    <h1 className="text-center text-3xl">أضافة فرح</h1>
                    <div className="form-group">
                        <label className="text-xl font-medium">الأسم</label>
                        <input
                            type="text"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium">التاريخ</label>
                        <input
                            type="date"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setDate(e.target.value)}
                            value={date}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium">الكنيسة</label>
                        <input
                            type="text"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setChurch(e.target.value)}
                            value={church}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium me-4">المحضر</label>
                        <input type="checkbox" onChange={(e) => setAddMinutes(e.target.checked)} />
                        <label className="text-xl font-medium ms-4">علم للاضافة</label>
                        <input
                            type="number"
                            readOnly
                            value={addMinutes ? minutes : ""}
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium me-4">القسيمة</label>
                        <input type="checkbox" onChange={(e) => setAddCoupon(e.target.checked)} />
                        <label className="text-xl font-medium ms-4">علم للاضافة</label>
                        <input
                            type="number"
                            readOnly
                            value={addCoupon ? coupon : ""}
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium">الكاهن</label>
                        <input
                            type="text"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setFather(e.target.value)}
                            value={father}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium">المعلم</label>
                        <input
                            type="text"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setAssistant(e.target.value)}
                            value={assistant}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium">بركة الكاهن</label>
                        <input
                            type="number"
                            readOnly
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            value={fatherPlessing}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium">بركة المعلم</label>
                        <input
                            type="number"
                            readOnly
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            value={assistantPlessing}
                        />
                    </div>
                    <div className="text-center py-3">
                        <button
                            loading={loading}
                            onClick={handleSubmit}
                            className="bg-lime-500 text-white text-xl px-6 py-2 rounded-lg hover:bg-lime-300 duration-150">أضافة</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddWedding;
