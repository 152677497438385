import Navbar from "../../components/Navbar";
import workers from '../../imgs/workers.webp'
import category from '../../imgs/category.webp'
import product from '../../imgs/product.webp'
import shop from '../../imgs/shop.webp'
import worker from '../../imgs/worker.webp'
import expense from '../../imgs/expenses.webp'
import income from '../../imgs/incomes.jpg'
import penalety from '../../imgs/penalety.webp'
import penaletyHistory from '../../imgs/penalety history.webp'
import contract from '../../imgs/contract.webp'
import contractHistory from '../../imgs/contract history.webp'
import salary from '../../imgs/salary.avif'
import salaryHistory from '../../imgs/salaryHistory.avif'
import adddVacation from '../../imgs/addd-vacation.avif'
import vacationHistory from '../../imgs/vacation-history.avif'
import permission from '../../imgs/permission.jpg'
import mission from '../../imgs/mission.jpg'
import permission_history from '../../imgs/permission_history.jpg'
import mission_history from '../../imgs/mission_history.jpg'
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
const AdminAddPage = () => {
    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, [])
    return (
        <div className="add-page w-[90%] m-auto">
            <Navbar />
            <div className="heading mt-6 mb-3 text-center">
                <h1 className="font-bold text-3xl">أهلا ادمن المالية في</h1>
                <h1 className="font-bold text-3xl mt-3">صفحة الأضافات و البحوثات</h1>
            </div>

            <div className="add-body flex flex-wrap gap-4 justify-center">
                <div className="w-full text-start">
                    <h1 className="text-2xl underline underline-offset-[10px]">بيانات العمال و الاباء</h1>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={workers} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/worker'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">أضافة عمال و اباء</button>
                        </Link>
                    </div>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={worker} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/show/worker'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> عرض العمال و الاباء</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="add-body mt-5 flex flex-wrap gap-4 justify-center">
                <div className="w-full text-start">
                    <h1 className="text-2xl underline underline-offset-[10px]">المصروفات و الايرادات</h1>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={expense} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/expenses'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">أضافة مصروفات</button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={income} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/incomes'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">أضافة ايرادات</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="add-body mt-5 flex flex-wrap gap-4 justify-center">
                <div className="w-full text-start">
                    <h1 className="text-2xl underline underline-offset-[10px]">العقود</h1>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={contract} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/contract'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">أضافة عقد</button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={contractHistory} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/servedcontract/history'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">تاريخ العقود </button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="add-body mt-5 flex flex-wrap gap-4 justify-center">
                <div className="w-full text-start">
                    <h1 className="text-2xl underline underline-offset-[10px]">المرتبات و تفاصيلها</h1>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/workersalary'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">أضافة المرتبات </button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={salary} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/workersalary'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">المرتبات </button>
                        </Link>
                    </div>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={salaryHistory} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/servedsalary'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">المرتبات المصروفة</button>
                        </Link>
                    </div>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/non-worker-salary'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">المرتبات لمن تم اخلاء طرفهم</button>
                        </Link>
                    </div>
                </div>
            </div>
            
            <div className="add-body mt-5 flex flex-wrap gap-4 justify-center">
                <div className="w-full text-start">
                    <h1 className="text-2xl underline underline-offset-[10px]">الأجازات</h1>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={adddVacation} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/vacation'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> أضافة الأجازات للعمال</button>
                        </Link>
                    </div>
                </div>
                
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={vacationHistory} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/vacation/history'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> تاريخ الأجازات </button>
                        </Link>
                    </div>
                </div>

            </div>

            <div className="add-body mt-5 flex flex-wrap gap-4 justify-center">
                <div className="w-full text-start">
                    <h1 className="text-2xl underline underline-offset-[10px]">المأموريات والاذونات</h1>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={mission} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/mission'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> أضافة مأمورية</button>
                        </Link>
                    </div>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={permission} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/permission'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> أضافة أذن</button>
                        </Link>
                    </div>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={mission_history} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/mission/history'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> تاريخ المأموريات</button>
                        </Link>
                    </div>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={permission_history} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/permission/history'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> تاريخ الأذونات</button>
                        </Link>
                    </div>
                </div>

            </div>

            <div className="add-body mt-5 flex flex-wrap gap-4 justify-center">
                <div className="w-full text-start">
                    <h1 className="text-2xl underline underline-offset-[10px]">البركات و تفاصيلها</h1>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/occasion'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">أضافة عيد او مناسبة </button>
                        </Link>
                    </div>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/updateoccasion'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">تعديل المناسبة  او العيد</button>
                        </Link>
                    </div>
                </div>
                
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/blessing'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">اضافة البركات</button>
                        </Link>
                    </div>
                </div>


                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/blessing/all'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">البركة للجميع</button>
                        </Link>
                    </div>
                </div>

                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/blessing/history'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">البركات المصروفة</button>
                        </Link>
                    </div>
                </div>

            </div>

            <div className="add-body mt-5 flex flex-wrap gap-4 justify-center">
                <div className="w-full text-start">
                    <h1 className="text-2xl underline underline-offset-[10px]">الخصومات</h1>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/advance'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> اضافة سلفة </button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/advance/history'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">تاريخ السلف </button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={penalety} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/penalety'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> اضافة جزاء </button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={penaletyHistory} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/penalety/history'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">تاريخ الجزائات </button>
                        </Link>
                    </div>
                </div>
            </div>
            
            <div className="add-body mt-5 flex flex-wrap gap-4 justify-center">
                <div className="w-full text-start">
                    <h1 className="text-2xl underline underline-offset-[10px]">الافراح</h1>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/wedding'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> اضافة فرح </button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/wedding/constants'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">تعديل الثوابت </button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/wedding/history'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"> سجل الافراح</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="add-body mt-5 flex flex-wrap gap-4 justify-center">
                <div className="w-full text-start">
                    <h1 className="text-2xl underline underline-offset-[10px]">الاماكن و المنتجات</h1>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={category} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/category'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">أضافة فئة</button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={product} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/product'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">أضافة صنف</button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/add/place'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">أضافة مكان</button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/updateproduct'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">تعديل المنتجات</button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/updatecategory'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">تعديل الفئات</button>
                        </Link>
                    </div>
                </div>
                <div className="add-div w-[340px] py-4 shadow-lg">
                    <div className="img">
                        <img src={shop} alt="" className="w-full h-[180px]" />
                    </div>
                    <div className="button text-center mt-5">
                        <Link to={'/admin/updateplace'}>
                            <button className="bg-[#000000] text-white py-3 px-8 rounded w-fit text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200">تعديل الاماكن</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AdminAddPage;