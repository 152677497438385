import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import Pagination from '../component/Pagination';

const AddictDonorTable = () => {
    const [addictDonors, setAddictDonors] = useState([]);
    const [editingAddictDonor, setEditingAddictDonor] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const filteredAddictDonors = addictDonors.filter((donor) =>
        donor.AddictDonor.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedAddictDonors = filteredAddictDonors.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(filteredAddictDonors.length / itemsPerPage);


    const [editForm, setEditForm] = useState({
        AddictDonor: '',
        AddictDonorPhone: '',
    });

    const fetchAddictDonors = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict-donor`);
            setAddictDonors(response.data);
        } catch (error) {
            console.error('Error fetching addict donors:', error);
        }
    };

    const deleteAddictDonor = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف هذه التبرعات بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}addict-donor/${id}`);
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                        Notifaction: `تم حذف متبرع الحالة بنجاح`,
                    });
                    Swal.fire('تم الحذف!', 'تم حذف التبرع بنجاح.', 'success');
                    fetchAddictDonors();
                } catch (err) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء الحذف.', 'error');
                }
            }
        });
    };

    const handleEditClick = (addictDonor) => {
        setEditingAddictDonor(addictDonor._id);
        setEditForm({
            AddictDonor: addictDonor.AddictDonor,
            AddictDonorPhone: addictDonor.AddictDonorPhone,
        });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditForm({ ...editForm, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}addict-donor/${editingAddictDonor}`, editForm);
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم تحديث متبرع الحالة بنجاح`,
            });
            Swal.fire('تم التحديث!', 'تم تحديث البيانات بنجاح.', 'success');
            setEditingAddictDonor(null);
            fetchAddictDonors();
        } catch (error) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء التحديث.', 'error');
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };



    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm]);

    useEffect(() => {
        fetchAddictDonors();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="container mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">قائمة المتبرعين للحالات</h1>
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="ابحث عن المتبرع"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="w-full p-2 border rounded"
                    />
                </div>

                <div className="my-2">
                    <button
                        onClick={handlePrint}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none"
                    >
                        طباعة
                    </button>
                </div>
                <div className="flex justify-end mb-4">
                    <Link to={'/addiction/add/addictDonor'}>
                        <button
                            onClick={() => setEditingAddictDonor(null)}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none"
                        >
                            أضف متبرع جديد
                        </button>
                    </Link>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-3">إسم المتبرع</th>
                                <th className="px-4 py-3">رقم الهاتف</th>
                                <th className="px-4 py-3">الإجراءات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedAddictDonors.map((addictDonor) => (
                                <tr key={addictDonor._id} className="border-b hover:bg-gray-100">
                                    <td className="px-4 py-3 text-center">{addictDonor.AddictDonor}</td>
                                    <td className="px-4 py-3 text-center">{addictDonor.AddictDonorPhone}</td>
                                    <td className="px-4 py-3 flex justify-center space-x-2">
                                        <button
                                            onClick={() => handleEditClick(addictDonor)}
                                            className="bg-yellow-500 me-3 text-white py-1 px-3 rounded hover:bg-yellow-600 focus:outline-none"
                                        >
                                            تعديل
                                        </button>
                                        <button
                                            onClick={() => deleteAddictDonor(addictDonor._id)}
                                            className="bg-red-500 ms-3 text-white py-1 px-3 rounded hover:bg-red-600 focus:outline-none"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {editingAddictDonor && (
                    <div className="mt-8 bg-white p-6 rounded-xl shadow-lg">
                        <h2 className="text-2xl font-bold mb-4">تعديل بيانات المتبرع</h2>
                        <form onSubmit={handleEditSubmit}>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block mb-2 font-medium">إسم المتبرع</label>
                                    <input
                                        type="text"
                                        name="AddictDonor"
                                        value={editForm.AddictDonor}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2 font-medium">رقم الهاتف</label>
                                    <input
                                        type="text"
                                        name="AddictDonorPhone"
                                        value={editForm.AddictDonorPhone}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end mt-4">
                                <button
                                    type="button"
                                    onClick={() => setEditingAddictDonor(null)}
                                    className="bg-gray-400 text-white py-2 px-4 rounded mr-2 hover:bg-gray-500"
                                >
                                    إلغاء
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                >
                                    حفظ التعديلات
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default AddictDonorTable;
