import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const VacationHistory = () => {
    const [vacations, setVacations] = useState([]);
    const [filteredVacations, setFilteredVacations] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [nameFilter, setNameFilter] = useState("");
    const [typeFilter, setTypeFilter] = useState("");
    const [startDateFilter, setStartDateFilter] = useState("");
    const [endDateFilter, setEndDateFilter] = useState("");

    const fetchVacations = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}vacation/`);
            setVacations(response.data);
            setFilteredVacations(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const navigate = useNavigate();
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== "خزنة") {
                navigate("/login");
            }
        } catch (err) {
            console.error(err);
            navigate("/login");
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    useEffect(() => {
        fetchVacations();
    }, []);

    const handleSearch = () => {
        let filtered = vacations;

        if (nameFilter) {
            filtered = filtered.filter(vacation =>
                vacation.name?.name?.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }

        if (typeFilter) {
            filtered = filtered.filter(vacation =>
                vacation.vacationType.toLowerCase().includes(typeFilter.toLowerCase())
            );
        }

        if (startDateFilter && endDateFilter) {
            filtered = filtered.filter(vacation => {
                const vacationFrom = new Date(vacation.vacationFrom);
                const vacationTo = new Date(vacation.vacationTo);
                return (
                    vacationFrom >= new Date(startDateFilter) && vacationTo <= new Date(endDateFilter)
                );
            });
        }

        setFilteredVacations(filtered);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginatedData = filteredVacations.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredVacations.length / itemsPerPage);

    const handleDelete = async (id, vacationFrom, vacationTo, vacationType) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}vacation/${id}`, {
                        data: {
                            vacationFrom,
                            vacationTo,
                            vacationType
                        }
                    });
                    fetchVacations();
                    Swal.fire('تم الحذف!', 'تم حذف الإجازة.', 'success');
                } catch (error) {
                    console.error(error);
                    Swal.fire('خطأ!', 'حدث خطأ أثناء حذف الإجازة.', 'error');
                }
            }
        });
    };


    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8 text-center">سجل الإجازات</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                <input
                    type="text"
                    placeholder="البحث بالاسم"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                />
                <input
                    type="text"
                    placeholder="نوع الإجازة"
                    value={typeFilter}
                    onChange={(e) => setTypeFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                />
                <input
                    type="date"
                    placeholder="تاريخ البداية"
                    value={startDateFilter}
                    onChange={(e) => setStartDateFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                />
                <input
                    type="date"
                    placeholder="تاريخ النهاية"
                    value={endDateFilter}
                    onChange={(e) => setEndDateFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                />
            </div>

            <button
                onClick={handleSearch}
                className="mb-6 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg shadow-lg hover:bg-indigo-700"
            >
                بحث
            </button>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-lg rounded-lg">
                    <thead className="bg-indigo-700 text-white">
                        <tr>
                            <th className="py-4 px-6 text-right">الاسم</th>
                            <th className="py-4 px-6 text-right">الادارة</th>
                            <th className="py-4 px-6 text-right">الوظيفة</th>
                            <th className="py-4 px-6 text-right">نوع الإجازة</th>
                            <th className="py-4 px-6 text-right">من تاريخ</th>
                            <th className="py-4 px-6 text-right">إلى تاريخ</th>
                            <th className="py-4 px-6 text-center">إجراءات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((vacation, index) => (
                                <tr key={index} className="border-b even:bg-gray-100">
                                    <td className="py-4 px-6 text-right">{vacation.name?.name || "N/A"}</td>
                                    <td className="py-4 px-6 text-right">{vacation.name?.departement || "N/A"}</td>
                                    <td className="py-4 px-6 text-right">{vacation.name?.job || "N/A"}</td>
                                    <td className="py-4 px-6 text-right">{vacation.vacationType}</td>
                                    <td className="py-4 px-6 text-right">{new Date(vacation.vacationFrom).toLocaleDateString("en-uk")}</td>
                                    <td className="py-4 px-6 text-right">{new Date(vacation.vacationTo).toLocaleDateString("en-uk")}</td>
                                    <td className="py-4 px-6 text-center">
                                        <button
                                            onClick={() => handleDelete(vacation._id, vacation.vacationFrom, vacation.vacationTo, vacation.vacationType)}
                                            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                                        >
                                            حذف
                                        </button>

                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="py-4 text-center">لم يتم العثور على سجلات</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="mt-6 flex justify-center items-center space-x-4">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 mx-4 rounded-lg ${currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-indigo-600 text-white hover:bg-indigo-500"}`}
                >
                    السابق
                </button>
                <div className="flex items-center space-x-2">
                    <input
                        type="number"
                        min="1"
                        max={totalPages}
                        value={currentPage}
                        onChange={(e) => handlePageChange(Number(e.target.value))}
                        className="w-14 text-center border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <span className="text-sm font-semibold text-gray-700">/ {totalPages}</span>
                </div>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`px-4 py-2 rounded-lg ${currentPage === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-indigo-600 text-white hover:bg-indigo-500"}`}
                >
                    التالي
                </button>
            </div>
        </div>
    );
};

export default VacationHistory;
