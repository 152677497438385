import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import Pagination from '../component/Pagination';

const FamilyBoxTable = () => {
    const [familyBoxData, setFamilyBoxData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchParams, setSearchParams] = useState({
        startDate: '',
        endDate: '',
        details: '',
    });
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalExpense, setTotalExpense] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const fetchFamilyBoxData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}family-box`);
            setFamilyBoxData(response.data);
            setFilteredData(response.data);
            calculateTotals(response.data);
        } catch (error) {
            console.error('Error fetching family box data:', error);
        }
    };

    const calculateTotals = (data) => {
        let income = 0;
        let expense = 0;
        data.forEach(item => {
            if (item.amountType === 'ايراد') {
                income += item.incomeAmount ? parseInt(item.incomeAmount.income, 10) || 0 : 0;
            } else {
                expense += item.formExpenseAmount ? parseInt(item.formExpenseAmount.AddictFormExpense, 10) || 0 : 0;
                expense += item.familyExpenseAmount ? parseInt(item.familyExpenseAmount.FamilyExpense, 10) || 0 : 0;
            }
        });
        setTotalIncome(income);
        setTotalExpense(expense);
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        const updatedSearchParams = { ...searchParams, [name]: value };
        setSearchParams(updatedSearchParams);
    
        const filtered = familyBoxData.filter(item => {
            const createdAtDate = new Date(item.createdAt);
            const matchesStartDate = updatedSearchParams.startDate
                ? createdAtDate >= new Date(updatedSearchParams.startDate)
                : true;
            const matchesEndDate = updatedSearchParams.endDate
                ? createdAtDate <= new Date(updatedSearchParams.endDate)
                : true;
            const matchesDetails = updatedSearchParams.details
                ? item.details.includes(updatedSearchParams.details)
                : true;
            return matchesStartDate && matchesEndDate && matchesDetails;
        });
    
        setFilteredData(filtered);
        calculateTotals(filtered);
    };
    
    const deleteExpense = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف هذا البند بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}family-box/${id}`);
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                        Notifaction: `تم حذف البند بنجاح`,
                    });
                    Swal.fire('تم الحذف!', 'تم حذف البند بنجاح', 'success');
                    fetchFamilyBoxData();
                } catch (err) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء الحذف.', 'error');
                }
            }
        });
    };

    useEffect(() => {
        fetchFamilyBoxData();
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    return (
        <div className="px-8 py-5 bg-gray-50 min-h-screen">
            <Navbar />
            <div className="w-[90%] mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">جدول الايرادات والمصروفات</h1>
                <form className="mb-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div>
                            <label className="block text-lg font-medium mb-2">من تاريخ</label>
                            <input
                                type="date"
                                name="startDate"
                                value={searchParams.startDate}
                                onChange={handleSearchChange}
                                className="w-full border rounded-lg px-4 py-3"
                            />
                        </div>
                        <div>
                            <label className="block text-lg font-medium mb-2">إلى تاريخ</label>
                            <input
                                type="date"
                                name="endDate"
                                value={searchParams.endDate}
                                onChange={handleSearchChange}
                                className="w-full border rounded-lg px-4 py-3"
                            />
                        </div>
                        <div>
                            <label className="block text-lg font-medium mb-2">التفاصيل</label>
                            <input
                                type="text"
                                name="details"
                                value={searchParams.details}
                                onChange={handleSearchChange}
                                placeholder="بحث بالتفاصيل"
                                className="w-full border rounded-lg px-4 py-3"
                            />
                        </div>
                    </div>
                </form>
                <div className="text-center mb-8">
                    <h2 className="text-2xl font-semibold">
                        قيمة الصندوق: <span className="font-bold">{totalIncome - totalExpense}</span>
                    </h2>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div className="col-span-1">
                        <h2 className="text-2xl font-bold mb-4 text-green-700">الايرادات</h2>
                        <div className="overflow-x-auto">
                            <table className="w-full bg-white rounded-xl shadow-lg">
                                <thead className="bg-green-600 text-white">
                                    <tr>
                                        <th className="px-4 py-3">المبلغ</th>
                                        <th className="px-4 py-3">نوع الايراد</th>
                                        <th className="px-4 py-3">التفاصيل</th>
                                        <th className="px-4 py-3">التاريخ</th>
                                        <th className="px-4 py-3">الإجراءات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.filter(item => item.incomeAmount).map((item) => (
                                        <tr key={item._id} className="border-b hover:bg-gray-100 odd:bg-gray-50">
                                            <td className="px-4 py-3 text-center">{item.incomeAmount?.income || 0}</td>
                                            <td className="px-4 py-3 text-center">{item.amountType}</td>
                                            <td className="px-4 py-3 text-center">{item.details}</td>
                                            <td className="px-4 py-3 text-center">{new Date(item.createdAt).toLocaleDateString()}</td>
                                            <td className="px-4 py-3 text-center">
                                                <button
                                                    onClick={() => deleteExpense(item._id)}
                                                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                                                >
                                                    حذف
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <h3 className="text-xl text-green-700 text-center mt-4">إجمالي الايرادات: {totalIncome}</h3>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <h2 className="text-2xl font-bold mb-4 text-red-700">المصروفات</h2>
                        <div className="overflow-x-auto">
                            <table className="w-full bg-white rounded-xl shadow-lg">
                                <thead className="bg-red-600 text-white">
                                    <tr>
                                        <th className="px-4 py-3">المبلغ</th>
                                        <th className="px-4 py-3">نوع المصروف</th>
                                        <th className="px-4 py-3">التفاصيل</th>
                                        <th className="px-4 py-3">التاريخ</th>
                                        <th className="px-4 py-3">الإجراءات</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.filter(item => item.familyExpenseAmount || item.formExpenseAmount).map((item) => (
                                        <tr key={item._id} className="border-b hover:bg-gray-100 odd:bg-gray-50">
                                            <td className="px-4 py-3 text-center">
                                                {item.formExpenseAmount?.AddictFormExpense || ''}
                                                {item.familyExpenseAmount?.FamilyExpense || ''}
                                            </td>
                                            <td className="px-4 py-3 text-center">{item.amountType}</td>
                                            <td className="px-4 py-3 text-center">{item.details}</td>
                                            <td className="px-4 py-3 text-center">{new Date(item.createdAt).toLocaleDateString()}</td>
                                            <td className="px-4 py-3 text-center">
                                                <button
                                                    onClick={() => deleteExpense(item._id)}
                                                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                                                >
                                                    حذف
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <h3 className="text-xl text-red-700 text-center mt-4">إجمالي المصروفات: {totalExpense}</h3>
                        </div>
                    </div>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>

    );
};

export default FamilyBoxTable;
