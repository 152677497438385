import Swal from "sweetalert2";
import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../../components/Navbar';

const AddAddictDonor = () => {
    const [formData, setFormData] = useState({
        AddictDonor: '',
        AddictDonorPhone: '',
    });

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}addict-donor`, formData);
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم اضافة متبرع للحالة بنجاح`,
            });
            showSuccessAlert('تمت الاضافة بنجاح');
            setFormData({ AddictDonor: '', AddictDonorPhone: '' });
        } catch (error) {
            if (error.response && error.response.data.error) {
                showErrorAlert(error.response.data.error);
            } else {
                showErrorAlert('حدث خطأ اثناء الاضافة');
            }
        }
    };

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">اضف متبرع للحالة جديد</h1>
            <div className="max-w-4xl mx-auto p-8 bg-white rounded-xl shadow-lg">
                <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-8">
                    <div>
                        <label htmlFor="AddictDonor" className="block text-lg font-medium mb-2">اسم المتبرع</label>
                        <input
                            type="text"
                            id="AddictDonor"
                            name="AddictDonor"
                            value={formData.AddictDonor}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="AddictDonorPhone" className="block text-lg font-medium mb-2">هاتف المتبرع</label>
                        <input
                            type="text"
                            id="AddictDonorPhone"
                            name="AddictDonorPhone"
                            value={formData.AddictDonorPhone}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="col-span-1">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white text-lg font-semibold py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                        >
                            أضافة متبرع
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddAddictDonor;
