import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import { useParams } from 'react-router-dom';

const AddAdictFormDetails = () => {
    const { id } = useParams();
    const [addictAddress, setAddictAddress] = useState('');
    const [addictPhone1, setAddictPhone1] = useState('');
    const [addictPhone2, setAddictPhone2] = useState('');
    const [addictPhone3, setAddictPhone3] = useState('');
    const [addictEntryDate, setAddictEntryDate] = useState('');
    const [addictExitDate, setAddictExitDate] = useState('');
    const [sanatoriumAddress, setSanatoriumAddress] = useState('');
    const [sanatoriumAdmin, setSanatoriumAdmin] = useState('');
    const [sanatoriumAdminPhone, setSanatoriumAdminPhone] = useState('');
    const [sanatoriumFinanceAdmin, setSanatoriumFinanceAdmin] = useState('');
    const [sanatoriumFinanceAdminPhone, setSanatoriumFinanceAdminPhone] = useState('');
    const [sanatoriumExceptions, setSanatoriumExceptions] = useState('');
    const [formData, setFormData] = useState({
        _id: id,
        addict: '',
        responsibleServer: '',
        sanatorium: '',
        delviryExpensee: '',
        delviryExpenseeresponsible: '',
        delviryExpenseeresponsiblePhone: '',
        residenceExpense: '',
        personalExpense: '',
        medicalExpense: '',
        cigiratesExpense: '',
        medicalTestsExpenes: '',
        xraysExpense: '',
        kantennExpense: '',
        otherExpenses: '',
        therapySessionExpense: '',
        DetoksExpense: '',
        extramedicineExpense: '',
        extramedicineTestsandxraysExpense: '',
        goBackExpense: '',
        changePlaceExpense: '',
        startDate: '',
        endDate: ''
    });
    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_SERVER}addict-form/${formData._id}`, {
                startDate: formData.startDate,
                endDate: formData.endDate,
            });
            Swal.fire('نجاح!', 'تم التحديث بنجاح', 'success');
            fetchData()
        } catch (error) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء تحديث البيانات.', 'error');
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict-form/` + id);
            const data = response.data;
            console.log(data);
            setFormData({
                _id: id,
                addict: data.addict.addictName || '',
                responsibleServer: data.responsibleServer || '',
                sanatorium: data.sanatorium.sanatoriumName || '',
                delviryExpensee: data.delviryExpensee || '',
                delviryExpenseeresponsible: data.delviryExpenseeresponsible || '',
                delviryExpenseeresponsiblePhone: data.delviryExpenseeresponsiblePhone || '',
                residenceExpense: data.residenceExpense || '',
                personalExpense: data.personalExpense || '',
                medicalExpense: data.medicalExpense || '',
                cigiratesExpense: data.cigiratesExpense || '',
                medicalTestsExpenes: data.medicalTestsExpenes || '',
                xraysExpense: data.xraysExpense || '',
                kantennExpense: data.kantennExpense || '',
                otherExpenses: data.otherExpenses || '',
                therapySessionExpense: data.therapySessionExpense || '',
                DetoksExpense: data.DetoksExpense || '',
                extramedicineExpense: data.extramedicineExpense || '',
                extramedicineTestsandxraysExpense: data.extramedicineTestsandxraysExpense || '',
                goBackExpense: data.goBackExpense || '',
                changePlaceExpense: data.changePlaceExpense || '',
                startDate: data.startDate || '',
                endDate: data.endDate || ''
            });
            setAddictAddress(data.addict.addictAddress || '');
            setAddictPhone1(data.addict.addictPhone1 || '');
            setAddictPhone2(data.addict.addictPhone2 || '');
            setAddictPhone3(data.addict.addictPhone3 || '');
            setAddictEntryDate(data.addict.addictEntryDate || '');
            setAddictExitDate(data.addict.addictExitDate || '');
            setSanatoriumAddress(data.sanatorium.sanatoriumAddress || '');
            setSanatoriumAdmin(data.sanatorium.sanatoriumAdmin || '');
            setSanatoriumAdminPhone(data.sanatorium.sanatoriumAdminPhone || '');
            setSanatoriumFinanceAdmin(data.sanatorium.sanatoriumFinanceAdmin || '');
            setSanatoriumFinanceAdminPhone(data.sanatorium.sanatoriumFinanceAdminPhone || '');
            setSanatoriumExceptions(data.sanatorium.sanatoriumExceptions || '');
        } catch (error) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء جلب البيانات.', 'error');
        }
    };

    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">تفاصيل الاستمارة</h1>
            <div className='text-center'>
                <button onClick={handlePrint} className="bg-lime-500 text-white px-6 py-2 rounded-md mb-4">طباعة</button>
            </div>
            <div className="max-w-4xl lg:max-w-7xl xl:max-w-[90%] mx-auto p-8 bg-white rounded-xl shadow-lg">
                <form className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 print:hidden">
                    <div>
                        <label htmlFor="addict" className="block text-lg font-medium mb-2">الحالة</label>
                        <input
                            type="text"
                            id="addict"
                            readOnly
                            name="addict"
                            value={formData.addict}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictAddress" className="block text-lg font-medium mb-2">عنوان الحالة</label>
                        <input
                            type="text"
                            id="addictAddress"
                            readOnly
                            name="addictAddress"
                            value={addictAddress}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictPhone1" className="block text-lg font-medium mb-2">رقم هاتف الحالة الاول</label>
                        <input
                            type="text"
                            id="addictPhone1"
                            readOnly
                            name="addictPhone1"
                            value={addictPhone1}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictPhone2" className="block text-lg font-medium mb-2">رقم هاتف الحالة التاني</label>
                        <input
                            type="text"
                            id="addictPhone2"
                            readOnly
                            name="addictPhone2"
                            value={addictPhone2}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictPhone3" className="block text-lg font-medium mb-2">رقم هاتف الحالة التالت</label>
                        <input
                            type="text"
                            id="addictPhone3"
                            readOnly
                            name="addictPhone3"
                            value={addictPhone3}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictEntryDate" className="block text-lg font-medium mb-2">تاريخ دخول الخدمة</label>
                        <input
                            type="text"
                            id="addictEntryDate"
                            readOnly
                            name="addictEntryDate"
                            value={new Date(addictEntryDate).toLocaleDateString('en-uk')}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictExitDate" className="block text-lg font-medium mb-2">تاريخ خروج الخدمة</label>
                        <input
                            type="text"
                            id="addictExitDate"
                            readOnly
                            name="addictExitDate"
                            value={addictExitDate ? new Date(addictExitDate).toLocaleDateString('en-uk') : "لا يوجد"}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatorium" className="block text-lg font-medium mb-2">المصحة</label>
                        <input
                            type="text"
                            id="sanatorium"
                            readOnly
                            name="sanatorium"
                            value={formData.sanatorium}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumAddress" className="block text-lg font-medium mb-2">عنوان المصحة</label>
                        <input
                            type="text"
                            id="sanatoriumAddress"
                            readOnly
                            name="sanatoriumAddress"
                            value={sanatoriumAddress}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumAdmin" className="block text-lg font-medium mb-2">مسئول المصحة</label>
                        <input
                            type="text"
                            id="sanatoriumAdmin"
                            readOnly
                            name="sanatoriumAdmin"
                            value={sanatoriumAdmin}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumAdminPhone" className="block text-lg font-medium mb-2">رقم تليفون مسئول المصحة</label>
                        <input
                            type="text"
                            id="sanatoriumAdminPhone"
                            readOnly
                            name="sanatoriumAdminPhone"
                            value={sanatoriumAdminPhone}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumFinanceAdmin" className="block text-lg font-medium mb-2">المئسول المالي للمصحة</label>
                        <input
                            type="text"
                            id="sanatoriumFinanceAdmin"
                            readOnly
                            name="sanatoriumFinanceAdmin"
                            value={sanatoriumFinanceAdmin}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumFinanceAdminPhone" className="block text-lg font-medium mb-2">رقم تليفون المسئول المالي للمصحة</label>
                        <input
                            type="text"
                            id="sanatoriumFinanceAdminPhone"
                            readOnly
                            name="sanatoriumFinanceAdminPhone"
                            value={sanatoriumFinanceAdminPhone}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumExceptions" className="block text-lg font-medium mb-2">استسنائات المصحة</label>
                        <input
                            type="text"
                            id="sanatoriumExceptions"
                            readOnly
                            name="sanatoriumExceptions"
                            value={sanatoriumExceptions && sanatoriumExceptions.join(', ')}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="responsibleServer" className="block text-lg font-medium mb-2">اسم الخادم المسؤول</label>
                        <input
                            type="text"
                            id="responsibleServer"
                            readOnly
                            name="responsibleServer"
                            value={formData.responsibleServer}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpensee" className="block text-lg font-medium mb-2">تكاليف التوصيل</label>
                        <input
                            type="number"
                            id="delviryExpensee"
                            readOnly
                            name="delviryExpensee"
                            value={formData.delviryExpensee || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpenseeresponsible" className="block text-lg font-medium mb-2">المسؤول عن التوصيل</label>
                        <input
                            type="text"
                            id="delviryExpenseeresponsible"
                            readOnly
                            name="delviryExpenseeresponsible"
                            value={formData.delviryExpenseeresponsible}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpenseeresponsiblePhone" className="block text-lg font-medium mb-2">هاتف المسؤول عن التوصيل</label>
                        <input
                            type="text"
                            id="delviryExpenseeresponsiblePhone"
                            readOnly
                            name="delviryExpenseeresponsiblePhone"
                            value={formData.delviryExpenseeresponsiblePhone}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpenseeresponsiblePhone" className="block text-lg font-medium mb-2">مصاريف الاقامة بالمصحة</label>
                        <input
                            type="number"
                            id="residenceExpense"
                            readOnly
                            name="residenceExpense"
                            value={formData.residenceExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="personalExpense" className="block text-lg font-medium mb-2">المصاريف الشخصية داخل المصحة</label>
                        <input
                            type="number"
                            id="personalExpense"
                            readOnly
                            name="personalExpense"
                            value={formData.personalExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="medicalExpense" className="block text-lg font-medium mb-2">مصاريف الدواء</label>
                        <input
                            type="number"
                            id="medicalExpense"
                            readOnly
                            name="medicalExpense"
                            value={formData.medicalExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="cigiratesExpense" className="block text-lg font-medium mb-2">مصاريف السجاير</label>
                        <input
                            type="number"
                            id="cigiratesExpense"
                            readOnly
                            name="cigiratesExpense"
                            value={formData.cigiratesExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="kantennExpense" className="block text-lg font-medium mb-2">مصاريف الكانتين</label>
                        <input
                            type="number"
                            id="kantennExpense"
                            readOnly
                            name="kantennExpense"
                            value={formData.kantennExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="medicalTestsExpenes" className="block text-lg font-medium mb-2">مصاريف التحاليل</label>
                        <input
                            type="number"
                            id="medicalTestsExpenes"
                            readOnly
                            name="medicalTestsExpenes"
                            value={formData.medicalTestsExpenes || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="xraysExpense" className="block text-lg font-medium mb-2">مصاريف الاشعة</label>
                        <input
                            type="number"
                            id="xraysExpense"
                            readOnly
                            name="xraysExpense"
                            value={formData.xraysExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="otherExpenses" className="block text-lg font-medium mb-2">مصاريف اخري</label>
                        <input
                            type="number"
                            id="otherExpenses"
                            readOnly
                            name="otherExpenses"
                            value={formData.otherExpenses || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="therapySessionExpense" className="block text-lg font-medium mb-2">سعر الجلسات العلاجية داخل المصحة</label>
                        <input
                            type="number"
                            id="therapySessionExpense"
                            readOnly
                            name="therapySessionExpense"
                            value={formData.therapySessionExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="DetoksExpense" className="block text-lg font-medium mb-2">سعر الديتوكس</label>
                        <input
                            type="number"
                            id="DetoksExpense"
                            readOnly
                            name="DetoksExpense"
                            value={formData.DetoksExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="extramedicineExpense" className="block text-lg font-medium mb-2">سعر ادوية زيادة عن المحددة</label>
                        <input
                            type="number"
                            id="extramedicineExpense"
                            readOnly
                            name="extramedicineExpense"
                            value={formData.extramedicineExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="extramedicineTestsandxraysExpense" className="block text-lg font-medium mb-2">سعر اشاعات و تحاليل اضافية</label>
                        <input
                            type="number"
                            id="extramedicineTestsandxraysExpense"
                            readOnly
                            name="extramedicineTestsandxraysExpense"
                            value={formData.extramedicineTestsandxraysExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="goBackExpense" className="block text-lg font-medium mb-2">مصاريف الذهاب و العودة</label>
                        <input
                            type="number"
                            id="goBackExpense"
                            readOnly
                            name="goBackExpense"
                            value={formData.goBackExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="changePlaceExpense" className="block text-lg font-medium mb-2">مصاريف التحويل من مكان الي مكان</label>
                        <input
                            type="number"
                            id="changePlaceExpense"
                            readOnly
                            name="changePlaceExpense"
                            value={formData.changePlaceExpense || 0}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block text-lg font-medium text-gray-700">
                            تاريخ دخول المصحة
                        </label>
                        <input
                            type="date"
                            name="startDate"
                            value={formData.startDate ? new Date(formData.startDate).toISOString().split('T')[0] : ''}
                            onChange={handleDateChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block text-lg font-medium text-gray-700">
                            تاريخ خروج المصحة
                        </label>
                        <input
                            type="date"
                            name="endDate"
                            value={formData.endDate ? new Date(formData.endDate).toISOString().split('T')[0] : ''}
                            onChange={handleDateChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div className='col-span-1 items-center self-end text-center'>
                    <button onClick={handleSubmit} className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none w-full">
                        حفظ التعديلات
                    </button>
                    </div>
                </form>
                <div className='hidden print:block'>
                    <div className="print:grid print:grid-cols-3 print:gap-4">
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                اسم الحالة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addict || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                الخادم المسؤول
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.responsibleServer || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                عنوان الحالة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{addictAddress || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                رقم تليفون الحالة 1
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{addictPhone1 || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                رقم تليفون الحالة 2
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{addictPhone2 || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                رقم تليفون الحالة 3
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{addictPhone3 || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                تاريخ دخول الحالة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{new Date(addictEntryDate).toLocaleDateString('en-uk') || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                تاريخ خروج الحالة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{addictExitDate ? new Date(addictExitDate).toLocaleDateString('en-uk') : "لا يوجد"}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                عنوان المصحة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{sanatoriumAddress || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مدير المصحة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{sanatoriumAdmin || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                هاتف مدير المصحة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{sanatoriumAdminPhone || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مدير الشؤون المالية للمصحة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{sanatoriumFinanceAdmin || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                هاتف مدير الشؤون المالية للمصحة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{sanatoriumFinanceAdminPhone || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                استثناءات المصحة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{sanatoriumExceptions || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                المصحة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.sanatorium || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف التوصيل
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.delviryExpensee || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                المسؤول عن مصاريف التوصيل
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.delviryExpenseeresponsible || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                هاتف المسؤول عن مصاريف التوصيل
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.delviryExpenseeresponsiblePhone || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف الإقامة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.residenceExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                المصاريف الشخصية
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.personalExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                المصاريف الطبية
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.medicalExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف السجائر
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.cigiratesExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف الفحوصات الطبية
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.medicalTestsExpenes || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف الأشعة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.xraysExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف الكانتين
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.kantennExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف أخرى
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.otherExpenses || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف جلسات العلاج
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.therapySessionExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف الديتوكس
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.DetoksExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف الأدوية الإضافية
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.extramedicineExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف الفحوصات والأشعة الإضافية
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.extramedicineTestsandxraysExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                مصاريف العودة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.goBackExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                تغيير مكان المصاريف
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.changePlaceExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                تاريخ دخول المصحة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.startDate ? new Date(formData.startDate).toLocaleDateString("en-uk") : "لا يوجد"}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">
                                تاريخ خروج المصحة
                            </label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.endDate ? new Date(formData.endDate).toLocaleDateString("en-uk") : "لا يوجد"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AddAdictFormDetails;
