import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../components/Navbar';

const FormExpenseTable = () => {
    const [formExpenses, setFormExpenses] = useState([]);

    useEffect(() => {
        fetchFormExpenses();
    }, []);

    const fetchFormExpenses = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}form-expense`);
            setFormExpenses(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching form expenses:', error);
        }
    };

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="container mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">جدول مصروفات الاستمارات</h1>
                <div className="flex justify-end mb-4">
                    <Link to={'/addiction/add/FormExpense'}>
                        <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none">
                            أضف مصروف جديد
                        </button>
                    </Link>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-3">#</th>
                                <th className="px-4 py-3">الحالة</th>
                                <th className="px-4 py-3">الثمن</th>
                                <th className="px-4 py-3">تاريخ الصرف</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formExpenses.map((formExpense, index) => (
                                <tr key={formExpense._id} className="border-b hover:bg-gray-100">
                                    <td className="px-4 py-3 text-center">{index + 1}</td>
                                    <td className="px-4 py-3 text-center">تحت الانشاء</td>
                                    <td className="px-4 py-3 text-center">{formExpense.AddictFormExpense}</td>
                                    <td className="px-4 py-3 text-center">{new Date(formExpense.createdAt).toLocaleDateString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default FormExpenseTable;