import Swal from "sweetalert2";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Workers = () => {
    const [workers, setWorkers] = useState([]);
    const [filteredWorkers, setFilteredWorkers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTerm2, setSearchTerm2] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [workersPerPage] = useState(10);
    const [editingWorkerId, setEditingWorkerId] = useState(null);
    const [updatedData, setUpdatedData] = useState({});

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };


    const fetchWorkers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/workers`);
            setWorkers(response.data.workers);
            setFilteredWorkers(response.data.workers);
        } catch (error) {
            console.error('Error fetching workers:', error);
        }
    };


    const downloadWorkerCSV = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/workers/csv`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'workers.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error downloading CSV file:", error);
        }
    }

    const handleSearch = (e) => {
        const nameTerm = searchTerm;
        const deptTerm = searchTerm2;
        setSearchTerm(nameTerm);
        setSearchTerm2(e.target.value)

        const filtered = workers.filter(worker =>
            worker.name.includes(nameTerm) && worker.departement.includes(e.target.value)
        );
        setFilteredWorkers(filtered);
        setCurrentPage(1);
    };
    const handleSearchName = (e) => {
        const nameTerm = e.target.value;
        const deptTerm = searchTerm2;
        setSearchTerm(e.target.value);
        setSearchTerm2(deptTerm)

        const filtered = workers.filter(worker =>
            worker.name.includes(e.target.value) && worker.departement.includes(deptTerm)
        );
        setFilteredWorkers(filtered);
        setCurrentPage(1);
    };

    const indexOfLastWorker = currentPage * workersPerPage;
    const indexOfFirstWorker = indexOfLastWorker - workersPerPage;
    const currentWorkers = filteredWorkers.slice(indexOfFirstWorker, indexOfLastWorker);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleEditClick = (worker) => {
        setEditingWorkerId(worker._id);
        setUpdatedData(worker);
    };

    const getAge = (birthDate) => {
        if (!birthDate) return "N/A";
        const birth = new Date(birthDate);
        const today = new Date();
        const age = today.getFullYear() - birth.getFullYear();
        return age;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleUpdate = async (workerId) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_SERVER}add/update/worker/${workerId}`, updatedData);
            const updatedWorkers = workers.map(worker => worker._id === workerId ? response.data.worker : worker);
            showSuccessAlert('تم تعديل البيانات بنجاح')
            setWorkers(updatedWorkers);
            setFilteredWorkers(updatedWorkers);
            setEditingWorkerId(null);
        } catch (error) {
            showErrorAlert('حدث خطأ برجاء مراجعة البيانات')
            console.error('Error updating worker:', error);
        }
    };

    const handleChangeWorkingStatus = async (worker) => {
        try {
            if (worker.working) {
                const response = await axios.put(`${process.env.REACT_APP_HOST_SERVER}add/update/worker/${worker._id}`, { working: false });
                const response2 = await axios.put(`${process.env.REACT_APP_HOST_SERVER}salary/update2/${worker.name}`, { working: false });

                showSuccessAlert('تم اخلاء الطرف بنجاح ل' + worker.name)
                fetchWorkers()
            } else {
                const response = await axios.put(`${process.env.REACT_APP_HOST_SERVER}add/update/worker/${worker._id}`, { working: true });
                const response2 = await axios.put(`${process.env.REACT_APP_HOST_SERVER}salary/update2/${worker.name}`, { working: true });
                showSuccessAlert('تم ارجاع العمل بنجاح ل' + worker.name)
                fetchWorkers()
            }
        } catch (err) {
            console.log('اخلاء طرف', err)
        }

    }
    const handleDelete = async (workerId) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${process.env.REACT_APP_HOST_SERVER}add/delete/${workerId}`);
                    showSuccessAlert('تم الحذف بنجاح');
                    fetchWorkers();
                } catch (error) {
                    showErrorAlert('حدث خطأ برجاء مراجعة البيانات');
                    console.error('Error deleting worker:', error);
                }
            }
        });
    };


    const handleCancelEdit = () => {
        setEditingWorkerId(null);
    };

    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, [])

    useEffect(() => {
        fetchWorkers();
    }, []);

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4 text-center">قائمة العمال</h1>
            <div className="mb-4">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchName}
                    placeholder="ابحث بالاسم"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg text-right"
                />
            </div>
            <div className="mb-4">
                <select
                    value={searchTerm2}
                    onChange={handleSearch}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg text-right"
                >
                    <option value="">اختر الادارة</option>
                    {[...new Set(workers.map(worker => worker.departement))].map((department, index) => (
                        <option key={index} value={department}>
                            {department}
                        </option>
                    ))}
                </select>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border-b">الاسم</th>
                            <th className="px-4 py-2 border-b">الادارة</th>
                            <th className="px-4 py-2 border-b">الوظيفة</th>
                            <th className="px-4 py-2 border-b">العنوان</th>
                            <th className="px-4 py-2 border-b">تاريخ التعيين</th>
                            <th className="px-4 py-2 border-b">الرقم القومي</th>
                            <th className="px-4 py-2 border-b">تاريخ الميلاد</th>
                            <th className="px-4 py-2 border-b">العمر</th>
                            <th className="px-4 py-2 border-b">المؤهل</th>
                            <th className="px-4 py-2 border-b">التأمين</th>
                            <th className="px-4 py-2 border-b">رقم التأمين</th>
                            <th className="px-4 py-2 border-b">حالة التجنيد</th>
                            <th className="px-4 py-2 border-b">رقم الهاتف</th>
                            <th className="px-4 py-2 border-b">رقم الواتساب</th>
                            <th className="px-4 py-2 border-b">اجازة الاعتيادى</th>
                            <th className="px-4 py-2 border-b">اجازة العارضة</th>
                            <th className="px-4 py-2 border-b">ملاحظات</th>
                            <th className="px-4 py-2 border-b">يعمل</th>
                            <th className="px-4 py-2 border-b">اجرائات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentWorkers.map((worker) => (
                            <tr key={worker._id} className={!worker.working ? 'bg-red-200' : ''}>
                                {editingWorkerId === worker._id ? (
                                    <>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="name"
                                                value={updatedData.name}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="departement"
                                                value={updatedData.departement}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="job"
                                                value={updatedData.job}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="address"
                                                value={updatedData.address}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="startDate"
                                                value={updatedData.startDate}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="ID"
                                                value={updatedData.ID}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="date"
                                                name="birthDate"
                                                value={updatedData.birthDate}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    const birthDate = new Date(e.target.value);
                                                    const today = new Date();
                                                    const age = today.getFullYear() - birthDate.getFullYear();
                                                    const monthDiff = today.getMonth() - birthDate.getMonth();
                                                    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                                                        age--;
                                                    }
                                                    setUpdatedData((prevData) => ({ ...prevData, age: age }));
                                                }}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="number"
                                                name="age"
                                                value={updatedData.age}
                                                disabled
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="qualifier"
                                                value={updatedData.qualifier}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="assurance"
                                                value={updatedData.assurance}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="assuranceNumber"
                                                value={updatedData.assuranceNumber}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="soldierStatus"
                                                value={updatedData.soldierStatus}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="phone"
                                                value={updatedData.phone}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                name="whatsapp"
                                                value={updatedData.whatsapp}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                readOnly
                                                name="regularVacation"
                                                value={updatedData.regularVacation}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                type="text"
                                                readOnly
                                                name="casualVacation"
                                                value={updatedData.casualVacation}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <textarea
                                                name="notes"
                                                value={updatedData.notes}
                                                onChange={handleChange}
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <input
                                                value={"نعم"}
                                                readOnly
                                                className="w-full border border-gray-300 px-2"
                                            />
                                        </td>
                                        <td className="px-4 border py-2 border-b">
                                            <div className="">
                                                <button
                                                    onClick={() => handleUpdate(worker._id)}
                                                    className="bg-lime-500 text-white block w-14 text-center mb-2 px-3 py-1 rounded"
                                                >
                                                    حفظ
                                                </button>
                                                <button
                                                    onClick={handleCancelEdit}
                                                    className="bg-red-500 text-white block w-14 text-center mt-2 px-3 py-1 rounded"
                                                >
                                                    إلغاء
                                                </button>
                                            </div>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className="px-4 border py-2 border-b">{worker.name}</td>
                                        <td className="px-4 border py-2 border-b">{worker.departement}</td>
                                        <td className="px-4 border py-2 border-b">{worker.job}</td>
                                        <td className="px-4 border py-2 border-b">{worker.address}</td>
                                        <td className="px-4 border py-2 border-b">{new Date(worker.startDate).toLocaleDateString('en-uk')}</td>
                                        <td className="px-4 border py-2 border-b">{worker.ID}</td>
                                        <td className="px-4 border py-2 border-b">{new Date(worker.birthDate).toLocaleDateString('en-uk')}</td>
                                        <td className="px-4 border py-2 border-b">{getAge(worker.birthDate)}</td>
                                        <td className="px-4 border py-2 border-b">{worker.qualifier}</td>
                                        <td className="px-4 border py-2 border-b">{worker.assurance}</td>
                                        <td className="px-4 border py-2 border-b">{worker.assuranceNumber}</td>
                                        <td className="px-4 border py-2 border-b">{worker.soldierStatus}</td>
                                        <td className="px-4 border py-2 border-b">{worker.phone}</td>
                                        <td className="px-4 border py-2 border-b">{worker.whatsapp ? worker.whatsapp : 'لا يوجد'}</td>
                                        <td className="px-4 border py-2 border-b">{worker.regularVacation ? worker.regularVacation : 'لا يوجد'}</td>
                                        <td className="px-4 border py-2 border-b">{worker.casualVacation ? worker.casualVacation : 'لا يوجد'}</td>
                                        <td className="px-4 border py-2 border-b">{worker.notes ? worker.notes : 'لا يوجد'}</td>
                                        <td className="px-4 border py-2 border-b">{worker.working ? "نعم" : 'لا'}</td>
                                        <td className="px-4 border py-2 border-b">
                                            <button
                                                onClick={() => handleDelete(worker._id)}
                                                className="bg-red-500 mb-3 text-white w-28 text-center px-3 py-1 rounded"
                                            >
                                                حذف
                                            </button> <br />
                                            <button
                                                onClick={() => handleEditClick(worker)}
                                                className="bg-lime-500 text-white w-28 text-center px-3 py-1 rounded"
                                            >
                                                تعديل
                                            </button> <br />
                                            <button
                                                onClick={() => handleChangeWorkingStatus(worker)}
                                                className="bg-gray-500 mt-3 text-white w-28 text-center px-3 py-1 rounded"
                                            >
                                                {
                                                    worker.working ?
                                                        " اخلاء طرف" :
                                                        "ارجاع للعمل"
                                                }
                                            </button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>

                </table>
                <div>
                    <button
                        onClick={downloadWorkerCSV}
                        className="px-2 bg-lime-500 rounded-lg py-2 text-white mt-3"
                    >تحميل ملف العمال</button>
                </div>
            </div>
            <div className="mt-5 text-center">
                {Array.from({ length: Math.ceil(filteredWorkers.length / workersPerPage) }, (_, i) => (
                    <button
                        key={i}
                        onClick={() => paginate(i + 1)}
                        className={`mx-1 px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-lime-500 text-white' : 'bg-gray-200'}`}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Workers;
