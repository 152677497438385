import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../../components/Navbar";
import Logo from "../../imgs/logo.png"
const AddictDetails = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        addictName: "",
        addictID: "",
        addictAddress: "",
        addictPhone1: "",
        addictPhone2: "",
        addictPhone3: "",
        addictEntryDate: "",
        addictExitDate: "",
        addictCode: "",
        addictResponsibleServer: "",
        addictBirthDate: "",
        addictAddictions: [""],
        addictExceptions: [""],
    });
    const [age, setAge] = useState('');

    useEffect(() => {
        const fetchAddictData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict/${id}`);
                const addictData = response.data;
                setFormData({
                    addictName: addictData.addictName || "",
                    addictID: addictData.addictID || "",
                    addictAddress: addictData.addictAddress || "",
                    addictPhone1: addictData.addictPhone1 || "",
                    addictPhone2: addictData.addictPhone2 || "",
                    addictPhone3: addictData.addictPhone3 || "",
                    addictEntryDate: addictData.addictEntryDate || "",
                    addictExitDate: addictData.addictExitDate || "",
                    addictCode: addictData.addictCode || "",
                    addictResponsibleServer: addictData.addictResponsibleServer || "",
                    addictBirthDate: addictData.addictBirthDate || "",
                    addictAddictions: addictData.addictAddictions || [""],
                    addictExceptions: addictData.addictExceptions || [""],
                });
                if (addictData.addictBirthDate) {
                    const birthDate = new Date(addictData.addictBirthDate);
                    const calculatedAge = Math.floor((new Date() - birthDate) / (1000 * 60 * 60 * 24 * 365.25));
                    setAge(calculatedAge);
                }
            } catch (error) {
                console.error("Error fetching addict data:", error);
                showErrorAlert("حدث خطأ أثناء جلب بيانات الحالة.");
            }
        };

        fetchAddictData();
    }, [id]);

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="my-4 hidden print:block">
                <img src={Logo} alt="لوجو" className="w-32 h-32" />
            </div>
            <div className="w-[90%] mx-auto p-8 bg-white rounded-xl shadow-lg">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">تفاصيل الحالة</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div>
                        <label className="block text-lg font-medium mb-2">الأسم</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictName || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">الرقم القومي</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictID || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">العنوان</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictAddress || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">رقم الهاتف الأول</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictPhone1 || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">رقم الهاتف الثاني</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictPhone2 || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">رقم الهاتف الثالث</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictPhone3 || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">تاريخ الدخول</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictEntryDate ? new Date(formData.addictEntryDate).toLocaleDateString('en-uk') : "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">تاريخ الخروج</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictExitDate ? new Date(formData.addictExitDate).toLocaleDateString('en-uk') : "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">كود الحالة</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictCode || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">الخادم المسئول</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictResponsibleServer || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">تاريخ الميلاد</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictBirthDate ? new Date(formData.addictBirthDate).toLocaleDateString('en-uk') : "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">تاريخ الميلاد</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictBirthDate ? new Date(formData.addictBirthDate).toLocaleDateString('en-uk') : "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">العمر</label>
                        <p className="w-full border rounded-lg px-4 py-3">{age || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مواد التعاطي</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictAddictions.join(', ') || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">الاستثناءات</label>
                        <p className="w-full border rounded-lg px-4 py-3">{formData.addictExceptions.join(', ') || "لا يوجد"}</p>
                    </div>
                </div>
                <div className="text-center">
                    <button
                        className="bg-blue-600 text-white px-4 py-2 mt-4 rounded-lg w-[50%]"
                        onClick={handlePrint}
                    >
                        طباعة
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddictDetails;