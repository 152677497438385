import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
    const [place, setPlace] = useState('');
    const [code, setCode] = useState('');
    const [product, setProduct] = useState('');
    const [products, setProducts] = useState([]);
    const [quantity, setQuantity] = useState('');
    const [buyPrice, setBuyPrice] = useState('');
    const [sellPrice, setSellPrice] = useState('');
    const [places, setPlaces] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const navigate = useNavigate();

    const getPlaces = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}get/place`)
            .then((res) => {
                setPlaces(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/products`);
            setProducts(response.data.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const handleProductCodeChange = (e) => {
        const code = e.target.value;
        setCode(code);

        const existingProduct = products.find(product => product.code === code);
        if (existingProduct) {
            setPlace(existingProduct.place);
            setProduct(existingProduct.product);
            setQuantity(existingProduct.quantity);
            setBuyPrice(existingProduct.buyPrice);
            setSellPrice(existingProduct.sellPrice);
            setIsUpdate(true);
        } else {
            setProduct('');
            setQuantity('');
            setBuyPrice('');
            setSellPrice('');
            setIsUpdate(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}add/product`, {
                code,
                place,
                product,
                quantity,
                buyPrice,
                sellPrice,
            });
            setSuccess(response.data.message);
            setError(null);
            setProduct('');
            setQuantity('');
            setCode('');
            setBuyPrice('');
            setSellPrice('');
            const expenseData = {
                place: place,
                type: product,
                price: buyPrice * quantity,
                accepted: "no"
            };

            await axios.post(`${process.env.REACT_APP_HOST_SERVER}locker/expenses`, expenseData);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    useEffect(() => {
        getPlaces();
        getProducts();
    }, []);

    return (
        <div className="w-[90%] m-auto">
            <Navbar />
            <div className="add-heading">
                <h1 className="text-center text-3xl font-bold">صفحة اضافة الاصناف الجديدة في الكنيسة</h1>
            </div>
            <div className="form w-[50%] m-auto">
                <form onSubmit={handleSubmit}>
                    <div className="form-group mt-4">
                        <label className="text-xl">المكان المستهدف</label>
                        <select
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setPlace(e.target.value)}
                            value={place}
                        >
                            <option value="">من فضلك اختار المكان</option>
                            {
                                places && places.map((item) => (
                                    <option key={item._id} value={item._id}>{item.place}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className="form-group mt-4">
                        <label className="text-xl">كود الصنف الجديد</label>
                        <input
                            type="text"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={handleProductCodeChange}
                            value={code}
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">الصنف الجديد</label>
                        <input
                            type="text"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                            readOnly={isUpdate}
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">الكمية</label>
                        <input
                            type="number"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setQuantity(e.target.value)}
                            value={quantity}
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">سعر التكلفة</label>
                        <input
                            type="number"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            value={buyPrice}
                            onChange={(e) => setBuyPrice(e.target.value)}
                            readOnly={isUpdate}
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">سعر البيع</label>
                        <input
                            type="number"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            value={sellPrice}
                            onChange={(e) => setSellPrice(e.target.value)}
                            readOnly={isUpdate}
                        />
                    </div>
                    {error && (
                        <div className="mt-4 text-red-500 text-center">
                            {error}
                        </div>
                    )}
                    {success && (
                        <div className="mt-4 text-green-500 text-center">
                            {success}
                        </div>
                    )}
                    <div className="submit mt-6 text-center">
                        <button
                            type="submit"
                            className="bg-[#000000] text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                            disabled={loading}
                        >
                            {isUpdate ? 'تحديث الصنف' : 'إضافة صنف'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddProduct;