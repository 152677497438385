import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const IncomePage = () => {
    const [incomes, setIncomes] = useState([]);
    const [startSerial, setStartSerial] = useState('');
    const [endSerial, setEndSerial] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [editingIncome, setEditingIncome] = useState(null);
    const [place,setPlace] = useState("");
    const [places, setPlaces] = useState('')
    const [lastIncomes, setLastIncomes] = useState('')
    const itemsPerPage = 10;
    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };
    const getPlaces = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}get/place`)
            .then((res) => {
                setPlaces(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchIncomes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}locker/incomes`);
            setIncomes(response.data);
        } catch (error) {
            console.error('Error fetching incomes:', error);
        }
    };
    useEffect(() => {
        fetchIncomes();
        verifyAdmin();
        getPlaces();
    }, []);

    const handleEditChange = (e, field, id) => {
        setIncomes(incomes.filter((item)=> item.accepted === "yes").map(income =>
            income._id === id
                ? { ...income, [field]: field === 'place' ? places.find(p => p._id === e.target.value) : e.target.value }
                : income
        ));
    };


    const saveEdit = async (id) => {
        const incomeToSave = incomes.find(income => income._id === id);
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}locker/update/incomes/${id}`, incomeToSave);
            setEditingIncome(null);
        } catch (error) {
            console.error('Error saving income:', error);
        }
    };

    const filteredIncomes = incomes.filter(income => {
        if (income.accepted !== "yes") return false;
    
        const serialNumber = parseInt(income.serialNumber, 10);
        const start = startSerial ? parseInt(startSerial, 10) : null;
        const end = endSerial ? parseInt(endSerial, 10) : null;
    
        const placeMatch = !place || (income.place?.place?.includes(place));
    
        if (start !== null && end !== null) {
            return serialNumber >= start && serialNumber <= end && placeMatch;
        }
        if (start !== null) {
            return serialNumber === start && placeMatch;
        }
    
        return placeMatch;
    });
    

    const totalPrice = filteredIncomes.reduce((total, income) => total + income.price, 0);

    const indexOfLastIncome = currentPage * itemsPerPage;
    const indexOfFirstIncome = indexOfLastIncome - itemsPerPage;
    const currentIncomes = filteredIncomes.slice(indexOfFirstIncome, indexOfLastIncome);

    const totalPages = Math.ceil(filteredIncomes.length / itemsPerPage);

    const handlePrint = () => {
        window.print();
    };

    const getLastIncomes = async () => {
        const now = new Date();
        const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);
        const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000);

        const lastIncomes = incomes.filter((item) => {
            const createdAt = new Date(item.createdAt);
            const editedAt = new Date(item.updatedAt);
            return (
                (new Date(createdAt.getTime() + 60 * 60 * 1000) >= now && new Date(createdAt.getTime() + 60 * 60 * 1000) <= oneHourLater) ||
                (editedAt >= oneHourAgo && editedAt <= now)
            );
        });

        setLastIncomes(lastIncomes);
    };

    useEffect(() => {
        getLastIncomes()
    }, [incomes])
    return (
        <div className="p-6 bg-white shadow rounded-md w-[90%] mx-auto mt-6 relative overflow-x-auto">
            <h2 className="text-2xl font-bold mb-4 text-blue-600">سجل الإيرادات</h2>

            <input
                type="text"
                placeholder="رقم الوصل - البداية"
                className="mb-4 p-2 border border-blue-300 rounded w-full"
                value={startSerial}
                onChange={(e) => setStartSerial(e.target.value)}
            />
            <input
                type="text"
                placeholder="رقم الوصل - النهاية"
                className="mb-4 p-2 border border-blue-300 rounded w-full"
                value={endSerial}
                onChange={(e) => setEndSerial(e.target.value)}
            />
            <input
                type="text"
                placeholder="المكان المراد البحق عنه"
                className="mb-4 p-2 border border-blue-300 rounded w-full"
                value={place}
                onChange={(e) => setPlace(e.target.value)}
            />

            {(lastIncomes && !startSerial) && (
                <table className="w-full border border-gray-300 text-center mb-6">
                    <thead>
                        <tr className="bg-blue-100">
                            <th className="border px-4 py-2">رقم الوصل</th>
                            <th className="border px-4 py-2">المكان</th>
                            <th className="border px-4 py-2">السعر</th>
                            <th className="border px-4 py-2">النوع</th>
                            <th className="border px-4 py-2">تاريخ</th>
                            <th className="border px-4 py-2">التفاصيل</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            lastIncomes && lastIncomes.filter((item)=> item.accepted === "yes").map(lastIncome => (
                                <tr>
                                    <td className="border px-4 py-2">{lastIncome.serialNumber}</td>
                                    <td className="border px-4 py-2">{lastIncome.place.place}</td>
                                    <td className="border px-4 py-2">{lastIncome.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td className="border px-4 py-2">{lastIncome.type || 'لا يوجد'}</td>
                                    <td className="border px-4 py-2">{new Date(lastIncome.date).toLocaleDateString('en-GB')}</td>
                                    <td className="border px-4 py-2">{lastIncome.details || "لا يوجد"}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )}


            <table className="w-full border border-gray-300 text-center print:hidden">
                <thead>
                    <tr className="bg-blue-100">
                        <th className="border px-4 py-2">رقم الوصل</th>
                        <th className="border px-4 py-2">المكان</th>
                        <th className="border px-4 py-2">السعر</th>
                        <th className="border px-4 py-2">النوع</th>
                        <th className="border px-4 py-2">تاريخ</th>
                        <th className="border px-4 py-2">التفاصيل</th>
                        <th className="border px-4 py-2 print:hidden">تعديل</th>
                    </tr>
                </thead>
                <tbody>
                    {currentIncomes.filter((item)=> item.accepted === "yes").map(income => (
                        <tr key={income._id}>
                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <input value={income.serialNumber} disabled onChange={(e) => handleEditChange(e, 'serialNumber', income._id)} />
                                ) : income.serialNumber}
                            </td>
                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <select
                                        value={income.place._id}
                                        onChange={(e) => handleEditChange(e, 'place', income._id)}
                                    >
                                        {places.map((place) => (
                                            <option key={place._id} value={place._id}>
                                                {place.place}
                                            </option>
                                        ))}
                                    </select>
                                ) : income.place.place}
                            </td>

                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <input value={income.price} onChange={(e) => handleEditChange(e, 'price', income._id)} />
                                ) : income.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </td>
                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <input value={income.type} onChange={(e) => handleEditChange(e, 'type', income._id)} />
                                ) : income.type ? income.type : 'لا يوجد'}
                            </td>
                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <input
                                        type="date"
                                        value={income.date}
                                        onChange={(e) => handleEditChange(e, 'date', income._id)}
                                    />
                                ) : new Date(income.date).toLocaleDateString('en-GB')}
                            </td>
                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <input value={income.details} onChange={(e) => handleEditChange(e, 'details', income._id)} />
                                ) : income.details ? income.details : "لا يوجد"}
                            </td>

                            <td className="border px-4 py-2 print:hidden">
                                {editingIncome === income._id ? (
                                    <button onClick={() => saveEdit(income._id)} className="bg-green-500 text-white px-2 py-1 rounded">حفظ</button>
                                ) : (
                                    <button onClick={() => setEditingIncome(income._id)} className="bg-yellow-500 text-white px-2 py-1 rounded">تعديل</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <table className="w-full border border-gray-300 text-center hidden print:table">
                <thead>
                    <tr className="bg-blue-100">
                        <th className="border px-4 py-2">رقم الوصل</th>
                        <th className="border px-4 py-2">المكان</th>
                        <th className="border px-4 py-2">السعر</th>
                        <th className="border px-4 py-2">النوع</th>
                        <th className="border px-4 py-2">تاريخ</th>
                        <th className="border px-4 py-2">التفاصيل</th>
                        <th className="border px-4 py-2 print:hidden">تعديل</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredIncomes.filter((item)=> item.accepted === "yes").map(income => (
                        <tr key={income._id}>
                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <input value={income.serialNumber} disabled onChange={(e) => handleEditChange(e, 'serialNumber', income._id)} />
                                ) : income.serialNumber}
                            </td>
                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <select
                                        value={income.place._id}
                                        onChange={(e) => handleEditChange(e, 'place', income._id)}
                                    >
                                        {places.map((place) => (
                                            <option key={place._id} value={place._id}>
                                                {place.place}
                                            </option>
                                        ))}
                                    </select>
                                ) : income.place.place}
                            </td>

                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <input value={income.price} onChange={(e) => handleEditChange(e, 'price', income._id)} />
                                ) : income.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </td>
                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <input value={income.type} onChange={(e) => handleEditChange(e, 'type', income._id)} />
                                ) : income.type ? income.type : 'لا يوجد'}
                            </td>
                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <input
                                        type="date"
                                        value={income.date}
                                        onChange={(e) => handleEditChange(e, 'date', income._id)}
                                    />
                                ) : new Date(income.date).toLocaleDateString('en-GB')}
                            </td>
                            <td className="border px-4 py-2">
                                {editingIncome === income._id ? (
                                    <input value={income.details} onChange={(e) => handleEditChange(e, 'details', income._id)} />
                                ) : income.details ? income.details : "لا يوجد"}
                            </td>

                            <td className="border px-4 py-2 print:hidden">
                                {editingIncome === income._id ? (
                                    <button onClick={() => saveEdit(income._id)} className="bg-green-500 text-white px-2 py-1 rounded">حفظ</button>
                                ) : (
                                    <button onClick={() => setEditingIncome(income._id)} className="bg-yellow-500 text-white px-2 py-1 rounded">تعديل</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {startSerial && (
                <div className="mt-4">
                    <p className="font-bold text-xl text-center">إجمالي السعر <br /> {totalPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} جنيها فقط</p>
                </div>
            )}

            <div className="flex justify-between mt-4">
                <button
                    className="bg-red-600 text-white px-4 py-2 rounded disabled:opacity-50"
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                >
                    السابق
                </button>

                <div className="flex items-center">
                    <span>صفحة </span>
                    <input
                        type="number"
                        value={currentPage}
                        min="1"
                        max={totalPages}
                        onChange={(e) => {
                            const pageNumber = Math.max(1, Math.min(Number(e.target.value), totalPages));
                            setCurrentPage(pageNumber);
                        }}
                        className="w-12 mx-2 text-center border rounded focus:outline-none focus:ring-2 focus:ring-blue-300"
                    />
                    <span> من {totalPages}</span>
                </div>

                <button
                    className={`relative bg-gradient-to-r from-red-600 to-red-500 text-white px-6 py-3 rounded-lg shadow-lg 
                            hover:from-red-500 hover:to-red-400 transition-all duration-300 ease-in-out 
                            ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105'}`}
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                >
                    <span className="absolute inset-0 bg-red-700 rounded-lg opacity-0 transition-opacity duration-300 ease-in-out"></span>
                    <span className="relative">التالي</span>
                </button>
            </div>

            <div className="flex justify-end mt-4">
                <button
                    className="bg-green-600 text-white px-4 py-2 rounded"
                    onClick={handlePrint}
                >
                    طباعة
                </button>
            </div>

            <div className="absolute bottom-0 right-0 p-4 hidden w-full print:block">
                <div className='flex justify-between gap-x-8'>
                    <p className="text-right text-2xl">توقيع المورد</p>
                    <p className="text-right text-2xl">توقيع أمين الصندوق</p>
                </div>
            </div>
        </div>
    );
};

export default IncomePage;
