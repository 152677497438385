import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../imgs/logo.png"
const AddPenalety = () => {
    const [names, setNames] = useState('');
    const [id, setId] = useState('');
    const [penaletyExpense, setPenaletyExpense] = useState('');
    const [penaletyDate, setPenaletyDate] = useState('');
    const [reason, setReason] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate('');
    const [loading, setLoading] = useState('');

    const getData = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}salary`)
            .then((res) => setNames(res.data.data))
            .catch((err) => console.log(err));
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') navigate('/login');
        } catch (err) {
            navigate('/login');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}salary/update/${id}`, { penaletyExpense });
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}penalety/add/${id}`, {
                name_id: id,
                PenaletyExpense: penaletyExpense,
                PenaletyDate: penaletyDate,
                reason
            });
            setSuccess("تمت اضافة الجزاء بنجاح");
            setPenaletyDate('');
            setPenaletyExpense('');
            setReason('');
            setError(null);
        } catch (err) {
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        verifyAdmin();
        getData();
    }, []);

    return (
        <div>
            <div className="px-4">
                <Navbar />
            </div>
            <div className="form w-[75%] m-auto">
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">الأسم المختار</label>
                    <select
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        onChange={(e) => setId(e.target.value)}
                    >
                        <option selected>من فضلك اختار الاسم</option>
                        {names && names.map((item) => (
                            <option key={item._id} value={item._id}>{item.name}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">تاريخ الجزاء</label>
                    <input
                        type="date"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={penaletyDate}
                        onChange={(e) => setPenaletyDate(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">قيمة الجزاء</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={penaletyExpense}
                        onChange={(e) => setPenaletyExpense(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4 block print:hidden">
                    <label className="text-xl">سبب الجزاء</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </div>
                {error && <div className="mt-4 text-red-500 text-center">{error}</div>}
                {success && <div className="mt-4 text-green-500 text-center">{success}</div>}
                <div className="submit mt-6 text-center">
                    <button
                        type="submit"
                        className="bg-[#000000] text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        disabled={loading}
                        onClick={handleSubmit}
                    >
                        أضافة جزاء
                    </button>
                </div>
                <div className="submit mt-6 text-center print:hidden">
                    <button
                        type="submit"
                        className="bg-lime-500 text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        onClick={() => window.print()}
                    >
                        طباعة
                    </button>
                </div>
                {names &&
                    <div className="print:block hidden mt-10 border-2 p-14">
                        <div className="my-4">
                            <img src={logo} alt="logo" className="w-32 h-32" />
                        </div>
                        <h2 className="text-3xl text-center font-bold mb-6">جزاء</h2>
                        <p className="text-lg mb-6 leading-loose">
                            الي ابينا القمص مدبر الكنيسة \........................<br />
                            تحية طيبة و بعد <br />
                            الرجاء من قدسكم اعتماد هذا الجزاء بقيمة {penaletyExpense} جنيها
                        </p>
                        <h2 className="text-2xl font-bold mb-4">تفاصيل الجزاء</h2>
                        <p className="text-xl mb-4">أسم الحاصل علي الجزاء: {names.find(item => item._id === id)?.name}</p>
                        <p className="text-xl mb-4">تاريخ طلب الجزاء: {penaletyDate}</p>
                        <p className="text-xl mb-4">قيمة الجزاء: {penaletyExpense}</p>
                        <p className="text-xl mb-4">سبب الجزاء: {reason}</p>
                        <div className="flex justify-between items-center border-t pt-4">
                            <p className="text-xl">توقيع الحسابات</p>
                            <p className="text-xl">توقيع مدبر الكنيسة</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default AddPenalety;
