import Swal from "sweetalert2";
import React, { useState } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar";

const AddIncome = () => {
    const [formData, setFormData] = useState({
        income: "",
        incomeType: "",
        details: "",
    });

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const incomeResponse = await axios.post(`${process.env.REACT_APP_HOST_SERVER}income`, formData);
            const incomeId = incomeResponse.data._id;
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}family-box`, {
                incomeAmount: incomeId,
                amountType: 'ايراد',
                details: formData.details,
            });
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم اضافة ايراد بنجاح`,
            });

            showSuccessAlert("تمت الاضافة بنجاح");
            setFormData({
                income: "",
                incomeType: "",
                details: "",
            });
        } catch (error) {
            console.error(error);
            showErrorAlert("حدث خطأ اثناء الاضافة");
        }
    };

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">اضف ايراد جديد</h1>
            <div className="max-w-4xl mx-auto p-8 bg-white rounded-xl shadow-lg">
                <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                        <label htmlFor="income" className="block text-lg font-medium mb-2">مبلغ الايراد</label>
                        <input
                            type="number"
                            id="income"
                            name="income"
                            value={formData.income}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div>
                        <label htmlFor="incomeType" className="block text-lg font-medium mb-2">نوع الايراد</label>
                        <input
                            type="text"
                            id="incomeType"
                            name="incomeType"
                            value={formData.incomeType}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div className="col-span-1 md:col-span-2">
                        <label htmlFor="details" className="block text-lg font-medium mb-2">التفاصيل</label>
                        <textarea
                            id="details"
                            name="details"
                            value={formData.details}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div className="col-span-1 md:col-span-2">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white text-lg font-semibold py-3 rounded-lg"
                        >
                            أضافة ايراد
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddIncome;