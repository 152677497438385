import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddWeddingConstants = () => {
    const [minutes, setMinutes] = useState("");
    const [coupon, setCoupon] = useState("");
    const [fatherPlessingMinutes, setFatherPlessingMinutes] = useState("");
    const [fatherPlessingCoupon, setFatherPlessingCoupon] = useState("");
    const [assistantPlessingMinutes, setAssistantPlessingMinutes] = useState("");
    const [assistantPlessingCoupon, setAssistantPlessingCoupon] = useState("");
    const [loading, setLoading] = useState(false);
    const [existingId, setExistingId] = useState(null);
    const navigate = useNavigate();

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== "خزنة") {
                navigate("/login");
            }
        } catch {
            navigate("/login");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const payload = {
                minutes,
                coupon,
                fatherPlessingMinutes,
                assistantPlessingMinutes,
                fatherPlessingCoupon,
                assistantPlessingCoupon,
            };
            if (existingId) {
                await axios.put(`${process.env.REACT_APP_HOST_SERVER}wedding-constants/${existingId}`, payload);
                showSuccessAlert("تم تحديث الثوابت بنجاح");
            } else {
                await axios.post(`${process.env.REACT_APP_HOST_SERVER}wedding-constants`, payload);
                showSuccessAlert("تمت إضافة الثوابت بنجاح");
            }
            setMinutes("");
            setCoupon("");
            setFatherPlessingMinutes("");
            setFatherPlessingCoupon("");
            setAssistantPlessingMinutes("");
            setAssistantPlessingCoupon("");
            fetchWeddingConstants();
        } catch (err) {
            showErrorAlert(err.response?.data?.errors || [err.response?.data?.message || "حدث خطأ ما تأكد من صحة البيانات"]);
            console.log(err)
        } finally {
            setLoading(false);
        }
    };

    const fetchWeddingConstants = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}wedding-constants/`);
            if (res.data.length > 0) {
                const constants = res.data[0];
                setExistingId(constants._id);
                setMinutes(constants.minutes);
                setCoupon(constants.coupon);
                setFatherPlessingMinutes(constants.fatherPlessingMinutes);
                setAssistantPlessingMinutes(constants.assistantPlessingMinutes);
                setFatherPlessingCoupon(constants.fatherPlessingCoupon);
                setAssistantPlessingCoupon(constants.assistantPlessingCoupon);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchWeddingConstants();
    }, []);

    useEffect(() => {
        verifyAdmin();
    }, []);

    return (
        <div>
            <Navbar />
            <div className="container m-auto">
                <div className="form space-y-3">
                    <h1 className="text-center text-3xl">أضافة ثوابت الافراح</h1>
                    <div className="form-group">
                        <label className="text-xl font-medium me-4">المحضر</label>
                        <input
                            type="number"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setMinutes(e.target.value)}
                            value={minutes}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium me-4">القسيمة</label>
                        <input
                            type="number"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setCoupon(e.target.value)}
                            value={coupon}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium">بركة الكاهن (المحضر)</label>
                        <input
                            type="number"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setFatherPlessingMinutes(e.target.value)}
                            value={fatherPlessingMinutes}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium">بركة الكاهن (القسيمة)</label>
                        <input
                            type="number"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setFatherPlessingCoupon(e.target.value)}
                            value={fatherPlessingCoupon}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium">بركة المعلم (المحضر)</label>
                        <input
                            type="number"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setAssistantPlessingMinutes(e.target.value)}
                            value={assistantPlessingMinutes}
                        />
                    </div>
                    <div className="form-group">
                        <label className="text-xl font-medium">بركة المعلم (القسيمة)</label>
                        <input
                            type="number"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            onChange={(e) => setAssistantPlessingCoupon(e.target.value)}
                            value={assistantPlessingCoupon}
                        />
                    </div>
                    <div className="text-center py-3">
                        <button
                            disabled={loading}
                            onClick={handleSubmit}
                            className="bg-lime-500 text-white text-xl px-6 py-2 rounded-lg hover:bg-lime-300 duration-150"
                        >
                            {existingId ? "تحديث" : "أضافة"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddWeddingConstants;
