import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import Pagination from '../component/Pagination';

const AddictFamilyTable = () => {
    const [addictFamilies, setAddictFamilies] = useState([]);
    const [editingAddictFamily, setEditingAddictFamily] = useState(null);
    const [editForm, setEditForm] = useState({
        AddictFamilyResponsible: '',
        AddictFamilyResponsiblePhone: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const fetchAddictFamilies = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict-family`);
            setAddictFamilies(response.data);
        } catch (error) {
            console.error('Error fetching addict families:', error);
        }
    };

    const deleteAddictFamily = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف هذه الأسرة بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}addict-family/${id}`);
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                        Notifaction: `تم حذف أسرة الحالة بنجاح`,
                    });
                    Swal.fire('تم الحذف!', 'تم حذف الأسرة بنجاح.', 'success');
                    fetchAddictFamilies();
                } catch (err) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء الحذف.', 'error');
                }
            }
        });
    };

    const handleEditClick = (addictFamily) => {
        setEditingAddictFamily(addictFamily._id);
        setEditForm({
            AddictFamilyResponsible: addictFamily.AddictFamilyResponsible,
            AddictFamilyResponsiblePhone: addictFamily.AddictFamilyResponsiblePhone,
        });
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditForm({ ...editForm, [name]: value });
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}addict-family/${editingAddictFamily}`, editForm);
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم تحديث أسرة الحالة بنجاح`,
            });
            Swal.fire('تم التحديث!', 'تم تحديث البيانات بنجاح.', 'success');
            setEditingAddictFamily(null);
            fetchAddictFamilies();
        } catch (error) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء التحديث.', 'error');
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginatedAddictFamilies = addictFamilies.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(addictFamilies.length / itemsPerPage);

    useEffect(() => {
        fetchAddictFamilies();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="container mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">قائمة أسر الحالات</h1>
                <div className="flex justify-end mb-4">
                    <Link to={'/addiction/add/addictFamily'}>
                        <button
                            onClick={() => setEditingAddictFamily(null)}
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none"
                        >
                            أضف اسرة جديدة
                        </button>
                    </Link>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-3">الإسم المسؤول</th>
                                <th className="px-4 py-3">رقم الهاتف</th>
                                <th className="px-4 py-3">الإجراءات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedAddictFamilies.map((addictFamily) => (
                                <tr key={addictFamily._id} className="border-b hover:bg-gray-100">
                                    <td className="px-4 py-3 text-center">{addictFamily.AddictFamilyResponsible}</td>
                                    <td className="px-4 py-3 text-center">{addictFamily.AddictFamilyResponsiblePhone}</td>
                                    <td className="px-4 py-3 flex justify-center space-x-2">
                                        <button
                                            onClick={() => handleEditClick(addictFamily)}
                                            className="bg-yellow-500 me-3 text-white py-1 px-3 rounded hover:bg-yellow-600 focus:outline-none"
                                        >
                                            تعديل
                                        </button>
                                        <button
                                            onClick={() => deleteAddictFamily(addictFamily._id)}
                                            className="bg-red-500 ms-3 text-white py-1 px-3 rounded hover:bg-red-600 focus:outline-none"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {editingAddictFamily && (
                    <div className="mt-8 bg-white p-6 rounded-xl shadow-lg">
                        <h2 className="text-2xl font-bold mb-4">تعديل بيانات الأسرة</h2>
                        <form onSubmit={handleEditSubmit}>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block mb-2 font-medium">الإسم المسؤول</label>
                                    <input
                                        type="text"
                                        name="AddictFamilyResponsible"
                                        value={editForm.AddictFamilyResponsible}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div>
                                    <label className="block mb-2 font-medium">رقم الهاتف</label>
                                    <input
                                        type="text"
                                        name="AddictFamilyResponsiblePhone"
                                        value={editForm.AddictFamilyResponsiblePhone}
                                        onChange={handleEditChange}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end mt-4">
                                <button
                                    type="button"
                                    onClick={() => setEditingAddictFamily(null)}
                                    className="bg-gray-400 text-white py-2 px-4 rounded mr-2 hover:bg-gray-500"
                                >
                                    إلغاء
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                                >
                                    حفظ التعديلات
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default AddictFamilyTable;