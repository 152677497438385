import Swal from "sweetalert2";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ServedSalary = () => {
    const [salaries, setSalaries] = useState([]);
    const [filteredSalaries, setFilteredSalaries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const [nameFilter, setNameFilter] = useState('');
    const [departementFilter, setDepartementFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState('');
    const [endDateFilter, setEndDateFilter] = useState('');
    const [totalSalary, setTotalSalary] = useState(0);
    const [loading, setLoading] = useState('')

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const fetchSalaries = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}served/salary/`);
            setSalaries(response.data);
            setFilteredSalaries(response.data);
            setTotalSalary(response.data.reduce((acc, salary) => acc + salary.Salary, 0));
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmitExpenses = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (!departementFilter) {
                const result = await Swal.fire({
                    title: 'هل انت متأكد من انك تريد تحويل الحميع و ليس ادارة ادارة ؟',
                    text: "تحويل جميع الادارات معا",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'نعم، حولهم!',
                    cancelButtonText: 'إلغاء'
                });

                if (result.isConfirmed) {
                    try {
                        await axios.post(`${process.env.REACT_APP_HOST_SERVER}locker/expenses`, {
                            place: "6723671b6bbe5b2610b901f5",
                            type: "جميع الادارات",
                            price: totalSalary,
                            accepted: "yes"
                        });
                        showSuccessAlert('تم التحويل بنجاح');
                    } catch (err) {
                        showErrorAlert(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
                    }
                    return;
                }
            } else {
                const result = await Swal.fire({
                    title: `هل انت متأكد من انك تريد تحويل مبلغ ${totalSalary.toLocaleString()} لادارة ${departementFilter}`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'نعم، حولهم!',
                    cancelButtonText: 'إلغاء'
                });

                if (result.isConfirmed) {
                    try {
                        await axios.post(`${process.env.REACT_APP_HOST_SERVER}locker/expenses`, {
                            place: "6723671b6bbe5b2610b901f5",
                            type: " ادارة" + departementFilter,
                            price: totalSalary,
                            accepted: "yes"
                        });
                        showSuccessAlert('تم التحويل بنجاح');
                    } catch (err) {
                        showErrorAlert(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
                    }
                }
            }
        } catch (err) {
            showErrorAlert(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchSalaries();
    }, []);

    const handleSearch = () => {
        let filtered = salaries;

        if (nameFilter) {
            filtered = filtered.filter((salary) =>
                salary.Name && salary.Name.name.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }

        if (departementFilter) {
            filtered = filtered.filter((salary) =>
                salary.Departement && salary.Departement.toLowerCase().includes(departementFilter.toLowerCase())
            );
        }

        if (startDateFilter) {
            filtered = filtered.filter((salary) => salary.date >= startDateFilter);
        }

        if (endDateFilter) {
            filtered = filtered.filter((salary) => salary.date <= endDateFilter);
        }

        setFilteredSalaries(filtered);
        setTotalSalary(filtered.reduce((acc, salary) => acc + salary.Salary, 0));
        setCurrentPage(1);
    };


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const uniqueDepartments = [...new Set(salaries.map((salary) => salary.Departement))];


    const paginatedData = filteredSalaries.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredSalaries.length / itemsPerPage);

    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, []);

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}served/salary/delete/${id}`);
                    fetchSalaries();
                    showSuccessAlert('تم الحذف بنجاح');
                } catch (err) {
                    console.error(err);
                }
            }
        });
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8 text-center">سجلات الرواتب المقدمة</h1>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
                <input
                    type="text"
                    placeholder="البحث بالاسم"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                />
                <select
                    value={departementFilter}
                    onChange={(e) => setDepartementFilter(e.target.value)}
                    className="border-gray-300 rounded-md shadow-sm py-2 px-4"
                >
                    <option selected value="">الادارات</option>
                    {uniqueDepartments.map((department) => (
                        <option key={department} value={department}>
                            {department}
                        </option>
                    ))}
                </select>
                <input
                    type="date"
                    placeholder="تاريخ البدء"
                    value={startDateFilter}
                    onChange={(e) => setStartDateFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <input
                    type="date"
                    placeholder="تاريخ الانتهاء"
                    value={endDateFilter}
                    onChange={(e) => setEndDateFilter(e.target.value)}
                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
            </div>

            <button
                onClick={handleSearch}
                className="mb-6 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg shadow-lg hover:bg-indigo-700"
            >
                بحث
            </button>

            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-lg rounded-lg">
                    <thead className="bg-indigo-700 text-white">
                        <tr>
                            <th className="py-4 px-6 text-right">التاريخ</th>
                            <th className="py-4 px-6 text-right">الاسم</th>
                            <th className="py-4 px-6 text-right">الادارة</th>
                            <th className="py-4 px-6 text-left">الراتب</th>
                            <th className="py-4 px-6 text-left">إجراءات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData.length > 0 ? (
                            paginatedData.map((salary, index) => (
                                <tr key={index} className="border-b even:bg-gray-100">
                                    <td className="py-4 px-6 text-right">{salary.date ? salary.date.slice(0, 10) : 'N/A'}</td>
                                    <td className="py-4 px-6 text-right">{salary.Name ? salary.Name.name : 'N/A'}</td>
                                    <td className="py-4 px-6 text-right">{salary.Departement || 'N/A'}</td>
                                    <td className="py-4 px-6 text-left">{salary.Salary.toLocaleString()}</td>
                                    <td className="py-4 px-6 text-left">
                                        <button
                                            onClick={() => handleDelete(salary._id)}
                                            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="py-4 text-center">لم يتم العثور على سجلات</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="mt-6 flex justify-center items-center space-x-4">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 mx-4 rounded-lg ${currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-indigo-600 text-white hover:bg-indigo-500"}`}
                >
                    السابق
                </button>
                <div className="flex items-center space-x-2">
                    <input
                        type="number"
                        min="1"
                        max={totalPages}
                        value={currentPage}
                        onChange={(e) => handlePageChange(Number(e.target.value))}
                        className="w-14 text-center border rounded-lg px-2 py-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <span className="text-sm font-semibold text-gray-700">/ {totalPages}</span>
                </div>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`px-4 py-2 rounded-lg ${currentPage === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-indigo-600 text-white hover:bg-indigo-500"}`}
                >
                    التالي
                </button>
            </div>

            <div className="mt-6 text-center">
                <p className="text-lg font-bold">إجمالي الرواتب: {totalSalary.toLocaleString()} جنيها فقط</p>
            </div>
            {
                startDateFilter && endDateFilter ?
                    <div className="mt-6 text-center">
                        <button
                            loading={loading}
                            onClick={handleSubmitExpenses}
                            className="px-6 py-3 rounded-xl bg-lime-500 text-white text-xl hover:bg-lime-300 duration-150">تحويل الي الخزنة</button>
                    </div> : ''
            }
        </div>
    );
};

export default ServedSalary;
