import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";

const Weddings = () => {
    const [weddings, setWeddings] = useState([]);
    const [filteredWeddings, setFilteredWeddings] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [father, setFather] = useState("");
    const [assistant, setAssistant] = useState("");
    const [totals, setTotals] = useState({ fatherPlessing: 0, assistantPlessing: 0, minutes: 0, coupon: 0 });


    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const fetchWeddings = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}wedding`);
            setWeddings(res.data);
            setFilteredWeddings(res.data);
            calculateTotals(res.data);
        } catch (err) { }
    };

    const calculateTotals = (data) => {
        const totalFatherPlessing = data.reduce((sum, w) => sum + w.fatherPlessing, 0);
        const totalAssistantPlessing = data.reduce((sum, w) => sum + w.assistantPlessing, 0);
        const totalMinutes = data.reduce((sum, w) => sum + w.minutes, 0);
        const totalCoupon = data.reduce((sum, w) => sum + w.coupon, 0);
        setTotals({ fatherPlessing: totalFatherPlessing, assistantPlessing: totalAssistantPlessing, minutes: totalMinutes, coupon: totalCoupon });
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: "هل أنت متأكد؟",
            text: "لن تتمكن من التراجع عن هذا!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "نعم، احذفها!",
            cancelButtonText: "إلغاء",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}wedding/${id}`);
                    showSuccessAlert("تم الحذف بنجاح");
                    fetchWeddings();
                } catch (err) { 
                    showErrorAlert("حدث خطأ ما");
                }
            }
        });
    };

    const filterData = () => {
        let filtered = weddings;
        if (startDate) filtered = filtered.filter((w) => new Date(w.date) >= new Date(startDate));
        if (endDate) filtered = filtered.filter((w) => new Date(w.date) <= new Date(endDate));
        if (father) filtered = filtered.filter((w) => w.father.includes(father));
        if (assistant) filtered = filtered.filter((w) => w.assistant.includes(assistant));
        setFilteredWeddings(filtered);
        calculateTotals(filtered);
    };

    const navigate = useNavigate();

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== "خزنة") navigate("/login");
        } catch {
            navigate("/login");
        }
    };

    useEffect(() => {
        verifyAdmin();
        fetchWeddings();
    }, []);

    return (
        <div>
            <Navbar />
            <div className="container mx-auto p-6">
                <h1 className="text-center text-4xl font-bold mb-8 text-blue-700">صفحة الأفراح</h1>
                <div className="shadow-md rounded-lg p-6 space-y-4 mb-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div>
                            <label className="block text-gray-600 font-medium mb-1">من تاريخ</label>
                            <input
                                type="date"
                                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-400"
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block text-gray-600 font-medium mb-1">إلى تاريخ</label>
                            <input
                                type="date"
                                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-400"
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block text-gray-600 font-medium mb-1">الكاهن</label>
                            <input
                                type="text"
                                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-400"
                                onChange={(e) => setFather(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="block text-gray-600 font-medium mb-1">المعلم</label>
                            <input
                                type="text"
                                className="w-full border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-400"
                                onChange={(e) => setAssistant(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            onClick={filterData}
                            className="bg-blue-500 hover:bg-blue-400 text-white font-medium px-6 py-2 rounded-lg transition duration-200"
                        >
                            تصفية
                        </button>
                    </div>
                </div>
                <table className="w-full border-collapse border border-gray-300 rounded-lg shadow-md overflow-hidden">
                    <thead className="bg-blue-500 text-white">
                        <tr>
                            <th className="border px-4 py-2">الاسم</th>
                            <th className="border px-4 py-2">التاريخ</th>
                            <th className="border px-4 py-2">الكنيسة</th>
                            <th className="border px-4 py-2">المحضر</th>
                            <th className="border px-4 py-2">القسيمة</th>
                            <th className="border px-4 py-2">الكاهن</th>
                            <th className="border px-4 py-2">المعلم</th>
                            <th className="border px-4 py-2">البركة الاباء</th>
                            <th className="border px-4 py-2">البركة المعلمين</th>
                            <th className="border px-4 py-2">إجراءات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredWeddings.map((w) => (
                            <tr key={w._id} className="hover:bg-gray-100 text-center">
                                <td className="border px-4 py-2">{w.name}</td>
                                <td className="border px-4 py-2">{new Date(w.date).toLocaleDateString()}</td>
                                <td className="border px-4 py-2">{w.church}</td>
                                <td className="border px-4 py-2">{w.minutes}</td>
                                <td className="border px-4 py-2">{w.coupon}</td>
                                <td className="border px-4 py-2">{w.father}</td>
                                <td className="border px-4 py-2">{w.assistant}</td>
                                <td className="border px-4 py-2">{w.fatherPlessing}</td>
                                <td className="border px-4 py-2">{w.assistantPlessing}</td>
                                <td className="border px-4 py-2">
                                    <button
                                        onClick={() => handleDelete(w._id)}
                                        className="bg-red-500 hover:bg-red-400 text-white px-6 py-1 rounded-lg transition duration-200"
                                    >
                                        حذف
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="mt-8 text-gray-700 bg-gray-100 p-6 rounded-lg shadow-md flex justify-around text-xl">
                    <p className="mb-2">إجمالي المحاضر: {totals.minutes}</p>
                    <p>إجمالي القسايم: {totals.coupon}</p>
                    <p className="mb-2">إجمالي البركة للكاهن: {totals.fatherPlessing}</p>
                    <p className="mb-2">إجمالي البركة للمعلم: {totals.assistantPlessing}</p>
                    <p className="mb-2">الباقي من بعد البركات: {(totals.minutes + totals.coupon) - (totals.assistantPlessing + totals.fatherPlessing)}</p>
                </div>
            </div>
        </div>
    );
};

export default Weddings;
