import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar";
import Logo from "../../../imgs/logo.png"
const AddVacation = () => {
    const [workers, setWorkers] = useState([]);
    const [workerID, setWorkerID] = useState("");
    const [worker, setWorker] = useState("");
    const [workerDepartment, setWorkerDepartment] = useState("");
    const [workerJob, setWorkerJob] = useState("");
    const [vacationType, setVacationType] = useState("");
    const [vacationFrom, setVacationFrom] = useState("");
    const [vacationTo, setVacationTo] = useState("");
    const [remainingVacation1, setRemainingVacation1] = useState(0);
    const [remainingVacation2, setRemainingVacation2] = useState(0);
    const [vacation1, setVacation1] = useState(0);
    const [vacation2, setVacation2] = useState(0);

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        }).then(() => {
            window.location.reload()
        });
    };

    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const fetchWorkers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/workers`);
            setWorkers(response.data.workers);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchWorkers();
    }, []);

    const navigate = useNavigate();
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== "خزنة") {
                navigate("/login");
            }
        } catch (err) {
            console.error(err);
            navigate("/login");
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    const calculateDays = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        return Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1;
    };

    const handleWorkerChange = (e) => {
        const selectedWorker = workers.find(w => w.name === e.target.value);
        setWorker(e.target.value);
        setWorkerID(selectedWorker ? selectedWorker._id : "");
        setWorkerDepartment(selectedWorker ? selectedWorker.departement : "");
        setWorkerJob(selectedWorker ? selectedWorker.job : "");
        setRemainingVacation1(selectedWorker ? selectedWorker.regularVacation : 0);
        setRemainingVacation2(selectedWorker ? selectedWorker.casualVacation : 0);
        setVacation1(selectedWorker ? selectedWorker.regularVacation2 : 0);
        setVacation2(selectedWorker ? selectedWorker.casualVacation2 : 0);
    };

    const handleSubmitVacation = async () => {
        if (!worker || !workerDepartment || !workerJob || !vacationType || !vacationFrom || !vacationTo) {
            showRequiredAlert("جميع الحقول مطلوبة");
            return;
        }
        const daysRequested = calculateDays(vacationFrom, vacationTo);
        if (daysRequested <= 0) {
            showErrorAlert('اضف ايام الاجازة بطريقة صحيحة')
            return;
        }
        if (vacationType == "اعتيادية") {
            if (daysRequested > remainingVacation1) {
                showErrorAlert("عدد الأيام يتجاوز المتبقي من الإجازة");
                return;
            }
        }
        if (vacationType == "عارضة") {
            if (daysRequested > remainingVacation2) {
                showErrorAlert("عدد الأيام يتجاوز المتبقي من الإجازة");
                return;
            }
        }
        try {
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}vacation/add`, {
                name: workerID,
                departement: workerDepartment,
                jobTitle: workerJob,
                vacationType,
                vacationFrom,
                vacationTo,
            });
            showSuccessAlert("تم إضافة الإجازة بنجاح");
            setWorker("")
            setWorkerDepartment("")
            setWorkerJob("")
            setVacationType("")
            setRemainingVacation1("")
            setRemainingVacation2("")
            setVacationFrom("");
            setVacationTo("");
        } catch (error) {
            showErrorAlert(error.response?.data?.message || "حدث خطأ أثناء إضافة الإجازة");
        }
    };

    const getFormattedDateInArabic = (date) => {
        if (date) {
            const formattedDate = new Date(date);
            return new Intl.DateTimeFormat('ar-EG', {
                weekday: 'long',
            }).format(formattedDate);
        }
    };
    return (
        <div className="w-[75%] mx-auto print:w-[95%] print:border-4 px-4 py-8">
            <Navbar />
            <h1 className="text-3xl font-bold mb-8 text-center print:hidden">طلب اجازة</h1>
            <div className="print:hidden">
                <div className="grid grid-cols-1 gap-4 mb-6">
                    <select
                        value={worker}
                        onChange={handleWorkerChange}
                        className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                    >
                        <option value="">أختر العامل</option>
                        {workers.map((worker, index) => (
                            <option key={index} value={worker.name}>{worker.name}</option>
                        ))}
                    </select>

                    <input
                        type="text"
                        value={workerDepartment}
                        readOnly
                        className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                        placeholder="الإدارة"
                    />

                    <input
                        type="text"
                        value={workerJob}
                        readOnly
                        className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                        placeholder="الوظيفة"
                    />

                    <select
                        value={vacationType}
                        onChange={(e) => setVacationType(e.target.value)}
                        className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                    >
                        <option value="">أختر نوع الإجازة</option>
                        <option value="اعتيادية">اعتيادية</option>
                        <option value="عارضة">عارضة</option>
                        <option value="مرضية">مرضية</option>
                        <option value="بدون مرتب">بدون مرتب</option>
                    </select>
                    {
                        (vacationType) ?
                            (vacationType === "اعتيادية") ?
                                <input
                                    type="text"
                                    value={remainingVacation1 + " يوم متبقي من الأجازة"}
                                    readOnly
                                    className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                                />
                                : (vacationType === "عارضة") ?
                                    <input
                                        type="text"
                                        value={remainingVacation2 + " يوم متبقي من الأجازة"}
                                        readOnly
                                        className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                                    /> : ''
                            : ''
                    }

                    <label className="text-md">تاريخ بداية الأجازة</label>

                    <input
                        type="date"
                        value={vacationFrom}
                        onChange={(e) => setVacationFrom(e.target.value)}
                        className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                    />

                    <label className="text-md">تاريخ نهاية الأجازة</label>
                    <input
                        type="date"
                        value={vacationTo}
                        onChange={(e) => setVacationTo(e.target.value)}
                        className="p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-right"
                    />
                </div>
                <div className="text-center">
                    <button
                        onClick={handleSubmitVacation}
                        className="mb-6 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg shadow-lg hover:bg-indigo-700"
                    >
                        إضافة الإجازة
                    </button>
                    <br />
                    <button
                        onClick={() => { window.print() }}
                        className="mb-6 px-6 py-2 bg-green-600 text-white font-semibold rounded-lg shadow-lg hover:bg-green-700"
                    >
                        طباعة طلب الأجازة
                    </button>
                </div>
            </div>
            {/* for print  */}
            <div className="hidden print:block p-10">
                <div className="my-4">
                    <img src={Logo} alt="اللوجو" className="w-32 h-32" />
                </div>

                <h1 className="text-3xl font-bold mb-8 text-center underline underline-offset-[10px]">طلب اجازة {vacationType}</h1>
                <div className="text-center mb-4">
                    <p className="text-3xl underline underline-offset-[10px]">إلى قدس ابونا مدير الكنيسة : <b>ابونا مكاريوس جمال</b></p>
                </div>

                <div className="mb-4 mt-4">
                    <p className="text-[24px] underline underline-offset-[10px] my-3">طالب الإجازة : <b>{worker}</b></p>
                    <p className="text-[24px] underline underline-offset-[10px] my-3">الإدارة : <b>{workerDepartment}</b></p>
                    <p className="text-[24px] underline underline-offset-[10px] my-3">الوظيفة : <b>{workerJob}</b></p>
                    {(vacationType === "اعتيادية" || vacationType === "عارضة") ?
                        <p className="text-[24px] underline underline-offset-[10px] my-3">الرجاء و بعد إذن قدسك الموافقة على منحي إجازة من رصيد الإجازة السنوي</p>
                        :
                        <p className="text-[24px] underline underline-offset-[10px] my-3">الرجاء و بعد إذن قدسك الموافقة على منحي إجازة مرضية</p>
                    }
                    <p className="text-[24px] underline underline-offset-[10px] my-3">الإجازة المطلوبة : <b> {calculateDays(vacationFrom, vacationTo)} أيام</b>
                        ({vacationFrom} إلى {vacationTo})
                        من <b>{getFormattedDateInArabic(vacationFrom)}</b> إلى <b>{getFormattedDateInArabic(vacationTo)}</b>
                    </p>
                </div>

                {(vacationType === "اعتيادية" || vacationType === "عارضة") ?
                    <>
                        <p className="text-lg underline underline-offset-[10px] my-2">حيث الرصيد كالأتي : </p>
                        <table className="w-full border border-gray-300 text-center">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="p-2 border">الاعتيادي</th>
                                    <th className="p-2 border">العارضة</th>
                                    <th className="p-2 border">ما تم استخدامه من الاعتيادي</th>
                                    <th className="p-2 border">ما تم استخدامه من العارضة</th>
                                    <th className="p-2 border">الإجازة المطلوبة من الاعتيادي</th>
                                    <th className="p-2 border">الإجازة المطلوبة من العارضة</th>
                                    <th className="p-2 border">الإجازة المتبقية من الاعتيادي</th>
                                    <th className="p-2 border">الإجازة المتبقية من العارضة</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-2 border">{vacation1}</td>
                                    <td className="p-2 border">{vacation2}</td>
                                    <td className="p-2 border">{vacation1 - remainingVacation1}</td>
                                    <td className="p-2 border">{vacation2 - remainingVacation2}</td>
                                    <td className="p-2 border">{vacationType === "اعتيادية" ? calculateDays(vacationFrom, vacationTo) : 0}</td>
                                    <td className="p-2 border">{vacationType === "عارضة" ? calculateDays(vacationFrom, vacationTo) : 0}</td>
                                    <td className="p-2 border">{vacationType === "اعتيادية" ? remainingVacation1 - calculateDays(vacationFrom, vacationTo) : remainingVacation1}</td>
                                    <td className="p-2 border">{vacationType === "عارضة" ? remainingVacation2 - calculateDays(vacationFrom, vacationTo) : remainingVacation2}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                    : ''}

                <div className="mt-8">
                    <div className="">
                        <p>اسم القائم بالأعمال:</p>
                    </div>
                    <div className="mt-6">
                        <p>التوقيع:</p>
                    </div>
                    <div className="flex justify-between mt-6">
                        <p>توقيع طالب الإجازة:</p>
                        <p>توقيع مشرف الإدارة:</p>
                        <p>الحسابات:</p>
                    </div>
                    <div className="flex justify-between mt-8">
                        <p>مدير الكنيسة:</p>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default AddVacation;
