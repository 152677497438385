import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import axios from "axios";
import { useState, useEffect } from "react";

const Dashboard = () => {
    const navigate = useNavigate();
    const [allCasesCount, setAllCasesCount] = useState('')
    const [currentCasesCount, setCurrentCasesCount] = useState('')
    const [recoveredCasesCount, setRecoveredCasesCount] = useState('')
    const [notifications, setNotifications] = useState('')
    const [sanatoriums, setSanatoriums] = useState('')
    const [categories, setCategories] = useState('')
    const [expense, setExpense] = useState('')
    const [addictForms, setAddictForms] = useState('')
    const fetchAddicts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict`);
            const allAddicts = response.data;
            const currentDate = new Date();

            const allCasesCount = allAddicts.length;
            const currentCasesCount = allAddicts.filter(
                addict => !addict.addictExitDate || new Date(addict.addictExitDate) > currentDate
            ).length;
            const recoveredCasesCount = allAddicts.filter(
                addict => addict.addictExitDate && new Date(addict.addictExitDate) <= currentDate
            ).length;

            setAllCasesCount(allCasesCount)
            setCurrentCasesCount(currentCasesCount);
            setRecoveredCasesCount(recoveredCasesCount)
        } catch (error) {
            console.error('Error fetching addicts:', error);
        }
    };

    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}notification`);
            await setNotifications(response.data.slice(-3).reverse())
        } catch (error) {
            console.error('Error fetching notifications:', error);
            return [];
        }
    };

    const fetchSanatorium = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}sanatorium`);
            await setSanatoriums(response.data.length)
        } catch (error) {
            console.error('Error fetching notifications:', error);
            return [];
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}family-category`);
            await setCategories(response.data.length)
        } catch (error) {
            console.error('Error fetching Categoris:', error);
            return [];
        }
    };

    const fetchExpesnes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}family-expense`);
            await setExpense(response.data.length)
        } catch (error) {
            console.error('Error fetching Categoris:', error);
            return [];
        }
    };

    const fetchAddictForms = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict-form`);
            const filteredForms = response.data.filter(
                (form) => form?.addict && form?.endDate && new Date(form.endDate) > new Date()
            );
            setAddictForms(filteredForms.length);
        } catch (error) {
            console.error('Error fetching addict forms:', error);
            setAddictForms(0);
        }
    };


    const deleteNotification = async (notificationId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_HOST_SERVER}notification/${notificationId}`);
            fetchNotifications();
        } catch (error) {
            console.error('Error deleting notification:', error);
        }
    };

    const extraItmes = [
        { label: "اسر الحالات", path: "addictFamily", color: "bg-teal-600" },
        { label: "متبرعين الحالات", path: "addictDonor", color: "bg-cyan-600" },
        { label: "المتابعات المالية", path: "followUp", color: "bg-blue-600" },
        { label: "الايرادات", path: "incomes", color: "bg-cyan-600" },
        { label: "الايصالات", path: "receipt", color: "bg-teal-600" },
    ]

    const navigationItems = [
        { label: "عدد الحالات الاجمالية بالخدمة", count: allCasesCount, color: "bg-blue-500", path: "addicts/all" },
        { label: "عدد الحالات الحالية بالمصحة", count: addictForms, color: "bg-green-500", path: "addictform" },
        { label: "عدد الحالات المتعافية", count: recoveredCasesCount, color: "bg-red-500", path: "addicts/recovered" },
        { label: "انواع المصحات", count: sanatoriums, color: "bg-yellow-500", path: "sanatoriums" },
        { label: "فئات مصاريف الخدمة", count: categories, color: "bg-orange-500", path: "category" },
        { label: "جدول المصروفات الخدمة", count: expense, color: "bg-blue-500", path: "expenses" },
        { label: "صندوق الخدمة", count: "التفاصيل بالداخل", color: "bg-black", path: "family-box" },
    ];

    const actionButtons = [
        { label: "إضافة حالة جديد", color: "bg-indigo-500", path: "add/addict" },
        { label: "إضافة مصحة جديد", color: "bg-lime-500", path: "add/sanatorium" },
        { label: "إضافة حالة جديدة للمصحة", color: "bg-gray-500", path: "add/addictform" },
        { label: "إضافة فئة لمصاريف الخدمة", color: "bg-red-500", path: "add/familyCategory" },
        { label: "إضافة مصاريف الخدمة", color: "bg-yellow-500", path: "add/expense" },
        { label: "إضافة اسرة للحالة", color: "bg-blue-500", path: "add/addictFamily" },
        { label: "إضافة متبرع للحالة", color: "bg-orange-500", path: "add/addictDonor" },
        { label: "إضافة متابعة مالية", color: "bg-black", path: "add/followUp" },
        { label: "إضافة مصروفات لأستمارة الشهرية", color: "bg-yellow-500", path: "add/formExpense" },
        { label: "إضافة ايرادات للخدمة", color: "bg-indigo-500", path: "add/income" },
        { label: "إضافة ايصال", color: "bg-yellow-500", path: "add/receipt" },
    ];

    useEffect(() => {
        fetchAddicts();
        fetchNotifications();
        fetchSanatorium();
        fetchCategories();
        fetchExpesnes();
        fetchAddictForms();
    }, []);

    return (
        <div className="px-4">
            <Navbar />
            <div className="container m-auto mt-10">
                <h1 className="text-center text-4xl font-medium mb-8">لوحة التحكم</h1>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                    {navigationItems.map((item, index) => (
                        <div
                            key={index}
                            className={`${item.color} text-white rounded-lg shadow-lg flex flex-col justify-between p-6`}
                        >
                            <h2 className="text-2xl font-semibold">{item.label}</h2>
                            <p className="text-5xl font-bold mt-4">{item.count}</p>
                            <button
                                onClick={() => navigate(item.path)}
                                className="mt-6 bg-white text-black py-2 rounded-lg hover:bg-opacity-90"
                            >
                                عرض التفاصيل
                            </button>
                        </div>
                    ))}
                </div>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
                        <h2 className="text-xl font-medium mb-4">الإشعارات</h2>
                        <ul className="space-y-3">
                            {notifications && notifications.map((notification, index) => (
                                <li
                                    key={index}
                                    className="p-3 bg-white rounded-lg shadow-md hover:bg-lime-500 duration-150 hover:cursor-pointer"
                                    onClick={() => deleteNotification(notification._id)}
                                >
                                    {notification.Notifaction}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
                        <h2 className="text-xl font-medium mb-4">أحدث النشاطات</h2>
                        <ul className="space-y-3">
                            <li className="p-3 bg-white rounded-lg shadow-md hover:bg-lime-600 duration-150">يتم الان العمل علي صندوق الخزنة (اضافة ايرادات و اي مصروفات تنقص منه)</li>
                            <li className="p-3 bg-white rounded-lg shadow-md hover:bg-lime-600 duration-150">تمت اضافة الايرادات</li>
                        </ul>
                    </div>
                </div>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-6">
                    {actionButtons.map((button, index) => (
                        <button
                            key={index}
                            onClick={() => navigate(button.path)}
                            className={`p-6 ${button.color} text-white rounded-lg shadow-lg hover:bg-opacity-90 transition duration-200`}
                        >
                            {button.label}
                        </button>
                    ))}
                </div>
                <div className="mt-10 p-6 bg-gradient-to-r from-teal-400 via-cyan-500 to-blue-600 rounded-lg shadow-lg text-white">
                    <h2 className="text-3xl font-semibold mb-4">جداول اخرى</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                        {extraItmes.map((item, index) => (
                            <div key={index} className={`${item.color} p-4 rounded-lg shadow-lg text-white`}>
                                <h3 className="text-xl font-medium mb-2">{item.label}</h3>
                                <button
                                    onClick={() => navigate(item.path)}
                                    className="bg-white text-black py-2 px-4 rounded-lg mt-4 hover:bg-opacity-90"
                                >
                                    عرض التفاصيل
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Dashboard;
