import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';

const AddAdictForm = () => {
    const [formData, setFormData] = useState({
        addict: '',
        responsibleServer: '',
        sanatorium: '',
        delviryExpensee: 0,
        delviryExpenseeresponsible: '',
        delviryExpenseeresponsiblePhone: '',
        residenceExpense: 0,
        personalExpense: 0,
        medicalExpense: 0,
        cigiratesExpense: 0,
        medicalTestsExpenes: 0,
        xraysExpense: 0,
        kantennExpense: 0,
        otherExpenses: 0,
        therapySessionExpense: 0,
        DetoksExpense: 0,
        extramedicineExpense: 0,
        extramedicineTestsandxraysExpense: 0,
        goBackExpense: 0,
        changePlaceExpense: 0,
        startDate: '',
        endDate: ''
    });
    const [addicts, setAddicts] = useState([]);
    const [sanatoriums, setSanatoriums] = useState([]);
    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict-form`);
                setData(response.data);
                const response2 = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict`);
                const allAddicts = response2.data;
                const existingAddicts = response.data.map(item => item.addict?.addictName);
                const newAddicts = allAddicts.filter(addict => !existingAddicts.includes(addict.addictName));
                setAddicts(newAddicts);
            } catch (error) {
                Swal.fire('خطأ!', 'حدث خطأ أثناء جلب البيانات.', 'error');
            }
        };

        const fetchSanatoriums = async () => {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}sanatorium`);
            setSanatoriums(response.data);
        };
    
        fetchData();
        fetchSanatoriums();
    }, []);

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAddictChange = (e) => {
        const selectedAddict = addicts.find(addict => addict._id === e.target.value);
        if (selectedAddict) {
            setFormData({
                ...formData,
                addict: selectedAddict._id,
                responsibleServer: selectedAddict.addictResponsibleServer
            });
        } else {
            setFormData({ ...formData, addict: e.target.value });
        }
    };

    const handleSanatoriumChange = (e) => {
        const selectedSanatorium = sanatoriums.find(sanatorium => sanatorium._id === e.target.value);
        if (selectedSanatorium) {
            setFormData({
                ...formData,
                sanatorium: selectedSanatorium._id,
                delviryExpensee: selectedSanatorium.delviryExpensee,
                delviryExpenseeresponsible: selectedSanatorium.delviryExpenseeresponsible,
                delviryExpenseeresponsiblePhone: selectedSanatorium.delviryExpenseeresponsiblePhone,
                residenceExpense: selectedSanatorium.residenceExpense,
                personalExpense: selectedSanatorium.personalExpense,
                medicalExpense: selectedSanatorium.medicalExpense,
                cigiratesExpense: selectedSanatorium.cigiratesExpense,
                medicalTestsExpenes: selectedSanatorium.medicalTestsExpenes,
                xraysExpense: selectedSanatorium.xraysExpense,
                kantennExpense: selectedSanatorium.kantennExpense,
                otherExpenses: selectedSanatorium.otherExpenses,
                therapySessionExpense: selectedSanatorium.therapySessionExpense,
                DetoksExpense: selectedSanatorium.DetoksExpense,
                extramedicineExpense: selectedSanatorium.extramedicineExpense,
                extramedicineTestsandxraysExpense: selectedSanatorium.extramedicineTestsandxraysExpense,
                goBackExpense: selectedSanatorium.goBackExpense,
                changePlaceExpense: selectedSanatorium.changePlaceExpense
            });
        } else {
            setFormData({ ...formData, sanatorium: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}addict-form`, formData);
            await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                Notifaction: `تم اضافة حالة جديدة للمصحة بنجاح`,
            });
            showSuccessAlert('تمت الإضافة بنجاح');
            setFormData({
                addict: '',
                responsibleServer: '',
                sanatorium: '',
                delviryExpensee: '',
                delviryExpenseeresponsible: '',
                delviryExpenseeresponsiblePhone: '',
                residenceExpense: '',
                personalExpense: '',
                medicalExpense: '',
                cigiratesExpense: '',
                medicalTestsExpenes: '',
                xraysExpense: '',
                kantennExpense: '',
                otherExpenses: '',
                therapySessionExpense: '',
                DetoksExpense: '',
                extramedicineExpense: '',
                extramedicineTestsandxraysExpense: '',
                goBackExpense: '',
                changePlaceExpense: '',
                startDate: '',
                endDate: '',
            });
        } catch (error) {
            if (error.response && error.response.data.error) {
                showErrorAlert(error.response.data.error);
            } else {
                showErrorAlert('حدث خطأ أثناء الإضافة');
            }
        }
    };

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">إضافة حالة جديدة للمصحة</h1>
            <div className="max-w-4xl lg:max-w-7xl mx-auto p-8 bg-white rounded-xl shadow-lg">
                <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    <div>
                        <label htmlFor="addict" className="block text-lg font-medium mb-2">الحالة</label>
                        <select
                            id="addict"
                            name="addict"
                            value={formData.addict}
                            onChange={handleAddictChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="" selected>اختر الحالة</option>
                            {addicts.map((addict) => (
                                <option key={addict._id} value={addict._id}>
                                    {addict.addictName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="sanatorium" className="block text-lg font-medium mb-2">المصحة</label>
                        <select
                            id="sanatorium"
                            name="sanatorium"
                            value={formData.sanatorium}
                            onChange={handleSanatoriumChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="">اختر المصحة</option>
                            {sanatoriums.map((sanatorium) => (
                                <option key={sanatorium._id} value={sanatorium._id}>
                                    {sanatorium.sanatoriumName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="responsibleServer" className="block text-lg font-medium mb-2">اسم الخادم المسؤول</label>
                        <input
                            type="text"
                            id="responsibleServer"
                            name="responsibleServer"
                            value={formData.responsibleServer}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpensee" className="block text-lg font-medium mb-2">تكاليف التوصيل</label>
                        <input
                            type="number"
                            id="delviryExpensee"
                            name="delviryExpensee"
                            value={formData.delviryExpensee}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpenseeresponsible" className="block text-lg font-medium mb-2">المسؤول عن التوصيل</label>
                        <input
                            type="text"
                            id="delviryExpenseeresponsible"
                            name="delviryExpenseeresponsible"
                            value={formData.delviryExpenseeresponsible}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpenseeresponsiblePhone" className="block text-lg font-medium mb-2">هاتف المسؤول عن التوصيل</label>
                        <input
                            type="text"
                            id="delviryExpenseeresponsiblePhone"
                            name="delviryExpenseeresponsiblePhone"
                            value={formData.delviryExpenseeresponsiblePhone}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpenseeresponsiblePhone" className="block text-lg font-medium mb-2">مصاريف الاقامة بالمصحة</label>
                        <input
                            type="number"
                            id="residenceExpense"
                            name="residenceExpense"
                            value={formData.residenceExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="personalExpense" className="block text-lg font-medium mb-2">المصاريف الشخصية داخل المصحة</label>
                        <input
                            type="number"
                            id="personalExpense"
                            name="personalExpense"
                            value={formData.personalExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="medicalExpense" className="block text-lg font-medium mb-2">مصاريف الدواء</label>
                        <input
                            type="number"
                            id="medicalExpense"
                            name="medicalExpense"
                            value={formData.medicalExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="cigiratesExpense" className="block text-lg font-medium mb-2">مصاريف السجاير</label>
                        <input
                            type="number"
                            id="cigiratesExpense"
                            name="cigiratesExpense"
                            value={formData.cigiratesExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="kantennExpense" className="block text-lg font-medium mb-2">مصاريف الكانتين</label>
                        <input
                            type="number"
                            id="kantennExpense"
                            name="kantennExpense"
                            value={formData.kantennExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="medicalTestsExpenes" className="block text-lg font-medium mb-2">مصاريف التحاليل</label>
                        <input
                            type="number"
                            id="medicalTestsExpenes"
                            name="medicalTestsExpenes"
                            value={formData.medicalTestsExpenes}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="xraysExpense" className="block text-lg font-medium mb-2">مصاريف الاشعة</label>
                        <input
                            type="number"
                            id="xraysExpense"
                            name="xraysExpense"
                            value={formData.xraysExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="otherExpenses" className="block text-lg font-medium mb-2">مصاريف اخري</label>
                        <input
                            type="number"
                            id="otherExpenses"
                            name="otherExpenses"
                            value={formData.otherExpenses}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="therapySessionExpense" className="block text-lg font-medium mb-2">سعر الجلسات العلاجية داخل المصحة</label>
                        <input
                            type="number"
                            id="therapySessionExpense"
                            name="therapySessionExpense"
                            value={formData.therapySessionExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="DetoksExpense" className="block text-lg font-medium mb-2">سعر الديتوكس</label>
                        <input
                            type="number"
                            id="DetoksExpense"
                            name="DetoksExpense"
                            value={formData.DetoksExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="extramedicineExpense" className="block text-lg font-medium mb-2">سعر ادوية زيادة عن المحددة</label>
                        <input
                            type="number"
                            id="extramedicineExpense"
                            name="extramedicineExpense"
                            value={formData.extramedicineExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="extramedicineTestsandxraysExpense" className="block text-lg font-medium mb-2">سعر اشاعات و تحاليل اضافية</label>
                        <input
                            type="number"
                            id="extramedicineTestsandxraysExpense"
                            name="extramedicineTestsandxraysExpense"
                            value={formData.extramedicineTestsandxraysExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="goBackExpense" className="block text-lg font-medium mb-2">مصاريف الذهاب و العودة</label>
                        <input
                            type="number"
                            id="goBackExpense"
                            name="goBackExpense"
                            value={formData.goBackExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="changePlaceExpense" className="block text-lg font-medium mb-2">مصاريف التحويل من مكان الي مكان</label>
                        <input
                            type="number"
                            id="changePlaceExpense"
                            name="changePlaceExpense"
                            value={formData.changePlaceExpense}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="startDate" className="block text-lg font-medium mb-2">تاريخ دخول المصحة</label>
                        <input
                            type="date"
                            id="startDate"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="endDate" className="block text-lg font-medium mb-2">تاريخ خروج المصحة</label>
                        <input
                            type="date"
                            id="endDate"
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="col-span-1 lg:col-span-2">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white text-lg font-semibold py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                        >
                            إضافة الأستمارة
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddAdictForm;