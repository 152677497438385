import Swal from "sweetalert2";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../../imgs/logo.png'

const AdvanceHistory = () => {
    const [advances, setAdvances] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [name,setName] = useState('');
    const [advanceDate,setAdvanceDate] = useState('');
    const [advance,setAdvance] = useState('');
    const [advanceMonthly,setAdvanceMonthly] = useState('');
    const [advanceReason,setAdvanceReason] = useState('');
    const itemsPerPage = 10;
    const navigate = useNavigate('')

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleDelete = async (id, name) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: `هل تريد حذف ${name}؟`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(`${process.env.REACT_APP_HOST_SERVER}advance/delete/${id}`, {
                        data: { name }
                    });
                    showSuccessAlert('تم الحذف بنجاح');
                    fetchAdvances();
                } catch (err) {
                    showErrorAlert('حدث خطأ برجاء مراجعة البيانات');
                    console.log('deletion error', err);
                }
            }
        });
    };


    const fetchAdvances = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}advance`);
            setAdvances(response.data);
        } catch (error) {
            console.error('Error fetching advances:', error);
        }
    };
    useEffect(() => {
        verifyAdmin()
        fetchAdvances();
    }, []);

    const handleCheckToggle = async (id, name) => {
        try {
            const advance = advances.find(a => a._id === id);
            const updatedAdvance = { check: !advance.check, name };

            await axios.put(`${process.env.REACT_APP_HOST_SERVER}advance/update/${id}`, updatedAdvance);
            setAdvances(advances.map(advance =>
                advance._id === id ? { ...advance, check: !advance.check } : advance
            ));
        } catch (error) {
            console.error('Error updating advance:', error);
        }
    };

    const filteredAdvances = advances.filter(advance =>
        advance.name_id?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastAdvance = currentPage * itemsPerPage;
    const indexOfFirstAdvance = indexOfLastAdvance - itemsPerPage;
    const currentAdvances = filteredAdvances.slice(indexOfFirstAdvance, indexOfLastAdvance);
    const totalPages = Math.ceil(filteredAdvances.length / itemsPerPage);

    const handlePrint = async(item) => {
        await setName(item.name_id.name)
        await setAdvance(item.advanceExpense)
        await setAdvanceMonthly(item.advanceMonthly)
        await setAdvanceReason(item.advanceReason)
        await setAdvanceDate(new Date(item.advanceDate).toLocaleDateString('en-uk'))
        window.print();
    };

    return (
        <div className="p-6 bg-white shadow rounded-md w-[90%] mx-auto mt-6 relative">
            <div className="print:hidden">
                <h2 className="text-2xl font-bold mb-4 text-blue-600">جدول السلف</h2>

                <input
                    type="text"
                    placeholder="بحث بالاسم"
                    className="mb-4 p-2 border border-blue-300 rounded w-full"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                <table className="w-full border border-gray-300 text-center" style={{ width: '100%' }}>
                    <thead>
                        <tr className="bg-blue-100">
                            <th className="border px-4 py-2">الاسم</th>
                            <th className="border px-4 py-2">قيمة السلفة</th>
                            <th className="border px-4 py-2">قسط السلفة</th>
                            <th className="border px-4 py-2">الباقى من السلفة</th>
                            <th className="border px-4 py-2">تاريخ الموافقة</th>
                            <th className="border px-4 py-2">تفاصيل السلفة</th>
                            <th className="border px-4 py-2">عدم خصم هذا الشهر</th>
                            <th className="border px-4 py-2">اجرائات</th>
                            <th className="border px-4 py-2">طباعة</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentAdvances.map(advance => (
                            <tr key={advance._id}>
                                <td className="border px-4 py-2">{advance.name_id?.name}</td>
                                <td className="border px-4 py-2">{advance.advanceExpense.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                <td className="border px-4 py-2">{advance.advanceMonthly.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                <td className="border px-4 py-2">{advance.advanceRest.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                <td className="border px-4 py-2">{new Date(advance.advanceDate).toLocaleDateString('EN')}</td>
                                <td className="border px-4 py-2">{advance.advanceReason ? advance.advanceReason : "لا يوجد"}</td>
                                <td className="border px-4 py-2">
                                    <input
                                        type="checkbox"
                                        checked={!advance.check}
                                        onChange={() => handleCheckToggle(advance._id, advance.name_id)}
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                </td>
                                <td className="border px-4 py-2">
                                    <button
                                        onClick={() => {
                                            handleDelete(advance._id, advance.name_id.name)
                                        }}
                                        className='px-6 py-2 text-white bg-red-500 rounded-2xl hover:bg-red-600 duration-100'>
                                        حذف
                                    </button>
                                </td>
                                <td className="border px-4 py-2">
                                    <button
                                        onClick={() => {
                                            handlePrint(advance)
                                        }}
                                        className='px-6 py-2 text-white bg-lime-500 rounded-2xl hover:bg-lime-600 duration-100'>
                                        طباعة
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="flex justify-between mt-4">
                    <button
                        className="bg-blue-600 text-white px-4 py-2 rounded disabled:opacity-50"
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        السابق
                    </button>
                    <span>
                        صفحة {currentPage} من {totalPages}
                    </span>
                    <button
                        className="bg-blue-600 text-white px-4 py-2 rounded disabled:opacity-50"
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        التالي
                    </button>
                </div>
            </div>

            <div className="print:block hidden mt-10">
                <div className="my-4">
                    <img src={logo} alt="logo" className="w-32 h-32" />
                </div>
                <h2 className="text-3xl text-center font-bold mb-4">طلب سلفة</h2>
                <p className="text-lg mt-2 mb-2">
                    الي ابينا القمص مدبر الكنيسة \........................ <br /> تحية طبية و بعد <br /> الرجاء من قدسكم الموافقة علي طلب السلفة بقيمة {advance} جنيها
                </p>
                <h2 className="text-2xl font-bold mb-4 mt-6">تفاصيل السلفة</h2>
                <p className="text-xl mb-4">الأسم طالب السلفة: {name}</p>
                <p className="text-xl mb-4">تاريخ طلب السلفة: {advanceDate}</p>
                <p className="text-xl mb-4">قيمة السلفة: {advance}</p>
                <p className="text-xl mb-4">قسط السلفة: {advanceMonthly}</p>
                <p className="text-xl mb-4">تفاصيل السلفة: {advanceReason}</p>
                <p className="text-xl mb-4 mt-4">الأعتماد</p>
                <div className="flex justify-between">
                    <div>
                        <p className="text-xl text-left">توقيع الحسابات</p>
                    </div>
                    <div>
                        <p className="text-xl text-right">توقيع مدبر الكنيسة</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvanceHistory;
