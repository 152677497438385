import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddExpenses = () => {
    const [places, setPlaces] = useState('')
    const [place, setPlace] = useState('')
    const [contracts, setContracts] = useState('')
    const [type, setType] = useState('')
    const [details, setDetails] = useState('')
    const [price, setPrice] = useState('')
    const [contract, setContract] = useState('')
    const [accepted, setAccepted] = useState("yes")
    const [restContractPrice, setRestContractPrice] = useState('')
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate('')
    const [loading, setLoading] = useState('')

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        })
    };


    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    // Required Item Alert
    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };


    const getPlace = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}get/place`)
            .then((res) => {
                setPlaces(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getContract = () => {
        axios.get(`${process.env.REACT_APP_HOST_SERVER}contract`)
            .then((res) => {
                setContracts(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleContractChange = (e) => {
        const selectedContract = contracts.find(item => item._id === e.target.value)
        setContract(selectedContract);
        setRestContractPrice(selectedContract?.restContractPrice || '');
    }

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (!place || !type || !price) {
                return showRequiredAlert("برجاء التأكد من ملئ البيانات الاساسية");
            }

            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}locker/expenses`, {
                place,
                type,
                price,
                details,
                contract: contract._id,
                accepted: accepted
            });

            if (contract) {
                const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}servedcontract/contracts`, {
                    contractPlace: contract.contractPlace,
                    contract: contract.contract,
                    contractPrice: contract.contractPrice,
                    contractInstallment: price,
                    contractPiecePrice: contract.contractPiecePrice,
                    contractPieces: contract.contractPieces,
                    restContractPrice: contract.restContractPrice - price,
                    details: accepted == "yes"? contract.details : details
                });
            }

            setSuccess(response.data.message);
            setError(null);
            setType('');
            setPrice('');
            setDetails('');
            setContract('');
            setRestContractPrice('');
            showSuccessAlert("تمت الاضافة بنجاح");
        } catch (err) {
            console.log(err);
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
            showErrorAlert(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        verifyAdmin()
        getPlace()
        getContract()
    }, [])

    return (
        <div>
            <Navbar />
            <div className="form w-[75%] m-auto">
                <div className="form-group mt-4">
                    <label className="text-xl">المكان المستهدف</label>
                    <select
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        onChange={(e) => setPlace(e.target.value)}
                    >
                        <option value={place} selected>من فضلك اختار المكان</option>
                        {places && places.map((item) => (
                            <option value={item._id}>{item.place}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">نوع المصروفات</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">الثمن</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">تفاصيل</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">العقد</label>
                    <select
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        onChange={handleContractChange}
                    >
                        <option value="" selected>من فضلك اختر العقد ان وجد</option>
                        {contracts && contracts.filter((item) => item.restContractPrice > 0).map((item) => (
                            <option value={item._id} >{item.contract}</option>
                        ))}
                    </select>
                </div>
                {contract && (
                    <>
                        <div className="form-group mt-4">
                            <label className="text-xl">القيمة المتبقية من العقد</label>
                            <input
                                type="number"
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                value={restContractPrice}
                                readOnly
                            />
                        </div>
                        <div className="form-group mt-4">
                            <label className="text-xl">هل تريد ترحيله الي الخزنة</label>
                            <div className="flex items-center mt-3">
                                <label className="mr-2 flex items-center">
                                    نعم
                                </label>
                                    <input
                                        type="radio"
                                        className="mr-2"
                                        name="accepted"
                                        checked={accepted === "yes"}
                                        onChange={() => setAccepted("yes")}
                                    />
                                <label className="mr-2 flex items-center">
                                    لا
                                </label>
                                    <input
                                        type="radio"
                                        className="mr-2"
                                        name="accepted"
                                        checked={accepted === "no"}
                                        onChange={() => setAccepted("no")}
                                    />
                            </div>
                        </div>
                    </>
                )}
                {error && (
                    <div className="mt-4 text-red-500 text-center">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="mt-4 text-green-500 text-center">
                        {success}
                    </div>
                )}
                <div className="submit mt-6 text-center">
                    <button
                        type="submit"
                        className="bg-[#000000] text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        أضافة المصروفات
                    </button>
                </div>

            </div>
        </div>
    );
}

export default AddExpenses;
